@use "settings/s-colors" as colors;

.i-selected-file {
  background-color: colors.$primary-900;
  min-height: 24px;
  cursor: pointer;
}

.i-selected-file__remove {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
    height: 100%;
  }
}
