@use "settings/s-colors" as colors;

.f-date__icon {
  display: var(--icon-display);
  padding: 8px;
  position: absolute;
  pointer-events: none;
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button,
  &::-webkit-calendar-picker-indicator {
    display: none;
    appearance: none;
  }
}

.f-date {
  --radius: 30px;
  --icon-display: block;
  --cursor: pointer;

  &:not(.--native) {
    .f-date__input {
      padding-right: 48px;
    }
  }

  &.--native {
    --icon-display: none;
    --cursor: auto;
  }

  &.--invalid {
    --border-color: #{colors.$danger};
  }
}

.f-date__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color 0.3s ease;
}
