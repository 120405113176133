@use "settings/s-colors" as colors;

.m-header {
  --shadow-color: transparent;
  --min-height: 75px;
  --p-block: 8px;
  --logo-max-w: 106px;
  --bg-color: #{colors.$white};

  background-color: var(--bg-color);
  top: 0;
  left: 0;
  position: sticky;
  z-index: 5;
  width: 100%;
  transition: transform 0.5s ease;

  &.headroom--unpinned {
    transform: translateY(-100%);
  }

  &.headroom--not-top {
    --logo-max-w: 96px;

    @include media-breakpoint-only(xl) {
      --logo-max-w: 80px;
    }
  }

  @include media-breakpoint-only(xl) {
    --logo-max-w: 100px;
  }
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--p-block);
  transition: 0.3s ease;
  transition-property: padding, min-height;
  will-change: padding, min-height;

  @include media-breakpoint-down(sm) {
    --min-height: 80px;
    --p-block: 0;

    min-height: var(--min-height);
    padding-block: var(--p-block);
  }
}

.m-header-logo {
  max-width: var(--logo-max-w);
  transition: max-width 0.3s ease;
  will-change: max-width;
}
