@use "settings/s-colors" as colors;
@use "settings/s-general" as general;

.i-menu__submenu-button {
  --size: 24px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  rotate: var(--submenu-button-rotate);
  margin-left: auto;

  @include media-breakpoint-down(lg) {
    --size: 40px;

    transition: scale 0.3s ease;
    will-change: scale;
    scale: var(--submenu-button-scale);
    position: absolute;
    top: 0;
    right: 16px;

    &:hover {
      svg {
        transform: translateY(4px);
      }
    }
  }

  svg {
    transition: transform 0.3s ease;
  }
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 21px;
  --padding-right: 21px;
  --background: transparent;
  --background-hover: #{colors.$grey};

  &.--open {
    --submenu-display: block;
    --submenu-button-scale: -1;
  }

  & > .i-menu__submenu {
    display: var(--submenu-display);
  }
}

.i-menu__link {
  display: flex;
  font-size: 18px;
  gap: 8px;
  min-height: 40px;
  align-items: center;
  padding-block: 13px;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  border-radius: 24px;
  position: relative;
  text-decoration: none;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  will-change: background-color;
}

// Desktop

@include media-breakpoint-up(lg) {
  .i-menu {
    --padding-left: 21px;
    --padding-right: 21px;
    --submenu-display: block;
    --opacity: 0;
    --translate-x: -50%;
    --translate-y: 8px;
    --translate-y-dot: var(--translate-y);
    --pointer-events: none;

    .i-menu__submenu {
      z-index: 50;
      width: 100%;
      min-width: 280px;
      padding: 16px 8px;
      position: absolute;
      background-color: colors.$white;
      box-shadow: general.$shadow-02;
      border-radius: 20px;
      border: 1px solid colors.$primary-900;
      opacity: var(--opacity);
      pointer-events: var(--pointer-events);
      transform: translate(var(--translate-x), var(--translate-y));
      transition: opacity 0.3s ease;
      transition-property: opacity, transform;
      will-change: opacity, transform;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 16px;
        height: 16px;
        background-color: colors.$secondary;
        border-radius: 50%;
        opacity: var(--opacity);
        transform: translate(-50%, var(--translate-y-dot));
        transition: opacity 0.3s ease;
        transition-property: opacity, transform;
        will-change: opacity, transform;
      }
    }

    &.--level-1 {
      position: relative;

      & > .i-menu__submenu {
        top: 100%;
        left: 50%;
      }

      &:hover {
        --pointer-events: auto;
        --translate-y: 0;
        --translate-y-dot: -50%;
        --opacity: 1;
      }
    }

    &.--level-2 {
      position: relative;

      & > .i-menu__submenu {
        top: 0;
        left: 100%;

        &.--mirror {
          left: auto;
          right: 100%;
        }
      }

      & .i-menu__link:only-child {
        &:hover {
          --background: var(--background-hover);
        }
      }

      &:hover {
        --pointer-events: auto;
        --translate-y: 0;
        --translate-x: 0;
        --translate-y-dot: -50%;
        --opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .i-menu.--level-2 {
    & .i-menu__link:only-child {
      &:hover {
        --background: var(--background-hover);
      }
    }
  }
}
