*, :before, :after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  margin: 0;
}

hr {
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: .25;
  margin: .5rem 0;
}

h6, h5, h4, h3, h2, h1 {
  color: var(--bs-heading-color);
  margin-top: 0;
  margin-bottom: .25rem;
  font-weight: 500;
  line-height: 1.2;
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}

@media (min-width: 75em) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + .9vw);
}

@media (min-width: 75em) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + .6vw);
}

@media (min-width: 75em) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + .3vw);
}

@media (min-width: 75em) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol, ul {
  padding-left: 2rem;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol, ul ul, ol ul, ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: .875em;
}

mark {
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
  padding: .1875em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre, code {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: .875em;
  display: block;
  overflow: auto;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  color: var(--bs-code-color);
  word-wrap: break-word;
  font-size: .875em;
}

a > code {
  color: inherit;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  color: var(--bs-secondary-color);
  text-align: left;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead, tbody, tfoot, tr, td, th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

button, select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

select:disabled {
  opacity: 1;
}

[list]:not([type="date"]):not([type="datetime-local"]):not([type="month"]):not([type="week"]):not([type="time"])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button {
  -webkit-appearance: button;
}

[type="button"] {
  -webkit-appearance: button;
}

[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled) {
  cursor: pointer;
}

[type="button"]:not(:disabled) {
  cursor: pointer;
}

[type="submit"]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

textarea {
  resize: vertical;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-datetime-edit-text {
  padding: 0;
}

::-webkit-datetime-edit-minute {
  padding: 0;
}

::-webkit-datetime-edit-hour-field {
  padding: 0;
}

::-webkit-datetime-edit-day-field {
  padding: 0;
}

::-webkit-datetime-edit-month-field {
  padding: 0;
}

::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -.125rem;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

iframe {
  border: 0;
}

[hidden] {
  display: none !important;
}

.container, .container-fluid {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 36em) {
  .container {
    max-width: 33.75rem;
  }
}

@media (min-width: 48em) {
  .container {
    max-width: 45rem;
  }
}

@media (min-width: 62em) {
  .container {
    max-width: 60rem;
  }
}

@media (min-width: 81.25em) {
  .container {
    max-width: 78.75rem;
  }
}

@media (min-width: 93.75em) {
  .container {
    max-width: 91.25rem;
  }
}

@media (min-width: 108.75em) {
  .container {
    max-width: 105.75rem;
  }
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 36rem;
  --bs-breakpoint-md: 48rem;
  --bs-breakpoint-lg: 62rem;
  --bs-breakpoint-xl: 81.25rem;
  --bs-breakpoint-xxl: 93.75rem;
  --bs-breakpoint-xxxl: 108.75rem;
}

.row {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.row > * {
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.col {
  flex: 1 0;
}

.col-12 {
  flex: none;
  width: 100%;
}

.gx-0 {
  --bs-gutter-x: 0;
}

.gy-4 {
  --bs-gutter-y: 2rem;
}

@media (min-width: 36em) {
  .col-sm-6 {
    flex: none;
    width: 50%;
  }
}

@media (min-width: 48em) {
  .col-md-3 {
    flex: none;
    width: 25%;
  }

  .col-md-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-md-6 {
    flex: none;
    width: 50%;
  }
}

@media (min-width: 62em) {
  .col-lg-3 {
    flex: none;
    width: 25%;
  }

  .col-lg-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-lg-6 {
    flex: none;
    width: 50%;
  }

  .col-lg-8 {
    flex: none;
    width: 66.6667%;
  }
}

@media (min-width: 81.25em) {
  .col-xl-3 {
    flex: none;
    width: 25%;
  }

  .col-xl-4 {
    flex: none;
    width: 33.3333%;
  }

  .col-xl-10 {
    flex: none;
    width: 83.3333%;
  }
}

@media (min-width: 93.75em) {
  .col-xxl-2 {
    flex: none;
    width: 16.6667%;
  }

  .col-xxl-3 {
    flex: none;
    width: 25%;
  }

  .col-xxl-8 {
    flex: none;
    width: 66.6667%;
  }
}

.ratio {
  width: 100%;
  position: relative;
}

.ratio:before {
  padding-top: var(--bs-aspect-ratio);
  content: "";
  display: block;
}

.ratio > * {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.opacity-50 {
  opacity: .5;
}

.overflow-hidden {
  overflow: hidden;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.start-0 {
  left: 0;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.flex-column {
  flex-direction: column;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-self-end {
  align-self: flex-end;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-5 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.mt-4 {
  margin-top: 2rem;
}

.mt-5 {
  margin-top: 2.5rem;
}

.mt-auto {
  margin-top: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: .5rem;
}

.mb-2 {
  margin-bottom: 1rem;
}

.mb-3 {
  margin-bottom: 1.5rem;
}

.mb-5 {
  margin-bottom: 2.5rem;
}

.ms-1 {
  margin-left: .5rem;
}

.ms-auto {
  margin-left: auto;
}

.p-1 {
  padding: .5rem;
}

.p-2 {
  padding: 1rem;
}

.p-3 {
  padding: 1.5rem;
}

.p-4 {
  padding: 2rem;
}

.p-5 {
  padding: 2.5rem;
}

.p-6 {
  padding: 3rem;
}

.px-1 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-3 {
  padding-top: 1.5rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 1.5rem;
}

.pb-4 {
  padding-bottom: 2rem;
}

.gap-1 {
  gap: .5rem;
}

.gap-2 {
  gap: 1rem;
}

.gap-3 {
  gap: 1.5rem;
}

.gap-4 {
  gap: 2rem;
}

.gap-5 {
  gap: 2.5rem;
}

.row-gap-4 {
  row-gap: 2rem;
}

.text-start {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-nowrap {
  white-space: nowrap;
}

@media (min-width: 36em) {
  .justify-content-sm-start {
    justify-content: flex-start;
  }
}

@media (min-width: 48em) {
  .flex-md-row {
    flex-direction: row;
  }

  .justify-content-md-end {
    justify-content: flex-end;
  }

  .align-items-md-center {
    align-items: center;
  }
}

@media (min-width: 62em) {
  .d-lg-none {
    display: none;
  }

  .ms-lg-0 {
    margin-left: 0;
  }
}

.container {
  padding-left: 1.625rem;
  padding-right: 1.625rem;
}

@media (max-width: 35.999em) {
  .container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 359px) {
  .container {
    padding-left: .5rem;
    padding-right: .5rem;
  }
}

.fancybox__container {
  --fancybox-accent-color: #0f2944;
}

.flatpickr-calendar {
  --theme-color: #143558;
  --theme-color-hover: #0f2944;
}

.flatpickr-calendar .flatpickr-day.selected, .flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}

.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: #143558;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

body {
  -webkit-font-smoothing: antialiased;
  flex-direction: column;
  min-height: 100%;
  font-family: Montserrat, verdana;
  display: flex;
  overflow-x: hidden;
  -moz-osx-font-smoothing: grayscale !important;
}

figure {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 0;
}

html {
  height: 100%;
}

iframe {
  max-width: 100%;
  display: block;
}

img {
  opacity: 1;
  max-width: 100%;
  height: auto;
  transition: opacity .5s;
  display: block;
}

img[data-src], img[data-srcset] {
  min-width: .0625rem;
  min-height: .0625rem;
  display: block;
}

img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  color: #143558;
  text-decoration: underline;
  transition: text-decoration-color .3s;
  display: inline-block;
}

a:hover, a:focus {
  color: #143558;
  text-decoration-color: rgba(0, 0, 0, 0);
}

a[href^="x-apple-data-detectors:"] {
  pointer-events: none;
  text-decoration: none;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  flex-grow: 1;
  display: block;
  overflow-x: hidden;
}

strong, b {
  font-weight: 700;
}

.entry-content .column {
  width: 100%;
  min-height: .0625rem;
  margin-bottom: 1.5rem;
  padding-left: .9375rem;
  padding-right: .9375rem;
  position: relative;
}

.entry-content .column img {
  max-width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
  float: none !important;
}

@media (max-width: 61.999em) {
  .entry-content .column h2:first-of-type, .entry-content .column h3:first-of-type, .entry-content .column h4:first-of-type, .entry-content .column h5:first-of-type, .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}

.entry-content .columns-1, .entry-content .columns-1 .column {
  display: block;
}

.entry-content .columns-2 {
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-2 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-3 {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .entry-content .columns-3 {
    flex-direction: column;
    display: flex;
  }
}

.entry-content .columns-4 {
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  display: grid;
}

@media (max-width: 81.249em) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 61.999em) {
  .entry-content .columns-4 {
    flex-direction: column;
    display: flex;
  }
}

.validator {
  opacity: 0;
  flex-direction: column;
  gap: .125rem;
  display: flex;
  position: absolute;
  top: 100%;
}

.validator .erorr-s {
  white-space: nowrap;
  color: #e52661;
  z-index: 99;
  font-size: .75rem;
  line-height: 1rem;
  display: block;
}

.validator:first-of-type {
  opacity: 1;
}

.entry-content .alignnone {
  margin: .3125rem 1.25rem 1.25rem 0;
}

.entry-content .alignright {
  float: right;
  margin: 0 0 1.25rem 1.875rem;
}

.entry-content .alignright img {
  margin-block: 0;
}

.entry-content .alignleft {
  float: left;
  margin: 0 1.875rem 1.25rem 0;
}

.entry-content .alignleft img {
  margin-block: 0;
}

.entry-content .aligncenter, .entry-content div.aligncenter {
  margin: 3rem auto;
  display: block;
}

.entry-content .aligncenter img, .entry-content div.aligncenter img {
  margin-block: 0;
  margin-inline: auto;
}

@media (max-width: 35.999em) {
  .entry-content .alignleft, .entry-content .alignright {
    float: none;
    margin: 0;
    margin-bottom: 1.5rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}

.screen-reader-text {
  clip: rect(.0625rem, .0625rem, .0625rem, .0625rem);
  width: .0625rem;
  height: .0625rem;
  overflow: hidden;
  position: absolute !important;
}

.screen-reader-text:focus {
  color: #21759b;
  z-index: 100000;
  background-color: #f1f1f1;
  border-radius: .1875rem;
  width: auto;
  height: auto;
  padding: .9375rem 1.4375rem .875rem;
  font-size: .875rem;
  font-weight: bold;
  line-height: normal;
  text-decoration: none;
  display: block;
  top: .3125rem;
  left: .3125rem;
  box-shadow: 0 0 .125rem .125rem rgba(0, 0, 0, .6);
  clip: auto !important;
}

.u-bg-primary {
  background-color: #143558;
}

.u-bg-secondary-500 {
  background-color: #d7ab2d;
}

.u-bg-white {
  background-color: #fff;
}

.u-border-radius-lg {
  border-radius: 1.5rem;
}

.u-clamp {
  --lines: 2;
  -webkit-line-clamp: var(--lines);
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.u-clamp-6 {
  --lines: 6;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-3 {
  --lines: 3;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #143558;
}

.u-color-white {
  color: #fff;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-xs {
  --size: .75rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .25rem);
}

.u-fz-sm {
  --size: .875rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-md {
  --size: 1rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-lg {
  --size: 1.125rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .25rem);
}

.u-fz-xl {
  --size: 1.25rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fz-xxl {
  --size: 1.5rem;
  font-size: var(--size);
  line-height: calc(var(--size)  + .5rem);
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-h1, .u-h2, .u-h3, .u-h4, .u-h5, .u-h6, .u-h7 {
  font-size: var(--size);
  color: #143558;
  line-height: var(--line-height);
}

.u-h1, .entry-content h1 {
  --size: 5.25rem;
  --line-height: 6.375rem;
}

@media (max-width: 47.999em) {
  .u-h1, .entry-content h1 {
    --size: 3.75rem;
    --line-height: 5.25rem;
  }
}

.u-h2, .entry-content h2 {
  --size: 4.5rem;
  --line-height: 5.5rem;
}

@media (max-width: 47.999em) {
  .u-h2, .entry-content h2 {
    --size: 3.5rem;
    --line-height: 4.5rem;
  }
}

.u-h3, .entry-content h3 {
  --size: 3.75rem;
  --line-height: 4.5625rem;
}

@media (max-width: 47.999em) {
  .u-h3, .entry-content h3 {
    --size: 3.25rem;
    --line-height: 3.75rem;
  }
}

.u-h4, .entry-content h4 {
  --size: 3rem;
  --line-height: 3.625rem;
}

@media (max-width: 47.999em) {
  .u-h4, .entry-content h4 {
    --size: 2.5rem;
    --line-height: 3rem;
  }
}

.u-h5, .entry-content h5 {
  --size: 2.25rem;
  --line-height: 2.75rem;
}

@media (max-width: 47.999em) {
  .u-h5, .entry-content h5 {
    --size: 2rem;
    --line-height: 2.25rem;
  }
}

.u-h6, .entry-content h6 {
  --size: 1.875rem;
  --line-height: 2.5625rem;
}

@media (max-width: 47.999em) {
  .u-h6, .entry-content h6 {
    --size: 1.75rem;
    --line-height: 2.3125rem;
  }
}

.u-h7 {
  --size: 1.5rem;
  --line-height: 2rem;
}

@media (max-width: 47.999em) {
  .u-h7 {
    --size: 1.125rem;
    --line-height: 1.5rem;
  }
}

.u-img-hover {
  backface-visibility: hidden;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.u-img-hover img {
  will-change: transform, opacity;
  transition-property: opacity, transform;
}

.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  pointer-events: none;
  position: relative;
}

.--loading:before {
  content: "";
  z-index: 9000;
  opacity: .8;
  background-color: #e4e8ec;
  width: 100%;
  height: 100%;
  transition: opacity .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.--loading:after {
  content: "";
  z-index: 9001;
  border: .25rem solid #0f2944;
  border-bottom-color: #143558;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  margin-top: -.9375rem;
  margin-left: -.9375rem;
  animation: 1s cubic-bezier(.68, -.55, .27, 1.55) infinite rotation;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.u-scrollbar .os-scrollbar {
  --os-handle-bg: #0b1d30;
  --os-handle-bg-hover: #0f2944;
  --os-handle-bg-active: #143558;
  --os-handle-border-radius: .25rem;
  --os-size: .5rem;
  --os-track-bg: #e4e8ec;
  --os-track-bg-hover: #e4e8ec;
  --os-track-bg-active: #e4e8ec;
  cursor: grab;
}

.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}

.u-scrollbar .os-theme-dark.os-scrollbar-vertical {
  padding: 0;
}

.u-o-visible {
  overflow: visible !important;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-0 {
  top: 0;
}

.u-left-0 {
  left: 0;
}

.u-right-0 {
  right: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%)translateY(-50%);
}

.u-z-index-0 {
  z-index: 0;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.base-header {
  --spacing: 2rem;
  margin-inline: auto;
  margin-bottom: var(--spacing);
  width: 100%;
  max-width: 58rem;
}

.base-header.--left {
  text-align: left;
  align-items: flex-start;
  margin-inline: 0;
}

.base-header.--spacing-none {
  --spacing: 0;
}

.base-header__description {
  --font-family: Fraunces, serif;
  --font-style: italic;
  --font-weight: 200;
  --color: #7c8ea2;
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  color: var(--color);
}

.base-header__description p {
  margin-bottom: 0;
}

.base-heading {
  --font-weight: 700;
  font-weight: var(--font-weight);
}

.base-heading.--fw-400 {
  --font-weight: 400;
}

.base-section {
  --padding-y: 5.5rem;
  padding-block: var(--padding-y);
}

@media (max-width: 35.999em) {
  .base-section {
    --padding-y: 2.75rem;
  }
}

.base-section.--top-0 {
  padding-top: 0;
}

.base-section.--bottom-0 {
  padding-bottom: 0;
}

.base-section.--none {
  --padding-y: 0;
}

.btn {
  --direction-offset: .5rem;
}

.btn.--size-xs {
  --direction-offset: .25rem;
}

@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
  }

  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }

  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--sm {
  --font-size: .875rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 3rem;
}

.btn.--xs {
  --font-size: .75rem;
  --padding-y: .75rem;
  --padding-x: 1.5rem;
  --min-height: 2.5rem;
  --svg-size: 1rem;
}

.btn.--primary {
  --background-color: #cad2d9;
  --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #e4e8ec;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  will-change: background-color, box-shadow;
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--primary:hover {
    --background-color: #e4e8ec;
    --shadow: inset 0 -.1875rem 0 rgba(0, 0, 0, .16), 0 .1875rem 1.25rem #e4e8ec;
  }
}

.btn.--secondary {
  --background-color: #d7ab2d;
  --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #f0e1b7;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  will-change: background-color, box-shadow;
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--secondary:hover {
    --background-color: #dfbd5b;
    --shadow: inset 0 -.1875rem 0 rgba(0, 0, 0, .16), 0 .1875rem 1.25rem #f0e1b7;
  }
}

.btn.--tertiary {
  --font-color: #fff;
  --background-color: #143558;
  --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem rgba(20, 53, 88, .396);
  --border-radius: 2.75rem;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  will-change: background-color, box-shadow;
  transition: background-color .3s, box-shadow .3s;
}

@media (hover: hover) {
  .btn.--tertiary:hover {
    --background-color: #2d547e;
    --shadow: inset 0 -.1875rem 0 rgba(0, 0, 0, .525), 0 .5625rem 1rem rgba(20, 53, 88, .396);
  }
}

.btn.--outline {
  --font-color: #143558;
  --border-color: #143558;
  --background: #fff;
  background-color: var(--background);
  border: .0625rem solid var(--border-color);
  color: var(--font-color);
  will-change: border-color, background-color, color;
  transition: border-color .3s, background-color .3s, color .3s;
}

@media (hover: hover) {
  .btn.--outline:hover {
    --border-color: transparent;
    --background: #143558;
    --font-color: #fff;
  }

  .btn.--outline:hover path {
    fill: var(--font-color);
  }
}

.btn.--linkedin, .btn.--instagram, .btn.--facebook, .btn.--youtube {
  --background-color: #ff0200;
  --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #f0e1b7;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  will-change: background-color, box-shadow;
  transition: background-color .3s, box-shadow .3s;
}

.btn.--linkedin .btn__icon:before, .btn.--instagram .btn__icon:before, .btn.--facebook .btn__icon:before, .btn.--youtube .btn__icon:before {
  content: "";
  width: calc(var(--min-height)  + .0625rem);
  height: calc(var(--min-height)  + .0625rem);
  z-index: -1;
  will-change: width, height;
  background-color: #d7ab2d;
  border-radius: 50%;
  transition: width .3s ease-in-out, height .3s ease-in-out;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (hover: hover) {
  .btn.--linkedin:hover, .btn.--instagram:hover, .btn.--facebook:hover, .btn.--youtube:hover {
    --background-color: #ff0200;
    --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #e6cfcf;
  }

  .btn.--linkedin:hover .btn__icon:before, .btn.--instagram:hover .btn__icon:before, .btn.--facebook:hover .btn__icon:before, .btn.--youtube:hover .btn__icon:before {
    content: "";
    width: 0;
    height: 0;
  }
}

.btn.--facebook {
  --background-color: #4267b2;
}

@media (hover: hover) {
  .btn.--facebook:hover {
    --background-color: #4267b2;
    --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #cfd6e5;
  }
}

.btn.--instagram {
  background-image: radial-gradient(circle at 35% 90%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(circle at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}

@media (hover: hover) {
  .btn.--instagram:hover {
    --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #f0e1b7;
    background-image: radial-gradient(circle at 35% 90%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(circle at 0 140%, #fec564, rgba(0, 0, 0, 0) 50%), radial-gradient(at 0 -25%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(at 20% -50%, #5258cf, rgba(0, 0, 0, 0) 50%), radial-gradient(at 100% 0, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(at 60% -20%, #893dc2, rgba(0, 0, 0, 0) 50%), radial-gradient(at 100% 100%, #d9317a, rgba(0, 0, 0, 0)), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  }
}

.btn.--linkedin {
  --background-color: #0072b1;
}

@media (hover: hover) {
  .btn.--linkedin:hover {
    --background-color: #0072b1;
    --shadow: inset 0 0 0 rgba(0, 0, 0, .16), 0 .8125rem 1.25rem #cfdde6;
  }
}

.btn.--play {
  --background-color: rgba(255, 255, 255, .7);
  --border-radius: 4.9375rem;
  --max-width: 23.375rem;
  --font-size: 1.5rem;
  --font-weight: 400;
  --padding-y: 1rem;
  --padding-x: 1rem;
  max-width: var(--max-width);
  opacity: 1;
  background-color: var(--background-color);
  -webkit-backdrop-filter: blur(.75rem);
  backdrop-filter: blur(.75rem);
  position: absolute;
  bottom: 16.5rem;
  left: 1.5rem;
  transform: translate(-50%);
}

@media (max-width: 93.749em) {
  .btn.--play {
    bottom: 6.25rem;
  }
}

@media (max-width: 81.249em) {
  .btn.--play {
    bottom: 0;
    left: 50%;
  }
}

@media (max-width: 61.999em) {
  .btn.--play {
    --font-size: 1.125rem;
    --max-width: 18.75rem;
  }
}

.btn__icon .icon {
  --size: var(--svg-size);
}

.btn__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.btn {
  --border-radius: 2.25rem;
  --font-size: 1.125rem;
  --font-weight: 700;
  --font-color: #143558;
  --font-family: Montserrat, verdana;
  --padding-y: 1.5625rem;
  --padding-x: 2.5rem;
  --min-height: 4.5rem;
  --svg-size: 1.25rem;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  text-decoration: none;
}

@media (max-width: 61.999em) {
  .btn {
    --min-height: 4rem;
    --padding-y: .9375rem;
    --padding-x: 1.875rem;
    --font-size: 1rem;
  }
}

.btn:hover {
  color: var(--font-color);
  text-decoration: none;
}

.btn:focus, .btn:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.btn path {
  fill: var(--font-color);
  transition: fill .3s;
}

.btn.--icon-before .btn__icon {
  order: -1;
}

.btn.--icon-only {
  --min-height: 3rem;
  width: var(--min-height);
  height: var(--min-height);
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.btn.--icon-only .btn__title {
  display: none;
}

@media (max-width: 35.999em) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}

@media (min-width: 62em) {
  .content-w-aside__content {
    grid-column: 1 / span 8;
  }
}

.content-w-aside__row {
  gap: 2rem;
}

@media (min-width: 62em) {
  .content-w-aside__row {
    grid-template-columns: repeat(12, 1fr);
    justify-content: center;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .content-w-aside__row {
    flex-direction: column;
    display: flex;
  }
}

.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3 / span 8;
}

@media (min-width: 62em) {
  .content-w-aside__aside {
    grid-column: span 4 / -1;
  }
}

@media (min-width: 93.75em) {
  .content-w-aside__aside {
    grid-column: span 3 / -1;
  }
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  background-color: #fff;
  transition: background-color .3s;
}

.f-checkbox__visual svg {
  opacity: 0;
  transition: opacity .3s, transform .3s;
  transform: scale(.2);
}

.f-checkbox__visual:after {
  content: "";
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  background-color: #fff;
  transition: opacity .3s, transform .3s;
  display: none;
  position: absolute;
  transform: scale(.2);
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  pointer-events: none;
  opacity: 0;
  transform: translateX(-50%);
}

.f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-color: #05d686;
  background-color: var(--color);
}

.f-checkbox__input:checked ~ .f-checkbox__visual:after, .f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title {
  --color: #143558;
  color: var(--color);
}

.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: #05d686;
  --border-color: #a78523;
  --radius: .25rem;
  --size: 1.5rem;
  cursor: pointer;
}

.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}

.f-checkbox.--radio .f-checkbox__visual:after {
  display: block;
}

.f-checkbox.--disabled {
  --color: #cad2d9;
  --border-color: #cad2d9;
}

.f-checkbox.--disabled .f-checkbox__title {
  --color: #cad2d9;
  color: var(--color);
}

.f-checkbox.--disabled .f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-color: #cad2d9;
}

.f-checkbox-select__header {
  -webkit-user-select: none;
  user-select: none;
  background-color: var(--header-background);
  border: .0625rem solid #e4e8ec;
  min-height: 3rem;
  padding: .4375rem .5rem;
}

.f-checkbox-select__clear {
  display: none;
}

.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  transition: background-color .3s;
}

.f-checkbox-select__toggler svg {
  width: .875rem;
  height: .875rem;
  transition: scale .3s;
}

.f-checkbox-select__toggler:hover {
  background-color: #e4e8ec;
}

.f-checkbox-select__label {
  --end: 0;
  flex: 1;
  height: 2rem;
  top: 0;
  left: 0;
  overflow: hidden;
}

.f-checkbox-select__label > span {
  white-space: nowrap;
  transition: left 2s linear;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.f-checkbox-select__label:after {
  content: "";
  pointer-events: none;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box;
  z-index: 1;
  width: 1rem;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

.f-checkbox-select__label:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  transform-origin: top;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  will-change: opacity, transform;
  background-color: #fff;
  border: .0625rem solid #e4e8ec;
  width: calc(100% - .0625rem);
  transition: opacity .3s, transform .3s;
  top: calc(100% - .0625rem);
  left: 50%;
  transform: translateX(-50%)scaleY(.25);
}

.f-checkbox-select__body-inner {
  max-height: 10rem;
}

.f-checkbox-select {
  --header-background: #fff;
  min-width: 0;
}

.f-checkbox-select.--active {
  --header-background: #e4e8ec;
}

.f-checkbox-select.--active .f-checkbox-select__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%)scaleY(1);
}

.f-checkbox-select.--active .f-checkbox-select__toggler svg {
  scale: -1;
}

.f-date__icon {
  display: var(--icon-display);
  pointer-events: none;
  padding: .5rem 1rem .5rem .5rem;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}

.f-date__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-clear-button {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date__input::-webkit-calendar-picker-indicator {
  -webkit-appearance: none;
  appearance: none;
  display: none;
}

.f-date {
  --radius: 1.875rem;
  --icon-display: block;
  --cursor: pointer;
}

.f-date:not(.--native) .f-date__input {
  padding-right: 3rem;
}

.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}

.f-date.--invalid {
  --border-color: #e52661;
}

.f-date__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-error {
  --display: none;
  color: #e52661;
  display: var(--display);
  z-index: 2;
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.f-error.--show {
  --display: flex;
}

.f-error__icon {
  height: 1.375rem;
}

.f-base {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 1.875rem;
  width: 100%;
  min-height: 2.875rem;
  padding: .625rem 1rem;
  display: block;
  box-shadow: 0 .1875rem .375rem rgba(0, 0, 0, .16);
}

.f-base:focus {
  border-color: none;
  outline: none;
}

.f-base::placeholder {
  opacity: 1;
  color: #143558;
}

textarea.f-base {
  resize: none;
  min-height: 12.5rem;
}

.i-selected-file {
  cursor: pointer;
  background-color: #e4e8ec;
  min-height: 1.5rem;
}

.i-selected-file__remove {
  justify-content: center;
  align-items: center;
  width: .75rem;
  height: .75rem;
  display: flex;
}

.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files, .f-file__input {
  display: none;
}

.f-label.--required:after {
  content: "*";
  color: #e52661;
  padding-left: .25rem;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
}

.f-single-select__select.slim-select.ss-main:focus {
  box-shadow: none;
}

.f-single-select__select.slim-select.ss-values.ss-single {
  margin: 0;
}

.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 1rem;
}

.f-single-select, .f-single-select__select.slim-select {
  --radius: 1.875rem;
  --height: 3rem;
  --padding-inline: 1rem;
  --border-color: #a78523;
  --ss-primary-color: #143558;
  --ss-bg-color: #fff;
  --ss-font-color: #081828;
  --ss-font-placeholder-color: #e4e8ec;
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 18.75rem;
  --ss-spacing-l: .4375rem;
  --ss-spacing-m: .3125rem;
  --ss-spacing-s: .1875rem;
  --ss-animation-timing: .2s;
  --ss-border-radius: var(--radius);
}

.f-single-select.--native .f-single-select__wrap, .f-single-select__select.slim-select.--native .f-single-select__wrap {
  grid-template-areas: "select";
  align-items: center;
  display: grid;
}

.f-single-select.--native .f-single-select__wrap:after, .f-single-select__select.slim-select.--native .f-single-select__wrap:after {
  content: "";
  pointer-events: none;
  background-image: url("chevron-down.48c4fd44.svg");
  background-repeat: no-repeat;
  background-size: contain;
  grid-area: select;
  justify-self: end;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  display: block;
}

.f-single-select.--native .f-single-select__select, .f-single-select__select.slim-select.--native .f-single-select__select {
  border-radius: var(--radius);
  min-height: var(--height);
  border: .125rem solid var(--border-color);
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  outline: none;
  grid-area: select;
  width: 100%;
  margin: 0;
  padding-left: 1rem;
}

.f-single-select.--invalid, .f-single-select__select.slim-select.--invalid, .f-single-select.--invalid .ss-main, .f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #e52661;
}

.f-switch {
  --width: 3rem;
  --height: 1.5rem;
  --offset: .25rem;
  --background: #48617d;
  --background-checked: #143558;
  --transition-timing: .2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height)  - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #fff;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width)  - var(--handle-size)  - var(--offset));
}

.f-switch.--disabled {
  --background: #b0bbc7;
  --background-checked: #b0bbc7;
  --handle-background: #e4e8ec;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
}

.f-switch__visual:after {
  content: "";
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}

.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-text__icon {
  pointer-events: none;
  padding: .5rem;
  position: absolute;
}

.f-text__icon.--before {
  padding-left: 1rem;
  top: 50%;
  left: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__icon.--after {
  padding-right: 1rem;
  top: 50%;
  right: 0;
  transform: translateX(0)translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
}

.f-text {
  --radius: 1.875rem;
}

.f-text.--invalid {
  --border-color: #e52661;
}

.f-text.--icon-before .f-text__input {
  padding-left: 3rem;
}

.f-text.--icon-after .f-text__input {
  padding-right: 3rem;
}

.f-text__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color .3s;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  right: 1rem;
}

.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  width: var(--size);
  height: var(--size);
  grid-row-start: 1;
  grid-column-start: 1;
}

.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}

.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --radius: 1.875rem;
}

.f-textarea.--invalid {
  --border-color: #e52661;
}

.f-textarea__wrap {
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
}

.f-contact__grid {
  display: grid;
}

@media (min-width: 48em) {
  .f-contact__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 35.999em) {
  .f-contact__footer {
    flex-direction: column;
  }
}

@media (min-width: 36em) {
  .f-contact__submit {
    margin-left: auto;
  }
}

.f-newsletter {
  background-color: #e4e8ec;
  border-radius: 1rem;
}

@media (min-width: 62em) {
  .f-newsletter__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.f-newsletter__header {
  gap: .25rem;
}

@media (max-width: 35.999em) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (max-width: 47.999em) {
  .f-newsletter__submit {
    width: 100%;
  }
}

.g-base {
  flex-wrap: wrap;
  display: flex;
}

.g-base.--gap-xs {
  gap: .25rem;
}

.g-base.--gap-sm {
  gap: .5rem;
}

.g-base.--gap-md {
  gap: 1rem;
}

.g-base.--gap-lg {
  gap: 1.5rem;
}

.g-base.--gap-xl {
  gap: 2rem;
}

.g-base.--align-left {
  justify-content: flex-start;
}

.g-base.--align-right {
  justify-content: flex-end;
}

.g-base.--align-center {
  justify-content: center;
}

.g-base.--align-between {
  justify-content: space-between;
}

.g-buttons {
  --spacing: 3rem;
  margin-top: var(--spacing);
}

.g-buttons.--spacing-none {
  --spacing: 0;
}

.g-consultant-stats {
  grid-template-rows: 6.25rem 6.25rem;
  grid-template-columns: repeat(2, 1fr);
  gap: 6.125rem 3.0625rem;
  display: grid;
}

@media (max-width: 81.249em) {
  .g-consultant-stats {
    flex-direction: column;
    row-gap: 2rem;
    display: flex;
  }
}

@media (min-width: 81.25em) {
  .g-events {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.g-hero-slider__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-hero-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-hero-slider__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 35.999em) {
  .g-hero-slider__button {
    --offset: .5rem;
  }
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet {
  --swiper-pagination-bullet-border-radius: 50%;
  transform-origin: center;
  transition: width .3s;
}

.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
  --swiper-pagination-color: #d7ab2d;
  --swiper-pagination-bullet-border-radius: .25rem;
}

.g-hero-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider__buttons {
  pointer-events: none;
}

.g-jobs {
  gap: 5rem;
  width: 100%;
  max-width: 58rem;
  margin: 0 auto;
}

.g-jobs.--card {
  max-width: unset;
  flex-flow: wrap;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

.g-persons {
  display: grid;
}

@media (min-width: 48em) {
  .g-persons {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 81.25em) {
  .g-persons {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-posts-grid {
  gap: 4.5rem 2rem;
  display: grid;
}

@media (min-width: 62em) {
  .g-posts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell:first-of-type {
  grid-column: 1 / -1;
}

.g-posts {
  grid-template-columns: repeat(3, 1fr);
  gap: 3.5rem 2rem;
  display: grid;
}

@media (max-width: 61.999em) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 47.999em) {
  .g-posts {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 47.999em) {
  .g-posts.--compact {
    flex-direction: column;
    display: flex;
  }
}

.g-posts.--inline {
  flex-direction: column;
  display: flex;
}

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: #e4e8ec;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}

.g-reviews-slider__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-reviews-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-reviews-slider__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 47.999em) {
  .g-reviews-slider__button {
    --offset: calc(50% + .25rem);
  }
}

@media (max-width: 35.999em) {
  .g-reviews-slider__button {
    --offset: calc(50% - .25rem);
  }

  .g-reviews-slider__button.--icon-only {
    --min-height: 2.375rem;
  }
}

.g-reviews-slider.swiper {
  overflow: initial;
  align-items: center;
  min-height: 45.625rem;
  display: flex;
}

.g-reviews-slider .swiper-wrapper {
  align-items: center;
  display: flex;
}

.g-reviews-slider .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  transform: translate3d(0, 0, 0);
}

.g-reviews-slider__buttons {
  pointer-events: none;
}

.g-reviews-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 48em) and (max-width: 93.749em) {
  .g-signpost.--cols-4 {
    max-width: 44.875rem;
    margin-inline: auto;
  }
}

@media (max-width: 47.999em) {
  .g-signpost.--cols-4 {
    max-width: 22.5rem;
    margin-inline: auto;
  }

  .g-signpost.--cols-3 {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.g-steps {
  row-gap: 4.5rem;
}

.g-steps.--vertical {
  max-width: 42.875rem;
  margin-inline: auto;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}

.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}

.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}

@media (max-width: 35.999em) {
  .g-testimonials__button {
    --offset: .5rem;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  min-height: .5rem;
  margin-top: .5rem;
  display: flex;
  position: static;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transform-origin: center;
  transition: width .3s;
}

.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 2rem;
}

.g-testimonials .swiper-slide {
  backface-visibility: hidden;
  flex-shrink: 0;
  width: -moz-fit-content;
  width: fit-content;
  transform: translate3d(0, 0, 0);
}

.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (min-width: 93.75em) {
  .g-usps__cell.col-xxl-2 {
    width: 20%;
  }
}

.g-videos {
  gap: 9.625rem;
}

@media (max-width: 108.749em) {
  .g-videos {
    gap: 8.9375rem;
  }
}

@media (max-width: 93.749em) {
  .g-videos {
    gap: 10.0625rem;
  }
}

@media (max-width: 81.249em) {
  .g-videos {
    flex-direction: column;
    gap: 9.625rem;
  }
}

.g-videos__large-img .i-media .img.--cover {
  min-height: 33.625rem;
  max-height: 33.625rem;
}

@media (max-width: 81.249em) {
  .g-videos__large-img .i-media .img.--cover {
    min-height: auto;
  }
}

@media (min-width: 81.25em) {
  .g-videos__large-img .i-media .img.--cover {
    max-width: 40.9375rem;
  }
}

@media (min-width: 93.75em) {
  .g-videos__large-img .i-media .img.--cover {
    min-width: 50.4375rem;
    max-width: 50.4375rem;
  }
}

@media (min-width: 108.75em) {
  .g-videos__large-img .i-media .img.--cover {
    min-width: 59.375rem;
    max-width: 59.375rem;
  }
}

.g-videos__large-img:after {
  content: "";
  opacity: .26;
  background-color: #7c8793;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: -4.8125rem;
  transform: translate(50%);
}

@media (max-width: 108.749em) {
  .g-videos__large-img:after {
    right: -4.46875rem;
  }
}

@media (max-width: 93.749em) {
  .g-videos__large-img:after {
    right: -5.03125rem;
  }
}

@media (max-width: 81.249em) {
  .g-videos__large-img:after {
    width: 100%;
    height: .0625rem;
    top: calc(100% + 4.8125rem);
    right: 0;
    transform: translate(0, -50%);
  }

  .g-videos__list {
    flex-flow: wrap;
    margin: 0 -.5rem;
  }
}

.g-videos__item:nth-child(n+4) {
  display: none;
}

@media (max-width: 81.249em) {
  .g-videos__item {
    width: 50%;
    max-width: 100%;
    padding: 0 .5rem;
  }
}

@media (max-width: 61.999em) {
  .g-videos__item {
    width: 100%;
    max-width: 100%;
    padding: 0 .5rem;
  }
}

.g-videos-more {
  margin: 4rem -.5rem 0;
}

@media (max-width: 81.249em) {
  .g-videos-more {
    margin-top: 2rem;
  }
}

.g-videos-more.--hidden {
  display: none;
}

.g-videos-more__item {
  padding: 0 .5rem;
}

.icon {
  --size: 1.25rem;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}

.icon svg {
  width: 100%;
  height: 100%;
}

.icon.--colored {
  color: var(--color);
}

.icon.--colored path {
  fill: var(--color);
}

.icon.--primary {
  --color: #143558;
}

.icon.--secondary {
  --color: #a78523;
}

.icon.--white {
  --color: #fff;
}

.icon.--black {
  --color: #081828;
}

.icon.--danger {
  --color: #e52661;
}

.icon.--success {
  --color: #05d686;
}

.icon.--grey {
  --color: #7c8793;
}

.icon.--xxxl {
  --size: 3rem;
}

.icon.--xxl {
  --size: 2.5rem;
}

.icon.--xl {
  --size: 2rem;
}

.icon.--lg {
  --size: 1.5rem;
}

.icon.--md {
  --size: 1.25rem;
}

.icon.--sm {
  --size: 1rem;
}

.icon.--xs {
  --size: .625rem;
}

.img {
  overflow: hidden;
}

.img.--ratio-4-3 {
  aspect-ratio: 4 / 3;
}

.img.--ratio-16-9 {
  aspect-ratio: 16 / 9;
}

.img.--ratio-3-2 {
  aspect-ratio: 3 / 2;
}

.img.--ratio-1-1 {
  aspect-ratio: 1;
}

.img.--ratio-3-4 {
  aspect-ratio: 3 / 4;
}

.img.--contain {
  width: 100%;
  height: 100%;
}

.img.--contain img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.img.--cover {
  width: 100%;
  height: 100%;
}

.img.--cover img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.img.--placeholder {
  position: relative;
}

.img.--placeholder:before {
  content: "";
  z-index: -1;
  background-color: #e4e8ec;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author {
  display: flex;
}

.i-author.--compact {
  align-items: center;
}

.i-author.--compact .i-author__description {
  display: none;
}

@media (max-width: 35.999em) {
  .i-author {
    flex-direction: column;
  }
}

.i-author__image {
  --size: 4rem;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-banner__header {
  max-width: 80ch;
}

.i-banner__image {
  z-index: -1;
  width: 100%;
  height: 100%;
}

.i-banner__description {
  line-height: 1.5rem;
}

.i-banner {
  background-color: #e4e8ec;
}

@media (max-width: 35.999em) {
  .i-banner {
    padding: 2rem;
  }
}

.i-banner.--image {
  background-color: rgba(0, 0, 0, 0);
}

@media (min-width: 48em) {
  .i-banner.--image .i-banner__inner {
    flex-flow: wrap;
    justify-content: space-between;
    align-items: center;
  }
}

.i-banner.--image .i-banner__description {
  display: none;
}

.i-banner.--image .i-banner__buttons {
  margin-top: 0;
}

.i-breadcrumb {
  text-wrap: nowrap;
}

.i-breadcrumb .i-breadcrumb__link {
  --font-size: .875rem;
}

.i-breadcrumb .i-breadcrumb__divider {
  margin-right: 1rem;
}

.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}

.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}

.i-breadcrumb.--current {
  color: #7c8793;
  font-size: .875rem;
}

.i-consultant-stats {
  min-height: 6.1875rem;
  padding-left: 3rem;
  position: relative;
}

.i-consultant-stats:before {
  content: "";
  background-color: #d7ab2d;
  border-radius: .25rem;
  width: .1875rem;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.i-consultant-stats .i-consultant-stats__title {
  word-wrap: break-word;
  word-break: break-all;
  font-family: Fraunces, serif;
  line-height: 5.5rem;
}

.i-document {
  color: #e4e8ec;
  border-style: solid;
  border-width: .0625rem;
  border-radius: .25rem;
}

.i-document__title, .i-document__details {
  color: #143558;
}

@media (max-width: 47.999em) {
  .i-event {
    flex-direction: column;
    max-width: 20rem;
    margin-inline: auto;
  }
}

@media (min-width: 48em) {
  .i-event__image-link {
    width: 17.5rem;
    height: 13.125rem;
  }
}

.i-faq__body {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  background-color: #e4e8ec;
  grid-template-rows: 0fr;
  transition: grid-template-rows .5s;
  display: grid;
}

.i-faq__body-inner {
  position: relative;
  overflow: hidden;
}

.i-faq__body-inner:before {
  content: "";
  background-color: #0b1d30;
  width: 100%;
  height: .0625rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq {
  --border-radius: .375rem;
  border-radius: var(--border-radius);
  border: .0625rem solid #0b1d30;
}

.i-faq svg {
  will-change: transform;
  transition: transform .3s;
}

.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}

.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-hero__body {
  max-width: 37.5rem;
}

@media (max-width: 81.249em) {
  .i-hero__body {
    gap: 1.5rem;
    max-width: 100%;
    position: static;
    left: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 35.999em) {
  .i-hero__body {
    padding: 1rem;
  }
}

.i-hero__content {
  width: 100%;
  max-width: 35.375rem;
}

.i-hero {
  --offset: 4rem;
}

.i-hero.--right .i-hero__body {
  right: var(--offset);
}

@media (min-width: 62em) {
  .i-hero.--center .i-hero__body {
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@media (min-width: 81.25em) {
  .i-hero {
    margin-bottom: -5.5rem;
  }
}

@media (min-width: 93.75em) {
  .i-hero {
    margin-bottom: -14rem;
  }
}

@media (max-width: 61.999em) {
  .i-hero {
    padding-top: 1rem;
  }
}

.i-hero__image {
  width: 60.25rem;
  height: 73.125rem;
  margin-left: auto;
  transition: width .3s, height .3s;
  position: relative;
  top: -13.125rem;
  right: -10.25rem;
}

@media (max-width: 93.749em) {
  .i-hero__image {
    width: 47.5rem;
    height: 57rem;
    top: -9.25rem;
    right: -8.25rem;
  }
}

@media (max-width: 81.249em) {
  .i-hero__image {
    width: 100%;
    max-width: 37.5rem;
    height: auto;
    margin: 0 auto;
    top: -7.5rem;
    right: 0;
  }
}

@media (max-width: 47.999em) {
  .i-hero__image {
    margin-bottom: -2.5rem;
  }
}

.i-hero__share {
  margin-top: 3.625rem;
  margin-bottom: 4.8125rem;
}

.i-hero__share .link {
  --font-size: 1.5rem;
  gap: 1.875rem;
}

@media (max-width: 35.999em) {
  .i-hero__share .link {
    gap: 1rem;
  }
}

.i-hero__share .link.--underline-initial-dynamic .link__title {
  position: relative;
}

.i-hero__share .link.--underline-initial-dynamic .link__title:after {
  bottom: -.5rem;
}

.i-hero__play {
  --min-width: 6.875rem;
}

.i-hero__play .btn__icon {
  min-width: var(--min-width);
  height: var(--min-width);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 .8125rem 1.625rem rgba(0, 0, 0, .16);
}

.i-hero__play .btn__icon .icon {
  margin-left: .5rem;
}

@media (max-width: 61.999em) {
  .i-hero__play .btn__icon {
    --min-width: 5.625rem;
  }

  .i-hero__play .btn__icon .icon {
    --size: 2.5rem;
  }
}

@media (max-width: 35.999em) {
  .i-hero__play .btn__icon {
    --min-width: 4rem;
  }

  .i-hero__play .btn__icon .icon {
    --size: 2rem;
  }
}

.i-hero__youtube .btn__icon {
  margin-left: .25rem;
}

.i-hero-slide__body {
  --radius: .5rem;
  border-radius: var(--radius);
  -webkit-backdrop-filter: blur(1.25rem);
  backdrop-filter: blur(1.25rem);
  max-width: 35.5rem;
}

.i-hero-slide__body:before {
  content: "";
  z-index: -1;
  opacity: .4;
  border-radius: var(--radius);
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 61.999em) {
  .i-hero-slide__body {
    gap: .5rem;
    max-width: 100%;
    position: static;
    left: auto;
    right: auto;
    transform: translate3d(0, 0, 0);
  }
}

@media (max-width: 35.999em) {
  .i-hero-slide__body {
    padding: 1rem;
  }
}

.i-hero-slide {
  --offset: 4rem;
}

.i-hero-slide.--left .i-hero-slide__body {
  left: var(--offset);
}

.i-hero-slide.--right .i-hero-slide__body {
  right: var(--offset);
}

@media (min-width: 62em) {
  .i-hero-slide.--center .i-hero-slide__body {
    left: 50%;
    transform: translateX(-50%)translateY(-50%);
  }
}

@media (max-width: 61.999em) {
  .i-hero-slide {
    padding: 1rem;
  }

  .i-hero-slide__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 1rem;
  overflow: hidden;
}

.i-job__info {
  color: #143558;
  row-gap: .5rem;
}

@media (min-width: 48em) {
  .i-job__info {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 9rem;
  flex-direction: column;
}

@media (min-width: 48em) {
  .i-job {
    flex-direction: row;
  }
}

.i-job.--card {
  --image-size: 28rem;
  flex-direction: column;
  width: 26rem;
}

.i-job.--card .i-job__image {
  max-width: 100%;
  height: auto;
}

.i-job.--card .i-job__info {
  flex-direction: column;
  gap: 1rem;
}

.i-job.--card .i-job__footer {
  flex-direction: column;
  align-items: flex-start;
}

.i-media.--size-sm {
  flex-shrink: 0;
  width: min(100%, 9.6875rem);
  height: 9.6875rem;
}

.i-media.--size-sm .i-media__play-icon {
  display: none;
}

.i-media.--size-sm .i-media__time {
  bottom: .75rem;
  right: .5rem;
}

@media (max-width: 469px) {
  .i-media.--size-sm {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.i-media {
  --border-radius: 1.4375rem;
  border-radius: var(--border-radius);
  box-shadow: 0 .8125rem 1rem #e4e8ec;
}

.i-media:before {
  content: "";
  will-change: background-color, mix-blend-mode;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color .3s, mix-blend-mode .3s;
  position: absolute;
  inset: 0;
}

@media (hover: hover) {
  .i-media:hover:before {
    content: "";
    mix-blend-mode: multiply;
    z-index: 1;
    background-color: #dfbd5b;
  }

  .i-media:hover .i-media__play-icon {
    opacity: 1;
  }
}

.i-media .img {
  border-radius: var(--border-radius);
}

.i-media .i-media__time {
  color: #fff;
  border-radius: var(--border-radius);
  opacity: .75;
  z-index: 2;
  background-color: #020509;
  flex-shrink: 0;
  padding: .5rem 1.25rem;
  bottom: 2.1875rem;
  right: 3.125rem;
}

@media (max-width: 61.999em) {
  .i-media .i-media__time {
    bottom: 1.5625rem;
    right: 1.875rem;
  }
}

@media (max-width: 35.999em) {
  .i-media .i-media__time {
    padding: .5rem .9375rem;
    bottom: .75rem;
    right: .5rem;
  }
}

.i-media .i-media__play-icon {
  --min-width: 9.3125rem;
  min-width: var(--min-width);
  height: var(--min-width);
  z-index: 2;
  opacity: 0;
  background-color: #06111d;
  border-radius: 50%;
  transition: opacity .3s;
  box-shadow: 0 .8125rem 1rem rgba(0, 0, 0, .467);
}

.i-media .i-media__play-icon .icon {
  --size: 4rem;
  margin-left: .5rem;
}

@media (max-width: 61.999em) {
  .i-media .i-media__play-icon {
    --min-width: 7.4375rem;
  }

  .i-media .i-media__play-icon .icon {
    --size: 3.375rem;
  }
}

@media (max-width: 35.999em) {
  .i-media .i-media__play-icon {
    --min-width: 5.5625rem;
  }

  .i-media .i-media__play-icon .icon {
    --size: 2rem;
    margin-left: .25rem;
  }
}

.i-media.--video {
  cursor: pointer;
}

.i-menu__submenu-button {
  --size: 1.5rem;
  width: var(--size);
  height: var(--size);
  rotate: var(--submenu-button-rotate);
  justify-content: center;
  align-items: center;
  margin-left: auto;
  display: flex;
}

@media (max-width: 61.999em) {
  .i-menu__submenu-button {
    --size: 2.5rem;
    will-change: scale;
    scale: var(--submenu-button-scale);
    transition: scale .3s;
    position: absolute;
    top: 0;
    right: 1rem;
  }

  .i-menu__submenu-button:hover svg {
    transform: translateY(.25rem);
  }
}

.i-menu__submenu-button svg {
  transition: transform .3s;
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 1.3125rem;
  --padding-right: 1.3125rem;
  --background: transparent;
  --background-hover: #cad2d9;
}

.i-menu.--open {
  --submenu-display: block;
  --submenu-button-scale: -1;
}

.i-menu > .i-menu__submenu {
  display: var(--submenu-display);
}

.i-menu__link {
  padding-block: .8125rem;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  background-color: var(--background);
  will-change: background-color;
  border-radius: 1.5rem;
  align-items: center;
  gap: .5rem;
  min-height: 2.5rem;
  font-size: 1.125rem;
  text-decoration: none;
  transition: background-color .3s;
  display: flex;
  position: relative;
}

@media (min-width: 62em) {
  .i-menu {
    --padding-left: 1.3125rem;
    --padding-right: 1.3125rem;
    --submenu-display: block;
    --opacity: 0;
    --translate-x: -50%;
    --translate-y: .5rem;
    --translate-y-dot: var(--translate-y);
    --pointer-events: none;
  }

  .i-menu .i-menu__submenu {
    z-index: 50;
    opacity: var(--opacity);
    pointer-events: var(--pointer-events);
    transform: translate(var(--translate-x), var(--translate-y));
    will-change: opacity, transform;
    background-color: #fff;
    border: .0625rem solid #e4e8ec;
    border-radius: 1.25rem;
    width: 100%;
    min-width: 17.5rem;
    padding: 1rem .5rem;
    transition: opacity .3s, transform .3s;
    position: absolute;
    box-shadow: 0 .8125rem 1rem #e4e8ec;
  }

  .i-menu .i-menu__submenu:before {
    content: "";
    opacity: var(--opacity);
    transform: translate(-50%, var(--translate-y-dot));
    will-change: opacity, transform;
    background-color: #d7ab2d;
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    transition: opacity .3s, transform .3s;
    position: absolute;
    top: 0;
    left: 50%;
  }

  .i-menu.--level-1 {
    position: relative;
  }

  .i-menu.--level-1 > .i-menu__submenu {
    top: 100%;
    left: 50%;
  }

  .i-menu.--level-1:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --translate-y-dot: -50%;
    --opacity: 1;
  }

  .i-menu.--level-2 {
    position: relative;
  }

  .i-menu.--level-2 > .i-menu__submenu {
    top: 0;
    left: 100%;
  }

  .i-menu.--level-2 > .i-menu__submenu.--mirror {
    left: auto;
    right: 100%;
  }

  .i-menu.--level-2 .i-menu__link:only-child:hover {
    --background: var(--background-hover);
  }

  .i-menu.--level-2:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --translate-x: 0;
    --translate-y-dot: -50%;
    --opacity: 1;
  }
}

@media (max-width: 61.999em) {
  .i-menu.--level-2 .i-menu__link:only-child:hover {
    --background: var(--background-hover);
  }
}

.i-notification {
  color: #fff;
  background-color: #143558;
  min-height: 2.5rem;
}

@media (max-width: 35.999em) {
  .i-notification {
    text-align: center;
  }
}

.i-notification__container {
  width: min(100% - 2rem, 52rem);
}

@media (max-width: 35.999em) {
  .i-notification__container {
    flex-direction: column;
    gap: .5rem;
  }

  .i-notification__icon {
    margin-inline: auto;
  }

  .i-notification__close {
    order: -1;
  }
}

.i-person__image {
  border-radius: 50%;
  overflow: hidden;
}

@media (min-width: 62em) {
  .i-post.--hero {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 2rem;
    display: grid;
  }
}

@media (max-width: 61.999em) {
  .i-post.--hero {
    max-width: 43rem;
    margin-inline: auto;
  }
}

.i-post.--hero .i-post__tags {
  margin-top: 0;
}

@media (min-width: 62em) {
  .i-post.--hero .i-post__content-col {
    padding-block: 2rem;
  }
}

.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (min-width: 62em) {
  .i-post.--inline {
    grid-template-columns: minmax(10rem, 27.875rem) auto;
    gap: 2rem;
    display: grid;
  }

  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (max-width: 61.999em) {
  .i-post {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan {
  border: .0625rem solid #e4e8ec;
  border-radius: 1.5rem;
}

.i-priceplan.--highlight {
  border: none;
  box-shadow: 0 .5rem 1rem .125rem rgba(15, 28, 123, .15);
}

@media (max-width: 61.999em) {
  .i-priceplan {
    max-width: 28rem;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  color: #0f2944;
  background: #e4e8ec;
  border-radius: .5rem;
  width: calc(100% - 4rem);
  top: -1rem;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 4rem;
}

.i-quote__title {
  word-wrap: break-word;
  white-space: normal;
  width: min(100%, 60.5625rem);
  margin-block: 0;
  margin-inline: auto;
  font-family: Fraunces, serif;
}

.i-quote__icon-top {
  --size: 5.125rem;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.i-quote__icon-bottom {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.i-reviews-slide {
  background-color: #fff;
  border-radius: 1.25rem;
  box-shadow: 0 .8125rem 2.25rem #e4e8ec;
}

.i-reviews-slide__perex {
  color: #7c8793;
}

.i-step {
  --size: 9rem;
}

.i-step:before {
  content: "";
  background-color: #e4e8ec;
  display: block;
  position: absolute;
}

@media (max-width: 35.999em) {
  .i-step:before {
    display: none;
  }
}

.i-step.--column {
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.i-step.--column:before {
  left: 50%;
  top: calc(var(--size) / 2);
  width: calc(100% + 2rem);
  height: .25rem;
  transform: translateX(-50%);
}

.i-step.--inline:before {
  top: 0;
  left: calc(var(--size) / 2);
  width: .25rem;
  height: calc(100% + 4.5rem);
}

@media (max-width: 35.999em) {
  .i-step.--inline {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }
}

.i-step__icon {
  width: var(--size);
  height: var(--size);
  background-color: #e4e8ec;
  border-radius: 50%;
}

.i-step__count {
  color: #143558;
  font-size: 3.5rem;
  bottom: 0;
  left: 0;
}

.i-step__count:after {
  content: ".";
}

.i-tag {
  --padding-x: 1rem;
  --padding-y: .25rem;
  --min-height: 2rem;
  --font-size: 1rem;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  font-size: var(--font-size);
}

.i-tag.--sm {
  --padding-x: .5rem;
  --padding-y: .125rem;
  --min-height: 1.5rem;
  --font-size: .875rem;
}

.i-tag.--lg {
  --padding-x: 1.5rem;
  --padding-y: .25rem;
  --min-height: 2.5rem;
}

.i-tag {
  --font-color: #143558;
  --background-color: #e4e8ec;
  background-color: var(--background-color);
  color: var(--font-color);
  border: .0625rem solid var(--background-color);
  border-radius: .25rem;
}

.i-tag.--outline {
  border-color: #0b1d30;
}

.i-tag.--blank {
  --background-color: transparent;
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-before .i-tag__icon {
  order: -1;
}

.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}

.i-tag.--icon-only .i-tag__title {
  display: none;
}

a.i-tag, .i-tag.--hoverable {
  cursor: pointer;
  text-decoration: none;
  transition: border-color .3s;
}

a.i-tag:hover, .i-tag.--hoverable:hover {
  border-color: #143558;
}

a.i-tag.--blank {
  -webkit-text-decoration: underline rgba(0, 0, 0, 0);
  text-decoration: underline rgba(0, 0, 0, 0);
  transition: text-decoration-color .3s;
}

a.i-tag.--blank:hover {
  -webkit-text-decoration-color: var(--font-color);
  text-decoration-color: var(--font-color);
  border-color: rgba(0, 0, 0, 0);
}

.i-testimonial__image {
  justify-content: center;
  align-items: center;
  display: flex;
}

.i-testimonial__image img {
  object-fit: contain;
  max-width: 15.625rem;
  height: 4.5rem;
}

@media (min-width: 36em) {
  .i-usp.--inline {
    text-align: left;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;
  }

  .i-usp.--inline .i-usp__body {
    align-items: flex-start;
  }
}

.i-usp.--link {
  will-change: box-shadow;
  transition: box-shadow .3s;
  position: relative;
}

.i-usp.--link .i-usp__link-overlay {
  inset: 0;
}

@media (hover: hover) {
  .i-usp.--link:hover {
    box-shadow: 0 .5rem 2rem .125rem rgba(0, 0, 0, .16);
  }
}

.i-usp {
  border-radius: 1.25rem;
  gap: 2.6875rem;
  max-width: 19.625rem;
  padding: 1.5rem;
}

.i-usp__icon .icon {
  --size: 5rem;
}

.i-usp__description {
  max-width: 16.625rem;
}

@media (max-width: 469px) {
  .i-video {
    flex-direction: column;
  }
}

.i-video__text-part {
  height: auto;
}

.i-video__text-part .base-heading {
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  flex-shrink: 0;
  display: -webkit-box;
  overflow: hidden;
}

.i-video__title-link.link {
  --font-size: 1.5rem;
  word-break: break-word;
  font-weight: 700;
}

.i-video__title-link.link:hover {
  text-decoration-color: rgba(0, 0, 0, 0);
}

.i-video__name {
  color: #a78523;
  text-underline-offset: .375rem;
  -webkit-text-decoration: underline dotted #a78523;
  text-decoration: underline dotted #a78523;
  text-decoration-thickness: .25rem;
}

.i-video__date {
  color: #7c8793;
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
}

.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__drowpdown {
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  background-color: #fff;
  transition: opacity .3s;
  top: 100%;
  left: 0;
  box-shadow: 0 .5rem 2rem .125rem rgba(0, 0, 0, .16);
}

.c-language-select__active {
  min-height: 2.5rem;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color .3s;
}

.c-language-select__dropdown-link:hover {
  background-color: #e4e8ec;
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(.68, -.55, .27, 1.55);
}

.link.--animation-right:hover svg {
  transform: translateX(.5rem);
}

.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  color: #081828;
  text-decoration-color: rgba(0, 0, 0, 0);
}

.link.--black:hover, .link.--black:focus {
  color: #081828;
  text-decoration-color: #081828;
}

.link.--secondary {
  color: #a78523;
  text-decoration-color: rgba(0, 0, 0, 0);
}

.link.--secondary:hover, .link.--secondary:focus {
  color: #a78523;
  text-decoration-color: #a78523;
}

.link.--primary-400, .link.--primary-400:hover, .link.--primary-400:focus {
  color: #0f2944;
  text-decoration-color: rgba(0, 0, 0, 0);
}

.link.--grey, .link.--grey:hover, .link.--grey:focus {
  color: #7c8793;
  text-decoration-color: rgba(0, 0, 0, 0);
}

.link__icon svg {
  will-change: transform;
  transition: transform .3s;
}

.link {
  --font-size: 1.125rem;
  --text-decoration-style: dotted;
  --text-decoration-thickness: .25rem;
  --text-decoration-offset: .375rem;
  font-size: var(--font-size);
  text-decoration: underline;
  -webkit-text-decoration-style: var(--text-decoration-style);
  text-decoration-style: var(--text-decoration-style);
  text-decoration-color: rgba(0, 0, 0, 0);
  text-decoration-thickness: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);
  width: -moz-fit-content;
  width: fit-content;
  transition: text-decoration-color .3s, color .3s;
}

.link:hover {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--icon-before .link__icon {
  order: -1;
}

.link.--underline-initial-dynamic {
  --underline-bg: #a78523;
  text-decoration: none;
}

.link.--underline-initial-dynamic .link__title {
  position: relative;
}

.link.--underline-initial-dynamic .link__title:after {
  content: "";
  border-bottom: .25rem dotted var(--underline-bg);
  width: 1.1875rem;
  height: .25rem;
  transition: width .3s;
  display: block;
}

.link.--underline-initial-dynamic:hover, .link.--underline-initial-dynamic:focus {
  text-decoration: none;
}

.link.--underline-initial-dynamic:hover .link__title:after, .link.--underline-initial-dynamic:focus .link__title:after {
  width: 100%;
}

.link.--underline-initial-static {
  -webkit-text-decoration-color: inherit;
  text-decoration-color: inherit;
}

.link.--underline-style-solid {
  --text-decoration-style: solid;
}

.main-footer-column {
  color: #0f2944;
  font-size: 1.125rem;
}

@media (max-width: 35.999em) {
  .main-footer-column {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__youtube .btn__icon {
  margin-left: .25rem;
}

.main-footer .main-footer__top .container {
  position: relative;
}

.main-footer .main-footer__top .container:before {
  content: "";
  background-color: #b0bbc7;
  width: calc(100% - 3.25rem);
  height: .0625rem;
  position: absolute;
  top: -2.5rem;
  left: 1.625rem;
}

@media (min-width: 36em) {
  .main-footer__columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 81.25em) {
  .main-footer__columns {
    grid-template-columns: repeat(12, 1fr);
  }

  .main-footer__columns .main-footer-column:first-child, .main-footer__columns .main-footer-column:nth-of-type(2) {
    grid-column: span 2;
  }

  .main-footer__columns .main-footer-column:nth-of-type(3) {
    grid-column: span 3;
    margin-left: 2rem;
  }

  .main-footer__columns .main-footer-column:last-child {
    grid-column: span 5;
    justify-self: flex-end;
  }
}

@media (min-width: 36em) {
  .main-footer__bottom-columns {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

@media (min-width: 81.25em) {
  .main-footer__bottom-columns {
    grid-template-columns: repeat(12, 1fr);
  }

  .main-footer__bottom-columns .link {
    grid-column: span 2;
  }

  .main-footer__bottom-columns .main-footer__content {
    grid-column: span 10;
  }
}

.main-footer__bottom-edge {
  background-color: #0f2944;
  width: 100%;
  height: 2.5rem;
}

.m-header-search {
  --translate-y: -150%;
  --pointer-events: none;
  --opacity: 0;
}

.m-header-search.--open {
  --translate-y: -50%;
  --pointer-events: auto;
  --opacity: 1;
}

.m-header-search__form {
  z-index: 2;
  transform: translateY(var(--translate-y));
  pointer-events: var(--pointer-events);
  opacity: var(--opacity);
  will-change: transform, opacity;
  background-color: #fff;
  width: 100%;
  height: 100%;
  transition: opacity .3s, transform .3s;
  position: absolute;
  top: 50%;
  left: 0;
}

.m-header {
  --shadow-color: transparent;
  --min-height: 4.6875rem;
  --p-block: .5rem;
  --logo-max-w: 6.625rem;
  --bg-color: #fff;
  background-color: var(--bg-color);
  z-index: 5;
  width: 100%;
  transition: transform .5s;
  position: sticky;
  top: 0;
  left: 0;
}

.m-header.headroom--unpinned {
  transform: translateY(-100%);
}

.m-header.headroom--not-top {
  --logo-max-w: 6rem;
}

@media (min-width: 81.25em) and (max-width: 93.749em) {
  .m-header.headroom--not-top {
    --logo-max-w: 5rem;
  }

  .m-header {
    --logo-max-w: 6.25rem;
  }
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--p-block);
  will-change: padding, min-height;
  transition: padding .3s, min-height .3s;
}

@media (max-width: 35.999em) {
  .m-header__body {
    --min-height: 5rem;
    --p-block: 0;
    min-height: var(--min-height);
    padding-block: var(--p-block);
  }
}

.m-header-logo {
  max-width: var(--logo-max-w);
  will-change: max-width;
  transition: max-width .3s;
}

.m-nav {
  --transition-timing: 0;
}

@media (min-width: 62em) {
  .m-nav {
    margin-left: auto;
  }
}

@media (max-width: 61.999em) {
  .m-nav {
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    background-color: #fff;
    width: 100%;
    max-width: 26.25rem;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    overflow: hidden auto;
    transform: translateX(100%);
    box-shadow: 0 .5rem 2rem .125rem rgba(0, 0, 0, .16);
  }

  .m-nav.--open {
    transform: translateX(0);
  }

  .m-nav.--transition {
    --transition-timing: .3s;
  }
}

@media (min-width: 108.75em) {
  .m-nav__list {
    margin-right: 5.3125rem;
  }
}

@media (min-width: 62em) {
  .m-nav__list {
    flex-direction: row;
    align-items: start;
  }
}

.i-menu__link.--underline-initial-dynamic {
  padding-bottom: .125rem;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.i-menu__link.--underline-initial-dynamic:after {
  content: "";
  border-bottom: .25rem dotted #a78523;
  width: 1.1875rem;
  height: .25rem;
  transition: width .3s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
}

.i-menu__link.--underline-initial-dynamic:hover:after, .i-menu__link.--underline-initial-dynamic:focus:after {
  width: 100%;
}

.i-menu__link.--underline-initial-dynamic .link__title:after {
  display: none;
}

.i-menu__link.--underline-initial-dynamic .link__icon {
  margin-left: -.09375rem;
}

.i-menu__link.--underline-initial-dynamic .link__icon .icon.--colored path.uuid-089ecb80-19e4-441b-88cf-bc590cae2494 {
  fill: none;
}

.i-menu__customers {
  padding-bottom: .8125rem;
  padding-inline: 1.3125rem;
  padding-right: 0;
}

.i-menu__customers .i-menu__link {
  border-radius: 0;
}

.c-modal__dialog {
  pointer-events: none;
  cursor: cell;
  width: auto;
  margin: 2rem auto;
  position: relative;
}

.c-modal__content {
  width: var(--width);
  max-height: calc(calc(var(--vh, 1vh) * 100)  - 4rem);
  pointer-events: auto;
  cursor: auto;
  transition: transform var(--transtion-length) ease;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  max-height: calc(100vh - 4rem);
  margin-inline: auto;
  position: relative;
  overflow: hidden;
  transform: translateY(2rem);
  box-shadow: .25rem .25rem 1.5rem rgba(15, 38, 57, .1);
}

.c-modal__inner {
  max-height: calc(calc(var(--vh, 1vh) * 100)  - 4rem);
  max-height: calc(100vh - 4rem);
  overflow: auto;
}

.c-modal__body {
  flex: auto;
  padding: 2rem 1.5rem;
  position: relative;
}

@media (max-width: 47.999em) {
  .c-modal__body {
    padding: 1rem;
  }
}

.c-modal__header {
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 1.5rem 0;
  display: flex;
}

.c-modal__button-container {
  order: -1;
  justify-content: flex-end;
  display: flex;
}

.c-modal__close-cross {
  transform: translate(-1rem, 1rem);
}

.c-modal__footer {
  padding: 0 1.5rem 2rem;
}

.c-modal__footer.--right {
  justify-content: flex-end;
}

.c-modal__footer.--center {
  justify-content: center;
}

.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 1.375rem;
}

.c-modal {
  --transtion-length: .3s;
  --width: min(100% - 2rem, 45rem);
  z-index: 9000;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
  background-color: rgba(0, 0, 0, .25);
  outline: 0;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

@supports (-webkit-touch-callout: none) {
  .c-modal {
    height: -webkit-fill-available;
  }
}

.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}

.c-modal.--open .c-modal__content {
  transform: translateY(0);
}

.c-modal.--hidden {
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
}

.c-modal.--size-sm {
  --width: min(100% - 2rem, 30rem);
}

.c-modal.--size-xl {
  --width: min(100% - 2rem, 71.25rem);
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 1;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  position: var(--position);
  background-color: #081828;
  transition: opacity .3s;
  inset: 0;
}

.c-overlay.--show {
  --opacity: .75;
  --pointer-events: auto;
}

.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 2.5rem;
  background-color: var(--background);
  min-height: var(--size);
  border-radius: .5rem;
  padding: .5rem 1rem;
  transition-property: text-decoration-color, color, background-color;
}

.link.c-pagination__link:hover {
  --background: #e4e8ec;
}

.link.c-pagination__link.--page-number {
  min-width: var(--size);
  padding: .5rem;
  text-decoration: none;
}

.link.c-pagination__link.--active {
  --background: #143558;
  pointer-events: none;
  color: #fff;
}

.link.c-pagination__link.--blank {
  pointer-events: none;
}

.link.c-pagination__link.--prev:hover, .link.c-pagination__link.--next:hover {
  --background: transparent;
}

@media (max-width: 47.999em) {
  .c-pagination__items {
    flex-basis: 100%;
    order: -1;
    justify-content: center;
  }

  .c-pagination {
    justify-content: center;
  }
}

.c-scroll-up {
  --size: 2.5rem;
  --offset: 2.5rem;
  --radius: 1.25rem;
  --background: #143558;
  --background-hover: #0f2944;
  --icon-size: 1rem;
  --icon-color: #fff;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border-radius: var(--radius);
  background-color: var(--background);
  z-index: 50;
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(.8);
  cursor: pointer;
  transition: background-color .3s, opacity .3s cubic-bezier(.68, -.55, .27, 1.55), transform .3s cubic-bezier(.68, -.55, .27, 1.55);
}

.c-scroll-up:hover {
  --background: var(--background-hover);
}

.c-scroll-up:after {
  content: "";
  opacity: 0;
  border-radius: 1.25rem;
  width: 100%;
  height: 100%;
  transition: opacity .5s, box-shadow .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 0 .625rem 2.5rem #fff;
}

.c-scroll-up:active:after {
  opacity: 1;
  transition: all;
  position: absolute;
  box-shadow: 0 0 #fff;
}

.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
}

.c-scroll-up path {
  fill: var(--icon-color);
}

.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0)scale(1);
}

@media (max-width: 61.999em) {
  .s-breadcrumbs {
    display: none;
  }
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-calendar__app {
  --fc-border-color: #e4e8ec;
  --fc-today-bg-color: #e4e8ec;
  --fc-event-bg-color: #143558;
  --fc-button-bg-color: #143558;
  --fc-button-border-color: #143558;
  --fc-button-active-bg-color: #0f2944;
  --fc-button-active-border-color: #0f2944;
  --fc-button-hover-bg-color: #0f2944;
  --fc-button-hover-border-color: #0f2944;
  height: 35.625rem;
}

.s-calendar__app.fc {
  height: auto;
}

.s-calendar__app.--close {
  display: none;
}

.s-calendar__app .fc-daygrid {
  background-color: #fff;
}

.s-calendar__app .fc-event {
  border: none;
  border-radius: 0;
}

.s-calendar__app .fc-daygrid-day-number {
  color: #143558;
  text-decoration: none;
}

.s-calendar__app .fc-col-header-cell-cushion {
  text-decoration: none;
}

.s-calendar__app .fc-col-header-cell {
  padding: .5rem;
}

.s-calendar__toggler.--close svg {
  transform: scale(-1);
}

.s-consultant-about__header {
  --spacing: 3.1875rem;
}

.s-consultant-about__wrapper {
  flex-direction: column;
  row-gap: 2rem;
  padding: 0;
  display: flex;
}

@media (min-width: 62em) {
  .s-consultant-about__wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .s-consultant-about__wrapper .s-consultant-about__content {
    width: 100%;
    max-width: 28.125rem;
  }
}

@media (min-width: 93.75em) {
  .s-consultant-about__wrapper .s-consultant-about__content {
    width: 100%;
    max-width: 33.75rem;
  }
}

.s-consultant-meet__img.--contain {
  aspect-ratio: 1 / .982;
  width: 35.625rem;
}

@media (max-width: 61.999em) {
  .s-consultant-meet__img {
    display: none;
  }
}

.s-consultant-meet__header .base-header__description {
  --font-family: Montserrat, verdana;
  --font-size: 1.5rem;
  --font-weight: 400;
  --font-style: normal;
  --color: #143558;
  width: 100%;
  max-width: 37.125rem;
  margin-top: .5rem;
  font-size: 1.5rem;
  line-height: 2.3125rem;
  display: block;
}

.s-consultant-meet__text-part {
  width: min(100%, 39.375rem);
  margin-left: -1rem;
}

@media (max-width: 61.999em) {
  .s-consultant-meet__text-part {
    margin: 0;
  }
}

.s-consultant-meet__button {
  --font-size: 1.5rem;
  --border-radius: 2.5625rem;
  gap: 1.25rem;
  width: min(100%, 25.5rem);
}

@media (max-width: 61.999em) {
  .s-consultant-meet__button {
    --font-size: 1rem;
  }

  .s-consultant-meet__button .icon.--lg {
    --size: 1.25rem;
  }
}

.s-consultant-text-media {
  padding-top: 2.75rem;
  padding-bottom: 0;
  position: relative;
}

@media (max-width: 81.249em) {
  .s-consultant-text-media {
    padding-bottom: 5.5rem;
  }
}

@media (max-width: 35.999em) {
  .s-consultant-text-media {
    padding-bottom: 2.75rem;
  }
}

.s-consultant-video__header {
  --spacing: 4.3125rem;
}

.s-consultant-video__media {
  --width: 80rem;
  --max-height: 45.3125rem;
  width: min(100%, var(--width));
  max-height: var(--max-height);
  height: 100%;
  margin-inline: auto;
}

.s-consultant-video__media .img {
  width: min(100%, var(--width));
  max-height: var(--max-height);
  height: 100%;
}

.s-error-404__heading {
  --font-size: 11rem;
  font-size: var(--font-size);
}

@media (max-width: 47.999em) {
  .s-error-404__heading {
    --font-size: 8.5rem;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 1.5rem;
}

.s-error-404__perex {
  text-align: center;
  margin-inline: auto;
  max-width: 45rem;
  margin-bottom: 0;
}

.s-jobs__footer {
  background-color: #e4e8ec;
}

@media (min-width: 48em) {
  .s-jobs__footer {
    padding: 3rem 4rem;
  }
}

@media (max-width: 35.999em) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }

  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }

  .s-post-detail__share {
    flex-direction: column;
  }
}

.s-quote {
  --padding-y: 11rem;
}

.s-reviews-slider__ellipse-1.icon {
  --size: 45.625rem;
  min-width: var(--size);
  z-index: -2;
  top: calc(50% + .8125rem);
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-reviews-slider__ellipse-2.icon {
  --size: 30.75rem;
  min-width: var(--size);
  z-index: -1;
}

.s-reviews-slider__testimonials {
  margin-top: 4.6875rem;
  margin-bottom: 3.125rem;
}

.s-text-media__grid {
  flex-direction: column;
  display: flex;
}

@media (min-width: 62em) {
  .s-text-media__grid {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}

.s-text-media__header-in {
  gap: .5rem;
  margin-bottom: 1.5rem;
}

.s-text-media.--reverse .s-text-media__col.--content-col {
  order: -1;
}

.s-usps__header {
  --spacing: 5rem;
}

.s-videos__button {
  margin-top: 4rem;
}

.s-videos__button.--hidden {
  display: none;
}

.s-videos__header {
  --spacing: 4.25rem;
}

.widget_categories select {
  --border-color: #e4e8ec;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  width: 100%;
  height: 3rem;
  margin: 0;
  padding: .5rem;
}

.wp-aside-widgets .cat-item {
  align-items: center;
  padding-right: .5rem;
  display: flex;
}

.wp-aside-widgets .cat-item > a {
  width: 100%;
}

.widget_media_image a {
  display: block;
}

.widget_nav_menu .menu-item-has-children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_nav_menu .menu-item-has-children .sub-menu {
  padding-left: 1rem;
}

.widget_nav_menu .menu-item-has-children > .sub-menu {
  display: var(--submenu-display);
}

.widget_nav_menu .menu-item-has-children > a {
  padding-right: 3rem;
}

.widget_nav_menu .menu-item-has-children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_pages .page_item.active {
  text-decoration: underline;
}

.widget_pages .page_item_has_children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}

.widget_pages .page_item_has_children .sub-menu {
  padding-left: 1rem;
}

.widget_pages .page_item_has_children > .children {
  display: var(--submenu-display);
}

.widget_pages .page_item_has_children > a {
  padding-right: 3rem;
}

.widget_pages .page_item_has_children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_search form > div {
  gap: .5rem;
  display: flex;
}

.widget_search input[type="text"] {
  --border-color: #e4e8ec;
  --radius: .25rem;
  border: .0625rem solid var(--border-color);
  border-radius: var(--radius);
  -webkit-appearance: none;
  appearance: none;
  background-clip: padding-box;
  width: 100%;
  min-height: 2.875rem;
  padding-left: .5rem;
  display: block;
}

.widget_search input[type="text"]:focus {
  border-color: none;
  outline: none;
}

.widget_search input[type="submit"] {
  --border-radius: .375rem;
  --background: #143558;
  --font-color: #fff;
  color: var(--font-color);
  background-color: var(--background);
  border-radius: var(--border-radius);
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  touch-action: manipulation;
  border: none;
  height: 100%;
  min-height: 2.875rem;
  padding-block: .5rem;
  padding-inline: 1rem;
  text-decoration: none;
  transition: background-color .3s;
  display: block;
}

.widget_search input[type="submit"]:hover {
  color: var(--font-color);
  text-decoration: none;
}

.widget_search input[type="submit"]:focus {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

.widget_search input[type="submit"]:active {
  color: var(--font-color);
  outline: 0;
  text-decoration: none;
}

@media (hover: hover) {
  .widget_search input[type="submit"]:hover {
    --background: #0f2944;
  }
}

.wp-aside-widgets ul {
  flex-direction: column;
  display: flex;
}

.wp-aside-widgets .widget {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (max-width: 61.999em) {
  .wp-aside-widgets .widget {
    --content-display: none;
    --svg-rotate: 0deg;
  }

  .wp-aside-widgets .widget ul, .wp-aside-widgets .widget form {
    display: var(--content-display);
  }

  .wp-aside-widgets .widget.--open {
    --content-display: flex;
    --svg-rotate: 180deg;
  }
}

.wp-aside-widgets .widgettitle {
  --size: 1.5rem;
  font-size: var(--size);
  font-weight: 700;
  display: block;
  position: relative;
}

@media (max-width: 47.999em) {
  .wp-aside-widgets .widgettitle {
    --size: 1.25rem;
  }
}

.wp-aside-widgets .wp-caption {
  flex-direction: column;
  gap: .5rem;
  max-width: 100%;
  margin: 0;
  display: flex;
}

.wp-aside-widgets li {
  --color: #081828;
  display: block;
}

.wp-aside-widgets li > a {
  padding-block: .5rem;
  color: var(--color);
  min-height: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  text-decoration-color: rgba(0, 0, 0, 0);
  transition: text-decoration-color .3s;
  display: block;
}

.wp-aside-widgets li > a:hover {
  -webkit-text-decoration-color: var(--color);
  text-decoration-color: var(--color);
}

.widget__dropdown-btn {
  --size: 1.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.widget__dropdown-btn svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

@media (min-width: 62em) {
  .widget__dropdown-btn {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 2.5rem;
  cursor: pointer;
  width: var(--size);
  height: var(--size);
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.wp-aside-widgets .sub-menu__button svg {
  rotate: var(--svg-rotate);
  transition: rotate .3s;
}

.wp-breadcrumbs {
  --gap: 1.5rem;
}

.wp-breadcrumbs__group > span {
  gap: var(--gap);
  display: flex;
}

@media (max-width: 47.999em) {
  .wp-breadcrumbs__group {
    display: none;
  }
}

.wp-breadcrumbs__group > span span {
  --height: 3.5rem;
  min-height: var(--height);
  align-items: center;
  display: flex;
}

.wp-breadcrumbs__group > span span a {
  display: block;
}

.wp-breadcrumbs__group > span span:first-child a {
  color: rgba(0, 0, 0, 0);
  height: var(--height);
  -webkit-user-select: none;
  user-select: none;
  background-image: url("home.595e1735.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1rem;
  width: 1.5rem;
  display: inline-block;
  position: relative;
}

.wp-breadcrumbs__group > span span.breadcrumb_last {
  font-weight: 600;
}

.wp-breadcrumbs__group > span span:not(:first-child) {
  position: relative;
}

.wp-breadcrumbs__group > span span:not(:first-child):before {
  content: "";
  left: calc(var(--gap) / -2);
  background-color: #143558;
  width: .25rem;
  height: .25rem;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateX(-50%)translateY(-50%);
}

.entry-content strong:empty, .entry-content b:empty, .entry-content p:empty {
  display: none;
}

.entry-content hr {
  background: #e4e8ec;
  border: none;
  height: .0625rem;
  margin: 3rem 0;
}

.entry-content .wp-caption {
  text-align: center;
  border: 0;
  max-width: 100%;
  padding: 0;
}

.entry-content .wp-caption-text {
  color: #081828;
  text-align: center;
  background: rgba(235, 235, 235, .39);
  margin-bottom: 0;
  margin-left: 0;
  padding: .25rem .625rem;
  font-size: 1rem;
  line-height: 1.5rem;
  transform: none;
}

.entry-content blockquote {
  background-color: #e4e8ec;
  flex-direction: column;
  gap: .5rem;
  margin-block: 2rem;
  padding: 1rem;
  font-style: italic;
  font-weight: 400;
  display: flex;
  position: relative;
}

.entry-content blockquote p {
  margin-bottom: 0;
  font-style: italic;
}

.entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
}

@media (max-width: 35.999em) {
  .entry-content h1, .entry-content h2, .entry-content h3, .entry-content h4, .entry-content h5, .entry-content h6 {
    margin-bottom: 1.5rem !important;
  }
}

.entry-content h1 {
  margin-top: 0;
  position: relative;
}

.entry-content h2 {
  position: relative;
}

.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}

.entry-content img {
  max-width: 100%;
  margin-block: 1rem;
}

.entry-content ol > li {
  counter-increment: item;
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ol > li:before {
  content: counter(item) ".";
  color: #143558;
  width: 1.75rem;
  height: 1.75rem;
  margin-left: -2.1875rem;
  margin-right: .5rem;
  padding: .0625rem .25rem .0625rem .375rem;
  font-size: 1.125rem;
  font-weight: 700;
  display: inline-flex;
  top: .0625rem;
}

.entry-content ol > li ol > li {
  counter-increment: sub-item;
}

.entry-content ol > li ol > li:before {
  content: counter(sub-item) ".";
}

.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}

.entry-content ol > li ol > li ol > li:before {
  content: counter(sub-sub-item) ".";
}

.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-alpha"] li:before {
  content: counter(item, lower-alpha) ")";
}

.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-alpha"] li:before {
  content: counter(item, upper-alpha) ")";
}

.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-latin"] li:before {
  content: counter(item, lower-latin) ")";
}

.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-latin"] li:before {
  content: counter(item, upper-latin) ")";
}

.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-greek"] li:before {
  content: counter(item, lower-greek) ")";
}

.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-greek"] li:before {
  content: counter(item, upper-greek) ")";
}

.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: lower-roman"] li:before {
  content: counter(item, lower-roman) ")";
}

.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}

.entry-content ol[style*="list-style-type: upper-roman"] li:before {
  content: counter(item, upper-roman) ")";
}

.entry-content ul {
  list-style-type: none;
}

.entry-content ul > li {
  padding-left: 1.5rem;
  position: relative;
}

.entry-content ul > li:before {
  content: "";
  background-color: #143558;
  border-radius: 50%;
  width: .5rem;
  height: .5rem;
  display: block;
  position: absolute;
  top: .5rem;
  left: 0;
}

.entry-content ul, .entry-content ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.entry-content ul ul, .entry-content ul ol, .entry-content ol ul, .entry-content ol ol {
  margin-top: .5rem;
  margin-bottom: 0;
}

.entry-content ul li, .entry-content ol li {
  margin-bottom: .5rem;
}

.entry-content p {
  --font-size: 1.125rem;
  font-size: var(--font-size);
  color: #143558;
  margin-bottom: 1.5rem;
  line-height: 1.8125rem;
}

@media (max-width: 35.999em) {
  .entry-content p {
    --font-size: 1rem;
  }
}

.entry-content .table-responsive {
  border: 1rem solid #fff;
  margin: 1rem 0;
  overflow: auto;
  box-shadow: 0 0 0 .0625rem #e4e8ec;
}

.entry-content th, .entry-content td, .entry-content tr {
  border: none !important;
}

.entry-content td, .entry-content th {
  text-align: center;
  padding: 1rem;
  position: relative;
}

.entry-content thead {
  color: #fff;
  background-color: #143558;
  font-size: 1rem;
  font-weight: bold;
}

.entry-content tbody {
  color: #081828;
  background-color: #fff;
  font-size: .875rem;
}

.entry-content tbody tr:nth-child(2n) {
  background-color: rgba(235, 235, 235, .39);
}

.entry-content tbody td, .entry-content tbody th {
  padding: .5rem;
}

.entry-content tbody td:before, .entry-content tbody th:before {
  content: "";
  background: #e4e8ec;
  width: .0625rem;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.entry-content tbody td:last-child:before, .entry-content tbody th:last-child:before {
  display: none;
}

.entry-content table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 0 !important;
}

.entry-content table p {
  font-size: 1rem;
  transform: none;
  margin-bottom: 0 !important;
}

.g-search-results {
  flex-direction: column;
  gap: 2rem;
  padding-top: 5rem;
  display: flex;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  text-decoration-color: rgba(0, 0, 0, 0);
}

.i-search-result__heading-link:hover {
  text-decoration-color: #143558;
}

.i-search-result__link {
  text-decoration-color: rgba(0, 0, 0, 0);
  transition-property: color, text-decoration-color;
}

.i-search-result__link:hover {
  color: #143558;
  text-decoration-color: #143558;
}

.entry-content .gallery-item {
  margin: 0;
  padding: 0;
  position: relative;
}

.entry-content .gallery-item a {
  transition: opacity .3s;
  display: block;
}

.entry-content .gallery-item a:hover {
  opacity: .7;
}

.entry-content .gallery-item img {
  object-fit: contain;
  height: 100%;
  margin: 0 auto;
}

.search-form__wrap {
  align-items: center;
  gap: 1rem 2rem;
  max-width: 80ch;
  display: flex;
  position: relative;
}

@media (max-width: 61.999em) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  flex-grow: 1;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 .0625rem #e4e8ec;
}

.search-form__input {
  color: #081828;
  background: none;
  border: 0;
  outline: 0;
  flex-grow: 1;
  padding: 0 1rem;
}

@media (max-width: 35.999em) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  transition: background-color .2s;
  display: flex;
}

.search-form__submit svg {
  fill: #143558;
  width: 1.125rem;
  height: 1.125rem;
  transition: fill .2s;
}

.search-form__submit:hover {
  background-color: #143558;
}

.search-form__submit:hover svg {
  fill: #fff;
}

.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  align-items: center;
  gap: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: grid;
}

.entry-content .gallery > br {
  display: none;
}

.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}

@media (max-width: 61.999em) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}

@media (max-width: 47.999em) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 47.999em) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}

.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}

.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}

/*# sourceMappingURL=index.css.map */
