@use "settings/s-colors.scss" as colors;

.main-footer-column {
  color: colors.$primary-400;
  font-size: 18px;

  @include media-breakpoint-down(sm) {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__youtube {
  & .btn__icon {
    margin-left: 4px;
  }
}
