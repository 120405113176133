@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.entry-content {
  p {
    --font-size: 18px;

    margin-bottom: general.$spacing * 3;
    font-size: var(--font-size);
    color: colors.$primary;
    line-height: 29px;

    @include media-breakpoint-down(sm) {
      --font-size: 16px;
    }
  }
}
