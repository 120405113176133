.s-reviews-slider__ellipse-1 {
  &.icon {
    --size: 730px;

    min-width: var(--size);
    z-index: -2;
    top: calc(50% + 13px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.s-reviews-slider__ellipse-2 {
  &.icon {
    --size: 492px;

    min-width: var(--size);
    z-index: -1;
  }
}

.s-reviews-slider__testimonials {
  margin-top: 75px;
  margin-bottom: 50px;
}
