@use "settings/s-shadows" as shadows;

.i-usp {
  &.--link {
    position: relative;
    transition: box-shadow 0.3s ease;
    will-change: box-shadow;

    & .i-usp__link-overlay {
      inset: 0;
    }

    @media (hover: hover) {
      &:hover {
        box-shadow: shadows.$shadow;
      }
    }
  }
}
