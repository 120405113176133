@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.m-nav {
  --transition-timing: 0;

  @include media-breakpoint-up(lg) {
    margin-left: auto;
  }

  @include media-breakpoint-down(lg) {
    position: fixed;
    right: 0;
    top: 0;
    background-color: colors.$white;
    box-shadow: shadows.$shadow;
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    &.--open {
      transform: translateX(0);
    }

    &.--transition {
      --transition-timing: 0.3s;
    }
  }
}

.m-nav__list {
  @include media-breakpoint-up(xxxl) {
    margin-right: 85px;
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: start;
  }
}

.i-menu__link {
  &.--underline-initial-dynamic {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 2px;

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(50%);
      height: 4px;
      display: block;
      border-bottom: 4px dotted colors.$secondary-400;
      width: 19px;
      transition: width 0.3s ease;
    }

    &:hover::after,
    &:focus::after {
      width: 100%;
    }

    & .link__title {
      &::after {
        display: none;
      }
    }

    & .link__icon {
      margin-left: -1.5px;

      .icon {
        &.--colored {
          path.uuid-089ecb80-19e4-441b-88cf-bc590cae2494 {
            fill: none;
          }
        }
      }
    }
  }
}

.i-menu__customers {
  padding-bottom: 13px;
  padding-inline: 21px;
  padding-right: 0;

  & .i-menu__link {
    border-radius: 0;
  }
}
