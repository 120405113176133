@use "settings/s-colors" as colors;

.i-step {
  --size: 144px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    background-color: colors.$primary-900;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  &.--column {
    flex-direction: column;
    align-items: center;
    text-align: center;

    &::before {
      left: 50%;
      top: calc(var(--size) / 2);
      transform: translateX(-50%);
      height: 4px;
      width: calc(100% + 32px);
    }
  }

  &.--inline {
    &::before {
      top: 0;
      left: calc(var(--size) / 2);
      height: calc(100% + 72px);
      width: 4px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
}

.i-step__icon {
  width: var(--size);
  height: var(--size);
  background-color: colors.$primary-900;
  border-radius: 50%;
}

.i-step__count {
  bottom: 0;
  left: 0;
  font-size: 56px;
  color: colors.$primary;

  &::after {
    content: ".";
  }
}
