@use "settings/s-fonts" as fonts;

.i-quote__title {
  width: min(100%, 969px);
  font-family: fonts.$secondary;
  margin-inline: auto;
  margin-block: 0;
  word-wrap: break-word;
  white-space: normal;
}

.i-quote__icon-top {
  --size: 82px;

  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.i-quote__icon-bottom {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 50%);
}
