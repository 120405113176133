@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.entry-content {
  .table-responsive {
    margin: general.$spacing * 2 0;
    border: general.$spacing * 2 solid colors.$white;
    box-shadow: 0 0 0 1px colors.$primary-900;
    overflow: auto;
  }

  th,
  td,
  tr {
    border: none !important;
  }

  td,
  th {
    padding: general.$spacing * 2;
    text-align: center;
    position: relative;
  }

  thead {
    background-color: colors.$primary;
    color: colors.$white;
    font-size: 16px;
    font-weight: bold;
  }

  tbody {
    background-color: colors.$white;
    color: colors.$black;
    font-size: 14px;

    tr {
      &:nth-child(2n) {
        background-color: rgb(235 235 235 / 39%);
      }
    }

    td,
    th {
      padding: general.$spacing;

      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 1px;
        background: colors.$primary-900;
        top: 0;
        right: 0;
      }

      &:last-child {
        &::before {
          display: none;
        }
      }
    }
  }

  table {
    margin-bottom: 0 !important;
    width: 100%;
    max-width: 100%;

    p {
      margin-bottom: 0 !important;
      font-size: 16px;
      transform: none;
    }
  }
}
