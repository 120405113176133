@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-reviews-slide {
  background-color: colors.$white;
  border-radius: 20px;
  box-shadow: 0 13px 36px shadows.$color-grey;
}

.i-reviews-slide__perex {
  color: colors.$grey-100;
}
