@use "settings/s-colors.scss" as colors;

.u-bg-primary {
  background-color: colors.$primary;
}

.u-bg-secondary-500 {
  background-color: colors.$secondary-500;
}

.u-bg-white {
  background-color: colors.$white;
}
