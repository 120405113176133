@use "settings/s-colors" as colors;

.widget_categories {
  select {
    --border-color: #{colors.$primary-900};
    --radius: 4px;

    border: 1px solid var(--border-color);
    border-radius: var(--radius);
    height: 48px;
    width: 100%;
    padding: 8px;
    margin: 0;
  }
}
