@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.btn {
  &.--primary {
    --background-color: #{colors.$grey};
    --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-primary};

    background-color: var(--background-color);
    box-shadow: var(--shadow);
    transition: 0.3s ease;
    transition-property: background-color, box-shadow;
    will-change: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background-color: #{colors.$primary-900};
        --shadow: inset 0px -3px 0px #{shadows.$color-01}, 0px 3px 20px #{shadows.$color-primary};
      }
    }
  }

  &.--secondary {
    --background-color: #{colors.$secondary-500};
    --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-secondary};

    background-color: var(--background-color);
    box-shadow: var(--shadow);
    transition: 0.3s ease;
    transition-property: background-color, box-shadow;
    will-change: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background-color: #{colors.$secondary-600};
        --shadow: inset 0px -3px 0px #{shadows.$color-01}, 0px 3px 20px #{shadows.$color-secondary};
      }
    }
  }

  &.--tertiary {
    --font-color: #{colors.$white};
    --background-color: #{colors.$primary};
    --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #14355865;
    --border-radius: 44px;

    background-color: var(--background-color);
    box-shadow: var(--shadow);
    transition: 0.3s ease;
    transition-property: background-color, box-shadow;
    will-change: background-color, box-shadow;

    @media (hover: hover) {
      &:hover {
        --background-color: #{colors.$primary-hover};
        --shadow: inset 0px -3px 0px #00000086, 0px 9px 16px #14355865;
      }
    }
  }

  &.--outline {
    --font-color: #{colors.$primary};
    --border-color: #{colors.$primary};
    --background: #{colors.$white};

    background-color: var(--background);
    border: 1px solid var(--border-color);
    color: var(--font-color);
    will-change: border-color, background-color, color;
    transition: 0.3s ease;
    transition-property: border-color, background-color, color;

    @media (hover: hover) {
      &:hover {
        --border-color: transparent;
        --background: #{colors.$primary};
        --font-color: #{colors.$white};

        path {
          fill: var(--font-color);
        }
      }
    }
  }

  &%--youtube,
  &.--youtube {
    --background-color: #{colors.$youtube};
    --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-secondary};

    background-color: var(--background-color);
    box-shadow: var(--shadow);
    transition: 0.3s ease;
    transition-property: background-color, box-shadow;
    will-change: background-color, box-shadow;

    & .btn__icon::before {
      background-color: colors.$secondary-500;
      content: "";
      width: calc(var(--min-height) + 1px);
      height: calc(var(--min-height) + 1px);
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      border-radius: 50%;
      transition: 0.3s ease-in-out;
      transition-property: width, height;
      will-change: width, height;
    }

    @media (hover: hover) {
      &:hover {
        --background-color: #{colors.$youtube};
        --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-youtube};

        & .btn__icon::before {
          content: "";
          width: 0;
          height: 0;
        }
      }
    }
  }

  &.--facebook {
    @extend %--youtube;

    --background-color: #{colors.$facebook};

    @media (hover: hover) {
      &:hover {
        --background-color: #{colors.$facebook};
        --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-facebook};
      }
    }
  }

  &.--instagram {
    @extend %--youtube;

    background-image: colors.$instagram;

    @media (hover: hover) {
      &:hover {
        --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-secondary};

        background-image: colors.$instagram;
      }
    }
  }

  &.--linkedin {
    @extend %--youtube;

    --background-color: #{colors.$linkedin};

    @media (hover: hover) {
      &:hover {
        --background-color: #{colors.$linkedin};
        --shadow: inset 0px 0px 0px #{shadows.$color-01}, 0px 13px 20px #{shadows.$color-linkedin};
      }
    }
  }

  &.--play {
    --background-color: rgb(255 255 255 / 70%);
    --border-radius: 79px;
    --max-width: 374px;
    --font-size: 24px;
    --font-weight: 400;
    --padding-y: 16px;
    --padding-x: 16px;

    max-width: var(--max-width);
    position: absolute;
    bottom: 264px;
    left: 24px;
    transform: translate(-50%, 0);
    opacity: 1;
    background-color: var(--background-color);
    backdrop-filter: blur(12px);

    @include media-breakpoint-down(xxl) {
      bottom: 100px;
    }

    @include media-breakpoint-down(xl) {
      bottom: 0;
      left: 50%;
    }

    @include media-breakpoint-down(lg) {
      --font-size: 18px;
      --max-width: 300px;
    }
  }
}
