.g-consultant-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100px 100px;
  row-gap: 98px;
  column-gap: 49px;

  @include media-breakpoint-down(xl) {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
}
