@use "settings/s-colors" as colors;

.link.c-pagination__link {
  --background: transparent;
  --size: 40px;

  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--background);
  min-height: var(--size);
  transition-property: text-decoration-color, color, background-color;

  &:hover {
    --background: #{colors.$primary-900};
  }

  &.--page-number {
    padding: 8px;
    text-decoration: none;
    min-width: var(--size);
  }

  &.--active {
    --background: #{colors.$primary};

    pointer-events: none;
    color: colors.$white;
  }

  &.--blank {
    pointer-events: none;
  }

  &.--prev,
  &.--next {
    &:hover {
      --background: transparent;
    }
  }
}

.c-pagination__items {
  @include media-breakpoint-down(md) {
    order: -1;
    flex-basis: 100%;
    justify-content: center;
  }
}

.c-pagination {
  @include media-breakpoint-down(md) {
    justify-content: center;
  }
}
