@use "settings/s-colors" as colors;

.f-single-select__select.slim-select {
  &.ss-main {
    padding-inline: var(--padding-inline);

    &:focus {
      box-shadow: none;
    }
  }

  &.ss-values {
    &.ss-single {
      margin: 0;
    }
  }

  .ss-list .ss-option {
    padding-inline: 16px;
  }
}

.f-single-select,
.f-single-select__select.slim-select {
  --radius: 30px;
  --height: 48px;
  --padding-inline: 16px;
  --border-color: #{colors.$secondary-400};

  // Slimselect variables
  --ss-primary-color: #{colors.$primary};
  --ss-bg-color: #fff;
  --ss-font-color: #{colors.$black};
  --ss-font-placeholder-color: #{colors.$primary-900};
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;

  // Heights
  --ss-main-height: var(--height);
  --ss-content-height: 300px;

  // Spacing
  --ss-spacing-l: 7px;
  --ss-spacing-m: 5px;
  --ss-spacing-s: 3px;

  // Misc
  --ss-animation-timing: 0.2s;
  --ss-border-radius: var(--radius);

  &.--native {
    .f-single-select__wrap {
      display: grid;
      align-items: center;
      grid-template-areas: "select";

      &::after {
        display: block;
        content: "";
        width: 16px;
        height: 16px;
        background-image: url("../images/svg/chevron-down.svg");
        background-repeat: no-repeat;
        background-size: contain;
        justify-self: end;
        grid-area: select;
        margin-right: 16px;
        pointer-events: none;
      }
    }

    .f-single-select__select {
      border-radius: var(--radius);
      width: 100%;
      min-height: var(--height);
      border: 2px solid var(--border-color);
      background-color: colors.$white;
      padding-left: 16px;
      cursor: pointer;
      outline: none;
      margin: 0;
      appearance: none;
      grid-area: select;
    }
  }

  &.--invalid {
    --border-color: #{colors.$danger};

    .ss-main {
      --border-color: #{colors.$danger};
    }
  }
}
