@use "settings/s-colors" as colors;

.i-breadcrumb {
  text-wrap: nowrap;

  .i-breadcrumb__link {
    --font-size: 14px;
  }

  .i-breadcrumb__divider {
    margin-right: 16px;
  }

  &.--homepage {
    .i-breadcrumb__link {
      gap: 0;
    }

    .i-breadcrumb__divider {
      display: none;
    }
  }

  &.--current {
    font-size: 14px;
    color: colors.$grey-100;
  }
}
