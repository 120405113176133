@use "settings/s-colors" as colors;

.validator {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: 100%;
  opacity: 0;

  .erorr-s {
    display: block;
    white-space: nowrap;
    color: colors.$danger;
    font-size: 12px;
    line-height: 16px;
    z-index: 99;
  }

  &:first-of-type {
    opacity: 1;
  }
}
