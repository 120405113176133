@use "settings/s-colors" as colors;

.main-footer {
  & .main-footer__top .container {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: -40px;
      left: 26px;
      height: 1px;
      width: calc(100% - 52px);
      background-color: colors.$primary-800;
    }
  }
}

.main-footer__columns {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(12, 1fr);

    & .main-footer-column:first-child {
      grid-column: span 2;
    }

    & .main-footer-column:nth-of-type(2) {
      grid-column: span 2;
    }

    & .main-footer-column:nth-of-type(3) {
      grid-column: span 3;
      margin-left: 32px;
    }

    & .main-footer-column:last-child {
      grid-column: span 5;
      justify-self: flex-end;
    }
  }
}

.main-footer__bottom-columns {
  @include media-breakpoint-up(sm) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-breakpoint-up(xl) {
    grid-template-columns: repeat(12, 1fr);

    & .link {
      grid-column: span 2;
    }

    & .main-footer__content {
      grid-column: span 10;
    }
  }
}

.main-footer__bottom-edge {
  background-color: colors.$primary-400;
  height: 40px;
  width: 100%;
}
