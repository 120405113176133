@use "settings/s-colors" as colors;

$color-01: #00000029;
$color-02: #0007;

$color-grey: #e4e8ec;

$color-primary: #{colors.$primary-900};
$color-secondary: #f0e1b7;
$color-youtube: #e6cfcf;
$color-facebook: #cfd6e5;
$color-linkedin: #cfdde6;

$size-01: 0 8px 32px 2px;
$size-02: 0 13px 16px;

$shadow: #{$size-01 $color-01};
