$primary-100: #020509;
$primary-200: #06111d;
$primary-300: #0b1d30;
$primary-400: #0f2944;
$primary-500: #143558;
$primary-600: #48617d;
$primary-700: #7c8ea2;
$primary-800: #b0bbc7;
$primary-900: #e4e8ec;

$primary: #{$primary-500};
$primary-hover: #2d547e;

$secondary-100: #171305;
$secondary-200: #47390f;
$secondary-300: #775f19;
$secondary-400: #a78523;
$secondary-500: #d7ab2d;
$secondary-600: #dfbd5b;
$secondary-700: #e8d08a;
$secondary-800: #f1e3b9;
$secondary-900: #faf5e7;

$secondary: #{$secondary-500};

$white: #fff;

$grey: #cad2d9;
$grey-100: #7c8793;

$black: #081828;

$info-500: #05d686;
$danger: #e52661;
$success: #6fdb66;

$youtube: #ff0200;
$facebook: #4267b2;
$linkedin: #0072b1;
$instagram: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
  radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
  radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
