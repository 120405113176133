@use "settings/s-general.scss" as general;
@use "settings/s-colors.scss" as colors;

.i-media {
  --border-radius: 23px;

  border-radius: var(--border-radius);
  box-shadow: general.$shadow-02;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: transparent;
    transition: 0.3s ease;
    transition-property: background-color, mix-blend-mode;
    will-change: background-color, mix-blend-mode;
  }

  @media (hover: hover) {
    &:hover {
      &::before {
        content: "";
        background-color: colors.$secondary-600;
        mix-blend-mode: multiply;
        z-index: 1;
      }

      & .i-media__play-icon {
        opacity: 1;
      }
    }
  }

  & .img {
    border-radius: var(--border-radius);
  }

  & .i-media__time {
    bottom: 35px;
    right: 50px;
    flex-shrink: 0;
    padding: 8px 20px;
    background-color: colors.$primary-100;
    color: colors.$white;
    border-radius: var(--border-radius);
    opacity: 0.75;
    z-index: 2;

    @include media-breakpoint-down(lg) {
      bottom: 25px;
      right: 30px;
    }

    @include media-breakpoint-down(sm) {
      padding: 8px 15px;
      bottom: 12px;
      right: 8px;
    }
  }

  & .i-media__play-icon {
    --min-width: 149px;

    min-width: var(--min-width);
    height: var(--min-width);
    background-color: colors.$primary-200;
    border-radius: 50%;
    box-shadow: general.$shadow-03;
    z-index: 2;
    opacity: 0;
    transition: opacity 0.3s ease;

    & .icon {
      --size: 64px;

      margin-left: 8px;
    }

    @include media-breakpoint-down(lg) {
      --min-width: 119px;

      & .icon {
        --size: 54px;
      }
    }

    @include media-breakpoint-down(sm) {
      --min-width: 89px;

      & .icon {
        --size: 32px;

        margin-left: 4px;
      }
    }
  }

  &.--video {
    cursor: pointer;
  }
}
