@use "settings/s-colors.scss" as colors;

.s-consultant-video__header {
  --spacing: 69px;
}

.s-consultant-video__media {
  --width: 1280px;
  --max-height: 725px;

  width: min(100%, var(--width));
  max-height: var(--max-height);
  height: 100%;
  margin-inline: auto;

  & .img {
    width: min(100%, var(--width));
    max-height: var(--max-height);
    height: 100%;
  }
}
