@use "settings/s-colors.scss" as colors;

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6,
.u-h7 {
  font-size: var(--size);
  color: colors.$primary;
  line-height: var(--line-height);
}

.u-h1,
.entry-content h1 {
  --size: 84px;
  --line-height: 102px;

  @include media-breakpoint-down(md) {
    --size: 60px;
    --line-height: 84px;
  }
}

.u-h2,
.entry-content h2 {
  --size: 72px;
  --line-height: 88px;

  @include media-breakpoint-down(md) {
    --size: 56px;
    --line-height: 72px;
  }
}

.u-h3,
.entry-content h3 {
  --size: 60px;
  --line-height: 73px;

  @include media-breakpoint-down(md) {
    --size: 52px;
    --line-height: 60px;
  }
}

.u-h4,
.entry-content h4 {
  --size: 48px;
  --line-height: 58px;

  @include media-breakpoint-down(md) {
    --size: 40px;
    --line-height: 48px;
  }
}

.u-h5,
.entry-content h5 {
  --size: 36px;
  --line-height: 44px;

  @include media-breakpoint-down(md) {
    --size: 32px;
    --line-height: 36px;
  }
}

.u-h6,
.entry-content h6 {
  --size: 30px;
  --line-height: 41px;

  @include media-breakpoint-down(md) {
    --size: 28px;
    --line-height: 37px;
  }
}

.u-h7 {
  --size: 24px;
  --line-height: 32px;

  @include media-breakpoint-down(md) {
    --size: 18px;
    --line-height: 24px;
  }
}
