@use "settings/s-colors" as colors;
@use "settings/s-fonts.scss" as fonts;

.s-consultant-meet__img {
  &.--contain {
    width: 570px;
    aspect-ratio: 1 / 0.982;
  }

  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.s-consultant-meet__header {
  & .base-header__description {
    --font-family: #{fonts.$primary};
    --font-size: 24px;
    --font-weight: 400;
    --font-style: normal;
    --color: #{colors.$primary};

    font-size: 24px;
    line-height: 37px;
    display: block;
    margin-top: 8px;
    max-width: 594px;
    width: 100%;
  }
}

.s-consultant-meet__text-part {
  width: min(100%, 630px);
  margin-left: -16px;

  @include media-breakpoint-down(lg) {
    margin: 0;
  }
}

.s-consultant-meet__button {
  --font-size: 24px;
  --border-radius: 41px;

  width: min(100%, 408px);
  gap: 20px;

  @include media-breakpoint-down(lg) {
    --font-size: 16px;

    .icon.--lg {
      --size: 20px;
    }
  }
}
