@use "settings/s-colors.scss" as colors;

.link {
  &.--black {
    color: colors.$black;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$black;
      text-decoration-color: colors.$black;
    }
  }

  &.--secondary {
    color: colors.$secondary-400;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$secondary-400;
      text-decoration-color: colors.$secondary-400;
    }
  }

  &.--primary-400 {
    color: colors.$primary-400;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$primary-400;
      text-decoration-color: transparent;
    }
  }

  &.--grey {
    color: colors.$grey-100;
    text-decoration-color: transparent;

    &:hover,
    &:focus {
      color: colors.$grey-100;
      text-decoration-color: transparent;
    }
  }
}
