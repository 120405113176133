@use "settings/s-colors" as colors;

.g-reviews-slider__button {
  --offset: 50%;

  pointer-events: auto;

  &.--prev {
    transform: translateX(calc(-1 * var(--offset)));
  }

  &.--next {
    transform: translateX(var(--offset));
  }

  @include media-breakpoint-down(md) {
    --offset: calc(50% + 4px);
  }

  @include media-breakpoint-down(sm) {
    --offset: calc(50% - 4px);

    &.--icon-only {
      --min-height: 38px;
    }
  }
}

.g-reviews-slider {
  &.swiper {
    min-height: 730px;
    display: flex;
    align-items: center;
    overflow: initial;
  }

  & .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    flex-shrink: 0;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
  }
}

.g-reviews-slider__buttons {
  pointer-events: none;

  .swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }
}
