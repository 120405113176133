.i-usp {
  &.--inline {
    @include media-breakpoint-up(sm) {
      text-align: left;
      flex-direction: row;
      gap: 24px;
      align-items: flex-start;

      .i-usp__body {
        align-items: flex-start;
      }
    }
  }
}
