.i-testimonial__image {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 72px;
    max-width: 250px;
    object-fit: contain;
  }
}
