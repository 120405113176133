@use "settings/s-colors" as colors;

.f-newsletter {
  background-color: colors.$primary-900;
  border-radius: 16px;
}

.f-newsletter__grid {
  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-newsletter__header {
  gap: 4px;
}

.f-newsletter__inputs-col {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.f-newsletter__submit {
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
