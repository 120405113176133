.s-videos__button {
  margin-top: 64px;

  &.--hidden {
    display: none;
  }
}

.s-videos__header {
  --spacing: 68px;
}
