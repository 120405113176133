.i-media {
  &.--size-sm {
    width: min(100%, 155px);
    height: 155px;
    flex-shrink: 0;

    .i-media__play-icon {
      display: none;
    }

    .i-media__time {
      right: 8px;
      bottom: 12px;
    }

    @media (width <= 469px) {
      max-width: 100%;
      width: 100%;
      height: auto;
    }
  }
}
