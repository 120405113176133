@use "settings/s-colors.scss" as colors;
@use "settings/s-shadows" as shadows;

.i-usp {
  max-width: 314px;
  gap: 43px;
  padding: 24px;
  border-radius: 20px;
}

.i-usp__icon {
  & .icon {
    --size: 80px;
  }
}

.i-usp__description {
  max-width: 266px;
}
