@use "settings/s-colors" as colors;
@use "settings/s-fonts.scss" as fonts;

.base-header {
  --spacing: 32px;

  margin-inline: auto;
  max-width: 928px;
  width: 100%;
  margin-bottom: var(--spacing);

  &.--left {
    align-items: flex-start;
    text-align: left;
    margin-inline: 0;
  }

  &.--spacing-none {
    --spacing: 0;
  }
}

.base-header__description {
  --font-family: #{fonts.$secondary};
  --font-style: italic;
  --font-weight: 200;
  --color: #{colors.$primary-700};

  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  color: var(--color);

  p {
    margin-bottom: 0;
  }
}
