*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 8px 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, h5, h4, h3, h2, h1 {
  margin-top: 0;
  margin-bottom: 4px;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem;
  }
}

h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 2rem;
  }
}

h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.5rem;
  }
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 0.875em;
}

mark {
  padding: 0.1875em;
  color: var(--bs-highlight-color);
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.container,
.container-fluid,
.container-xxxl,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1300px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1260px;
  }
}
@media (min-width: 1500px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1460px;
  }
}
@media (min-width: 1740px) {
  .container-xxxl, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1692px;
  }
}
:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 992px;
  --bs-breakpoint-xl: 1300px;
  --bs-breakpoint-xxl: 1500px;
  --bs-breakpoint-xxxl: 1740px;
}

.row {
  --bs-gutter-x: 32px;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 8px;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 8px;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 16px;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 16px;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 24px;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 24px;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 32px;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 32px;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 40px;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 40px;
}

.g-6,
.gx-6 {
  --bs-gutter-x: 48px;
}

.g-6,
.gy-6 {
  --bs-gutter-y: 48px;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 8px;
  }
  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 8px;
  }
  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 16px;
  }
  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 16px;
  }
  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 24px;
  }
  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 24px;
  }
  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 32px;
  }
  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 32px;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 40px;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 40px;
  }
  .g-sm-6,
  .gx-sm-6 {
    --bs-gutter-x: 48px;
  }
  .g-sm-6,
  .gy-sm-6 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 8px;
  }
  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 8px;
  }
  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 16px;
  }
  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 16px;
  }
  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 24px;
  }
  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 24px;
  }
  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 32px;
  }
  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 32px;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 40px;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 40px;
  }
  .g-md-6,
  .gx-md-6 {
    --bs-gutter-x: 48px;
  }
  .g-md-6,
  .gy-md-6 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 8px;
  }
  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 8px;
  }
  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 16px;
  }
  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 16px;
  }
  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 24px;
  }
  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 24px;
  }
  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 32px;
  }
  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 32px;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 40px;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 40px;
  }
  .g-lg-6,
  .gx-lg-6 {
    --bs-gutter-x: 48px;
  }
  .g-lg-6,
  .gy-lg-6 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 1300px) {
  .col-xl {
    flex: 1 0 0%;
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .g-xl-0,
  .gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .g-xl-0,
  .gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .g-xl-1,
  .gx-xl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xl-1,
  .gy-xl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xl-2,
  .gx-xl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xl-2,
  .gy-xl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xl-3,
  .gx-xl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xl-3,
  .gy-xl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xl-4,
  .gx-xl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xl-4,
  .gy-xl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xl-5,
  .gx-xl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xl-5,
  .gy-xl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xl-6,
  .gx-xl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xl-6,
  .gy-xl-6 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 1500px) {
  .col-xxl {
    flex: 1 0 0%;
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxl-0,
  .gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxl-0,
  .gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxl-1,
  .gx-xxl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xxl-1,
  .gy-xxl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xxl-2,
  .gx-xxl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xxl-2,
  .gy-xxl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xxl-3,
  .gx-xxl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xxl-3,
  .gy-xxl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xxl-4,
  .gx-xxl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xxl-4,
  .gy-xxl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xxl-5,
  .gx-xxl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xxl-5,
  .gy-xxl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xxl-6,
  .gx-xxl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xxl-6,
  .gy-xxl-6 {
    --bs-gutter-y: 48px;
  }
}
@media (min-width: 1740px) {
  .col-xxxl {
    flex: 1 0 0%;
  }
  .row-cols-xxxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-xxxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-xxxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-xxxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .row-cols-xxxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-xxxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-xxxl-6 > * {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-xxxl-0 {
    margin-left: 0;
  }
  .offset-xxxl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xxxl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xxxl-3 {
    margin-left: 25%;
  }
  .offset-xxxl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xxxl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xxxl-6 {
    margin-left: 50%;
  }
  .offset-xxxl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xxxl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xxxl-9 {
    margin-left: 75%;
  }
  .offset-xxxl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xxxl-11 {
    margin-left: 91.66666667%;
  }
  .g-xxxl-0,
  .gx-xxxl-0 {
    --bs-gutter-x: 0;
  }
  .g-xxxl-0,
  .gy-xxxl-0 {
    --bs-gutter-y: 0;
  }
  .g-xxxl-1,
  .gx-xxxl-1 {
    --bs-gutter-x: 8px;
  }
  .g-xxxl-1,
  .gy-xxxl-1 {
    --bs-gutter-y: 8px;
  }
  .g-xxxl-2,
  .gx-xxxl-2 {
    --bs-gutter-x: 16px;
  }
  .g-xxxl-2,
  .gy-xxxl-2 {
    --bs-gutter-y: 16px;
  }
  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 24px;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 24px;
  }
  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 32px;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 32px;
  }
  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 40px;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 40px;
  }
  .g-xxxl-6,
  .gx-xxxl-6 {
    --bs-gutter-x: 48px;
  }
  .g-xxxl-6,
  .gy-xxxl-6 {
    --bs-gutter-y: 48px;
  }
}
.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.align-text-bottom {
  vertical-align: text-bottom;
}

.align-text-top {
  vertical-align: text-top;
}

.float-start {
  float: left;
}

.float-end {
  float: right;
}

.float-none {
  float: none;
}

.object-fit-contain {
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-fill {
  object-fit: fill;
}

.object-fit-scale {
  object-fit: scale-down;
}

.object-fit-none {
  object-fit: none;
}

.opacity-0 {
  opacity: 0;
}

.opacity-25 {
  opacity: 0.25;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-100 {
  opacity: 1;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-x-visible {
  overflow-x: visible;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.overflow-y-visible {
  overflow-y: visible;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.d-inline {
  display: inline;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-grid {
  display: grid;
}

.d-inline-grid {
  display: inline-grid;
}

.d-table {
  display: table;
}

.d-table-row {
  display: table-row;
}

.d-table-cell {
  display: table-cell;
}

.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.d-none {
  display: none;
}

.shadow {
  box-shadow: var(--bs-box-shadow);
}

.shadow-sm {
  box-shadow: var(--bs-box-shadow-sm);
}

.shadow-lg {
  box-shadow: var(--bs-box-shadow-lg);
}

.shadow-none {
  box-shadow: none;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-info {
  --bs-focus-ring-color: rgba(var(--bs-info-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-warning {
  --bs-focus-ring-color: rgba(var(--bs-warning-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-fixed {
  position: fixed;
}

.position-sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.top-100 {
  top: 100%;
}

.bottom-0 {
  bottom: 0;
}

.bottom-50 {
  bottom: 50%;
}

.bottom-100 {
  bottom: 100%;
}

.start-0 {
  left: 0;
}

.start-50 {
  left: 50%;
}

.start-100 {
  left: 100%;
}

.end-0 {
  right: 0;
}

.end-50 {
  right: 50%;
}

.end-100 {
  right: 100%;
}

.translate-middle {
  transform: translate(-50%, -50%);
}

.translate-middle-x {
  transform: translateX(-50%);
}

.translate-middle-y {
  transform: translateY(-50%);
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-0 {
  border: 0;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-top-0 {
  border-top: 0;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-end-0 {
  border-right: 0;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-bottom-0 {
  border-bottom: 0;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
}

.border-start-0 {
  border-left: 0;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity));
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity));
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity));
}

.border-info {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-info-rgb), var(--bs-border-opacity));
}

.border-warning {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-warning-rgb), var(--bs-border-opacity));
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity));
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity));
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity));
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity));
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity));
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle);
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle);
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle);
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle);
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle);
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle);
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle);
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle);
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mw-100 {
  max-width: 100%;
}

.vw-100 {
  width: 100vw;
}

.min-vw-100 {
  min-width: 100vw;
}

.h-25 {
  height: 25%;
}

.h-50 {
  height: 50%;
}

.h-75 {
  height: 75%;
}

.h-100 {
  height: 100%;
}

.h-auto {
  height: auto;
}

.mh-100 {
  max-height: 100%;
}

.vh-100 {
  height: 100vh;
}

.min-vh-100 {
  min-height: 100vh;
}

.flex-fill {
  flex: 1 1 auto;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-column-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.order-first {
  order: -1;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-last {
  order: 6;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 8px;
}

.m-2 {
  margin: 16px;
}

.m-3 {
  margin: 24px;
}

.m-4 {
  margin: 32px;
}

.m-5 {
  margin: 40px;
}

.m-6 {
  margin: 48px;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-right: 0;
  margin-left: 0;
}

.mx-1 {
  margin-right: 8px;
  margin-left: 8px;
}

.mx-2 {
  margin-right: 16px;
  margin-left: 16px;
}

.mx-3 {
  margin-right: 24px;
  margin-left: 24px;
}

.mx-4 {
  margin-right: 32px;
  margin-left: 32px;
}

.mx-5 {
  margin-right: 40px;
  margin-left: 40px;
}

.mx-6 {
  margin-right: 48px;
  margin-left: 48px;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.my-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.my-5 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.my-6 {
  margin-top: 48px;
  margin-bottom: 48px;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: 8px;
}

.mt-2 {
  margin-top: 16px;
}

.mt-3 {
  margin-top: 24px;
}

.mt-4 {
  margin-top: 32px;
}

.mt-5 {
  margin-top: 40px;
}

.mt-6 {
  margin-top: 48px;
}

.mt-auto {
  margin-top: auto;
}

.me-0 {
  margin-right: 0;
}

.me-1 {
  margin-right: 8px;
}

.me-2 {
  margin-right: 16px;
}

.me-3 {
  margin-right: 24px;
}

.me-4 {
  margin-right: 32px;
}

.me-5 {
  margin-right: 40px;
}

.me-6 {
  margin-right: 48px;
}

.me-auto {
  margin-right: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 8px;
}

.mb-2 {
  margin-bottom: 16px;
}

.mb-3 {
  margin-bottom: 24px;
}

.mb-4 {
  margin-bottom: 32px;
}

.mb-5 {
  margin-bottom: 40px;
}

.mb-6 {
  margin-bottom: 48px;
}

.mb-auto {
  margin-bottom: auto;
}

.ms-0 {
  margin-left: 0;
}

.ms-1 {
  margin-left: 8px;
}

.ms-2 {
  margin-left: 16px;
}

.ms-3 {
  margin-left: 24px;
}

.ms-4 {
  margin-left: 32px;
}

.ms-5 {
  margin-left: 40px;
}

.ms-6 {
  margin-left: 48px;
}

.ms-auto {
  margin-left: auto;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: 8px;
}

.p-2 {
  padding: 16px;
}

.p-3 {
  padding: 24px;
}

.p-4 {
  padding: 32px;
}

.p-5 {
  padding: 40px;
}

.p-6 {
  padding: 48px;
}

.px-0 {
  padding-right: 0;
  padding-left: 0;
}

.px-1 {
  padding-right: 8px;
  padding-left: 8px;
}

.px-2 {
  padding-right: 16px;
  padding-left: 16px;
}

.px-3 {
  padding-right: 24px;
  padding-left: 24px;
}

.px-4 {
  padding-right: 32px;
  padding-left: 32px;
}

.px-5 {
  padding-right: 40px;
  padding-left: 40px;
}

.px-6 {
  padding-right: 48px;
  padding-left: 48px;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}

.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}

.py-5 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.py-6 {
  padding-top: 48px;
  padding-bottom: 48px;
}

.pt-0 {
  padding-top: 0;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pt-5 {
  padding-top: 40px;
}

.pt-6 {
  padding-top: 48px;
}

.pe-0 {
  padding-right: 0;
}

.pe-1 {
  padding-right: 8px;
}

.pe-2 {
  padding-right: 16px;
}

.pe-3 {
  padding-right: 24px;
}

.pe-4 {
  padding-right: 32px;
}

.pe-5 {
  padding-right: 40px;
}

.pe-6 {
  padding-right: 48px;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: 8px;
}

.pb-2 {
  padding-bottom: 16px;
}

.pb-3 {
  padding-bottom: 24px;
}

.pb-4 {
  padding-bottom: 32px;
}

.pb-5 {
  padding-bottom: 40px;
}

.pb-6 {
  padding-bottom: 48px;
}

.ps-0 {
  padding-left: 0;
}

.ps-1 {
  padding-left: 8px;
}

.ps-2 {
  padding-left: 16px;
}

.ps-3 {
  padding-left: 24px;
}

.ps-4 {
  padding-left: 32px;
}

.ps-5 {
  padding-left: 40px;
}

.ps-6 {
  padding-left: 48px;
}

.gap-0 {
  gap: 0;
}

.gap-1 {
  gap: 8px;
}

.gap-2 {
  gap: 16px;
}

.gap-3 {
  gap: 24px;
}

.gap-4 {
  gap: 32px;
}

.gap-5 {
  gap: 40px;
}

.gap-6 {
  gap: 48px;
}

.row-gap-0 {
  row-gap: 0;
}

.row-gap-1 {
  row-gap: 8px;
}

.row-gap-2 {
  row-gap: 16px;
}

.row-gap-3 {
  row-gap: 24px;
}

.row-gap-4 {
  row-gap: 32px;
}

.row-gap-5 {
  row-gap: 40px;
}

.row-gap-6 {
  row-gap: 48px;
}

.column-gap-0 {
  column-gap: 0;
}

.column-gap-1 {
  column-gap: 8px;
}

.column-gap-2 {
  column-gap: 16px;
}

.column-gap-3 {
  column-gap: 24px;
}

.column-gap-4 {
  column-gap: 32px;
}

.column-gap-5 {
  column-gap: 40px;
}

.column-gap-6 {
  column-gap: 48px;
}

.font-monospace {
  font-family: var(--bs-font-monospace);
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw);
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw);
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw);
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw);
}

.fs-5 {
  font-size: 1.25rem;
}

.fs-6 {
  font-size: 1rem;
}

.fst-italic {
  font-style: italic;
}

.fst-normal {
  font-style: normal;
}

.fw-lighter {
  font-weight: lighter;
}

.fw-light {
  font-weight: 300;
}

.fw-normal {
  font-weight: 400;
}

.fw-medium {
  font-weight: 500;
}

.fw-semibold {
  font-weight: 600;
}

.fw-bold {
  font-weight: 700;
}

.fw-bolder {
  font-weight: bolder;
}

.lh-1 {
  line-height: 1;
}

.lh-sm {
  line-height: 1.25;
}

.lh-base {
  line-height: 1.5;
}

.lh-lg {
  line-height: 2;
}

.text-start {
  text-align: left;
}

.text-end {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-decoration-none {
  text-decoration: none;
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-line-through {
  text-decoration: line-through;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-wrap {
  white-space: normal;
}

.text-nowrap {
  white-space: nowrap;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word;
  word-break: break-word;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity));
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity));
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity));
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity));
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity));
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity));
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity));
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity));
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity));
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity));
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5);
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5);
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color);
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color);
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color);
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis);
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis);
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis);
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis);
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis);
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis);
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis);
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis);
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em;
}

.link-offset-2 {
  text-underline-offset: 0.25em;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em;
}

.link-offset-3 {
  text-underline-offset: 0.375em;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity));
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity));
}

.link-underline-info {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-info-rgb), var(--bs-link-underline-opacity));
}

.link-underline-warning {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-warning-rgb), var(--bs-link-underline-opacity));
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity));
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity));
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity));
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1));
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity));
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity));
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity));
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity));
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity));
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity));
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity));
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity));
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity));
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity));
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity));
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity));
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle);
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle);
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle);
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle);
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle);
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle);
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle);
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle);
}

.bg-gradient {
  background-image: var(--bs-gradient);
}

.user-select-all {
  user-select: all;
}

.user-select-auto {
  user-select: auto;
}

.user-select-none {
  user-select: none;
}

.pe-none {
  pointer-events: none;
}

.pe-auto {
  pointer-events: auto;
}

.rounded {
  border-radius: var(--bs-border-radius);
}

.rounded-0 {
  border-radius: 0;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm);
}

.rounded-2 {
  border-radius: var(--bs-border-radius);
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg);
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl);
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl);
}

.rounded-circle {
  border-radius: 50%;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill);
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-0 {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm);
  border-top-right-radius: var(--bs-border-radius-sm);
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius);
  border-top-right-radius: var(--bs-border-radius);
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg);
  border-top-right-radius: var(--bs-border-radius-lg);
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl);
  border-top-right-radius: var(--bs-border-radius-xl);
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl);
  border-top-right-radius: var(--bs-border-radius-xxl);
}

.rounded-top-circle {
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill);
  border-top-right-radius: var(--bs-border-radius-pill);
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm);
  border-bottom-right-radius: var(--bs-border-radius-sm);
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius);
  border-bottom-right-radius: var(--bs-border-radius);
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg);
  border-bottom-right-radius: var(--bs-border-radius-lg);
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl);
  border-bottom-right-radius: var(--bs-border-radius-xl);
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl);
  border-bottom-right-radius: var(--bs-border-radius-xxl);
}

.rounded-end-circle {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill);
  border-bottom-right-radius: var(--bs-border-radius-pill);
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm);
  border-bottom-left-radius: var(--bs-border-radius-sm);
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius);
  border-bottom-left-radius: var(--bs-border-radius);
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg);
  border-bottom-left-radius: var(--bs-border-radius-lg);
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl);
  border-bottom-left-radius: var(--bs-border-radius-xl);
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl);
  border-bottom-left-radius: var(--bs-border-radius-xxl);
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill);
  border-bottom-left-radius: var(--bs-border-radius-pill);
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-0 {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm);
  border-top-left-radius: var(--bs-border-radius-sm);
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius);
  border-top-left-radius: var(--bs-border-radius);
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg);
  border-top-left-radius: var(--bs-border-radius-lg);
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl);
  border-top-left-radius: var(--bs-border-radius-xl);
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl);
  border-top-left-radius: var(--bs-border-radius-xxl);
}

.rounded-start-circle {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill);
  border-top-left-radius: var(--bs-border-radius-pill);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.z-n1 {
  z-index: -1;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left;
  }
  .float-sm-end {
    float: right;
  }
  .float-sm-none {
    float: none;
  }
  .object-fit-sm-contain {
    object-fit: contain;
  }
  .object-fit-sm-cover {
    object-fit: cover;
  }
  .object-fit-sm-fill {
    object-fit: fill;
  }
  .object-fit-sm-scale {
    object-fit: scale-down;
  }
  .object-fit-sm-none {
    object-fit: none;
  }
  .d-sm-inline {
    display: inline;
  }
  .d-sm-inline-block {
    display: inline-block;
  }
  .d-sm-block {
    display: block;
  }
  .d-sm-grid {
    display: grid;
  }
  .d-sm-inline-grid {
    display: inline-grid;
  }
  .d-sm-table {
    display: table;
  }
  .d-sm-table-row {
    display: table-row;
  }
  .d-sm-table-cell {
    display: table-cell;
  }
  .d-sm-flex {
    display: flex;
  }
  .d-sm-inline-flex {
    display: inline-flex;
  }
  .d-sm-none {
    display: none;
  }
  .flex-sm-fill {
    flex: 1 1 auto;
  }
  .flex-sm-row {
    flex-direction: row;
  }
  .flex-sm-column {
    flex-direction: column;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-sm-grow-0 {
    flex-grow: 0;
  }
  .flex-sm-grow-1 {
    flex-grow: 1;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1;
  }
  .flex-sm-wrap {
    flex-wrap: wrap;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-sm-start {
    justify-content: flex-start;
  }
  .justify-content-sm-end {
    justify-content: flex-end;
  }
  .justify-content-sm-center {
    justify-content: center;
  }
  .justify-content-sm-between {
    justify-content: space-between;
  }
  .justify-content-sm-around {
    justify-content: space-around;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly;
  }
  .align-items-sm-start {
    align-items: flex-start;
  }
  .align-items-sm-end {
    align-items: flex-end;
  }
  .align-items-sm-center {
    align-items: center;
  }
  .align-items-sm-baseline {
    align-items: baseline;
  }
  .align-items-sm-stretch {
    align-items: stretch;
  }
  .align-content-sm-start {
    align-content: flex-start;
  }
  .align-content-sm-end {
    align-content: flex-end;
  }
  .align-content-sm-center {
    align-content: center;
  }
  .align-content-sm-between {
    align-content: space-between;
  }
  .align-content-sm-around {
    align-content: space-around;
  }
  .align-content-sm-stretch {
    align-content: stretch;
  }
  .align-self-sm-auto {
    align-self: auto;
  }
  .align-self-sm-start {
    align-self: flex-start;
  }
  .align-self-sm-end {
    align-self: flex-end;
  }
  .align-self-sm-center {
    align-self: center;
  }
  .align-self-sm-baseline {
    align-self: baseline;
  }
  .align-self-sm-stretch {
    align-self: stretch;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-last {
    order: 6;
  }
  .m-sm-0 {
    margin: 0;
  }
  .m-sm-1 {
    margin: 8px;
  }
  .m-sm-2 {
    margin: 16px;
  }
  .m-sm-3 {
    margin: 24px;
  }
  .m-sm-4 {
    margin: 32px;
  }
  .m-sm-5 {
    margin: 40px;
  }
  .m-sm-6 {
    margin: 48px;
  }
  .m-sm-auto {
    margin: auto;
  }
  .mx-sm-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-sm-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-sm-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-sm-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-sm-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-sm-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-sm-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-sm-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-sm-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-sm-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-sm-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-sm-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-sm-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-sm-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-sm-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-sm-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-sm-0 {
    margin-top: 0;
  }
  .mt-sm-1 {
    margin-top: 8px;
  }
  .mt-sm-2 {
    margin-top: 16px;
  }
  .mt-sm-3 {
    margin-top: 24px;
  }
  .mt-sm-4 {
    margin-top: 32px;
  }
  .mt-sm-5 {
    margin-top: 40px;
  }
  .mt-sm-6 {
    margin-top: 48px;
  }
  .mt-sm-auto {
    margin-top: auto;
  }
  .me-sm-0 {
    margin-right: 0;
  }
  .me-sm-1 {
    margin-right: 8px;
  }
  .me-sm-2 {
    margin-right: 16px;
  }
  .me-sm-3 {
    margin-right: 24px;
  }
  .me-sm-4 {
    margin-right: 32px;
  }
  .me-sm-5 {
    margin-right: 40px;
  }
  .me-sm-6 {
    margin-right: 48px;
  }
  .me-sm-auto {
    margin-right: auto;
  }
  .mb-sm-0 {
    margin-bottom: 0;
  }
  .mb-sm-1 {
    margin-bottom: 8px;
  }
  .mb-sm-2 {
    margin-bottom: 16px;
  }
  .mb-sm-3 {
    margin-bottom: 24px;
  }
  .mb-sm-4 {
    margin-bottom: 32px;
  }
  .mb-sm-5 {
    margin-bottom: 40px;
  }
  .mb-sm-6 {
    margin-bottom: 48px;
  }
  .mb-sm-auto {
    margin-bottom: auto;
  }
  .ms-sm-0 {
    margin-left: 0;
  }
  .ms-sm-1 {
    margin-left: 8px;
  }
  .ms-sm-2 {
    margin-left: 16px;
  }
  .ms-sm-3 {
    margin-left: 24px;
  }
  .ms-sm-4 {
    margin-left: 32px;
  }
  .ms-sm-5 {
    margin-left: 40px;
  }
  .ms-sm-6 {
    margin-left: 48px;
  }
  .ms-sm-auto {
    margin-left: auto;
  }
  .p-sm-0 {
    padding: 0;
  }
  .p-sm-1 {
    padding: 8px;
  }
  .p-sm-2 {
    padding: 16px;
  }
  .p-sm-3 {
    padding: 24px;
  }
  .p-sm-4 {
    padding: 32px;
  }
  .p-sm-5 {
    padding: 40px;
  }
  .p-sm-6 {
    padding: 48px;
  }
  .px-sm-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-sm-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-sm-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-sm-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-sm-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-sm-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-sm-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .py-sm-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-sm-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-sm-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-sm-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-sm-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-sm-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-sm-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pt-sm-0 {
    padding-top: 0;
  }
  .pt-sm-1 {
    padding-top: 8px;
  }
  .pt-sm-2 {
    padding-top: 16px;
  }
  .pt-sm-3 {
    padding-top: 24px;
  }
  .pt-sm-4 {
    padding-top: 32px;
  }
  .pt-sm-5 {
    padding-top: 40px;
  }
  .pt-sm-6 {
    padding-top: 48px;
  }
  .pe-sm-0 {
    padding-right: 0;
  }
  .pe-sm-1 {
    padding-right: 8px;
  }
  .pe-sm-2 {
    padding-right: 16px;
  }
  .pe-sm-3 {
    padding-right: 24px;
  }
  .pe-sm-4 {
    padding-right: 32px;
  }
  .pe-sm-5 {
    padding-right: 40px;
  }
  .pe-sm-6 {
    padding-right: 48px;
  }
  .pb-sm-0 {
    padding-bottom: 0;
  }
  .pb-sm-1 {
    padding-bottom: 8px;
  }
  .pb-sm-2 {
    padding-bottom: 16px;
  }
  .pb-sm-3 {
    padding-bottom: 24px;
  }
  .pb-sm-4 {
    padding-bottom: 32px;
  }
  .pb-sm-5 {
    padding-bottom: 40px;
  }
  .pb-sm-6 {
    padding-bottom: 48px;
  }
  .ps-sm-0 {
    padding-left: 0;
  }
  .ps-sm-1 {
    padding-left: 8px;
  }
  .ps-sm-2 {
    padding-left: 16px;
  }
  .ps-sm-3 {
    padding-left: 24px;
  }
  .ps-sm-4 {
    padding-left: 32px;
  }
  .ps-sm-5 {
    padding-left: 40px;
  }
  .ps-sm-6 {
    padding-left: 48px;
  }
  .gap-sm-0 {
    gap: 0;
  }
  .gap-sm-1 {
    gap: 8px;
  }
  .gap-sm-2 {
    gap: 16px;
  }
  .gap-sm-3 {
    gap: 24px;
  }
  .gap-sm-4 {
    gap: 32px;
  }
  .gap-sm-5 {
    gap: 40px;
  }
  .gap-sm-6 {
    gap: 48px;
  }
  .row-gap-sm-0 {
    row-gap: 0;
  }
  .row-gap-sm-1 {
    row-gap: 8px;
  }
  .row-gap-sm-2 {
    row-gap: 16px;
  }
  .row-gap-sm-3 {
    row-gap: 24px;
  }
  .row-gap-sm-4 {
    row-gap: 32px;
  }
  .row-gap-sm-5 {
    row-gap: 40px;
  }
  .row-gap-sm-6 {
    row-gap: 48px;
  }
  .column-gap-sm-0 {
    column-gap: 0;
  }
  .column-gap-sm-1 {
    column-gap: 8px;
  }
  .column-gap-sm-2 {
    column-gap: 16px;
  }
  .column-gap-sm-3 {
    column-gap: 24px;
  }
  .column-gap-sm-4 {
    column-gap: 32px;
  }
  .column-gap-sm-5 {
    column-gap: 40px;
  }
  .column-gap-sm-6 {
    column-gap: 48px;
  }
  .text-sm-start {
    text-align: left;
  }
  .text-sm-end {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left;
  }
  .float-md-end {
    float: right;
  }
  .float-md-none {
    float: none;
  }
  .object-fit-md-contain {
    object-fit: contain;
  }
  .object-fit-md-cover {
    object-fit: cover;
  }
  .object-fit-md-fill {
    object-fit: fill;
  }
  .object-fit-md-scale {
    object-fit: scale-down;
  }
  .object-fit-md-none {
    object-fit: none;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-inline-block {
    display: inline-block;
  }
  .d-md-block {
    display: block;
  }
  .d-md-grid {
    display: grid;
  }
  .d-md-inline-grid {
    display: inline-grid;
  }
  .d-md-table {
    display: table;
  }
  .d-md-table-row {
    display: table-row;
  }
  .d-md-table-cell {
    display: table-cell;
  }
  .d-md-flex {
    display: flex;
  }
  .d-md-inline-flex {
    display: inline-flex;
  }
  .d-md-none {
    display: none;
  }
  .flex-md-fill {
    flex: 1 1 auto;
  }
  .flex-md-row {
    flex-direction: row;
  }
  .flex-md-column {
    flex-direction: column;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-md-grow-0 {
    flex-grow: 0;
  }
  .flex-md-grow-1 {
    flex-grow: 1;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1;
  }
  .flex-md-wrap {
    flex-wrap: wrap;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-md-start {
    justify-content: flex-start;
  }
  .justify-content-md-end {
    justify-content: flex-end;
  }
  .justify-content-md-center {
    justify-content: center;
  }
  .justify-content-md-between {
    justify-content: space-between;
  }
  .justify-content-md-around {
    justify-content: space-around;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly;
  }
  .align-items-md-start {
    align-items: flex-start;
  }
  .align-items-md-end {
    align-items: flex-end;
  }
  .align-items-md-center {
    align-items: center;
  }
  .align-items-md-baseline {
    align-items: baseline;
  }
  .align-items-md-stretch {
    align-items: stretch;
  }
  .align-content-md-start {
    align-content: flex-start;
  }
  .align-content-md-end {
    align-content: flex-end;
  }
  .align-content-md-center {
    align-content: center;
  }
  .align-content-md-between {
    align-content: space-between;
  }
  .align-content-md-around {
    align-content: space-around;
  }
  .align-content-md-stretch {
    align-content: stretch;
  }
  .align-self-md-auto {
    align-self: auto;
  }
  .align-self-md-start {
    align-self: flex-start;
  }
  .align-self-md-end {
    align-self: flex-end;
  }
  .align-self-md-center {
    align-self: center;
  }
  .align-self-md-baseline {
    align-self: baseline;
  }
  .align-self-md-stretch {
    align-self: stretch;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-last {
    order: 6;
  }
  .m-md-0 {
    margin: 0;
  }
  .m-md-1 {
    margin: 8px;
  }
  .m-md-2 {
    margin: 16px;
  }
  .m-md-3 {
    margin: 24px;
  }
  .m-md-4 {
    margin: 32px;
  }
  .m-md-5 {
    margin: 40px;
  }
  .m-md-6 {
    margin: 48px;
  }
  .m-md-auto {
    margin: auto;
  }
  .mx-md-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-md-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-md-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-md-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-md-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-md-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-md-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-md-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-md-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-md-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-md-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-md-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-md-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-md-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-md-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-md-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-md-0 {
    margin-top: 0;
  }
  .mt-md-1 {
    margin-top: 8px;
  }
  .mt-md-2 {
    margin-top: 16px;
  }
  .mt-md-3 {
    margin-top: 24px;
  }
  .mt-md-4 {
    margin-top: 32px;
  }
  .mt-md-5 {
    margin-top: 40px;
  }
  .mt-md-6 {
    margin-top: 48px;
  }
  .mt-md-auto {
    margin-top: auto;
  }
  .me-md-0 {
    margin-right: 0;
  }
  .me-md-1 {
    margin-right: 8px;
  }
  .me-md-2 {
    margin-right: 16px;
  }
  .me-md-3 {
    margin-right: 24px;
  }
  .me-md-4 {
    margin-right: 32px;
  }
  .me-md-5 {
    margin-right: 40px;
  }
  .me-md-6 {
    margin-right: 48px;
  }
  .me-md-auto {
    margin-right: auto;
  }
  .mb-md-0 {
    margin-bottom: 0;
  }
  .mb-md-1 {
    margin-bottom: 8px;
  }
  .mb-md-2 {
    margin-bottom: 16px;
  }
  .mb-md-3 {
    margin-bottom: 24px;
  }
  .mb-md-4 {
    margin-bottom: 32px;
  }
  .mb-md-5 {
    margin-bottom: 40px;
  }
  .mb-md-6 {
    margin-bottom: 48px;
  }
  .mb-md-auto {
    margin-bottom: auto;
  }
  .ms-md-0 {
    margin-left: 0;
  }
  .ms-md-1 {
    margin-left: 8px;
  }
  .ms-md-2 {
    margin-left: 16px;
  }
  .ms-md-3 {
    margin-left: 24px;
  }
  .ms-md-4 {
    margin-left: 32px;
  }
  .ms-md-5 {
    margin-left: 40px;
  }
  .ms-md-6 {
    margin-left: 48px;
  }
  .ms-md-auto {
    margin-left: auto;
  }
  .p-md-0 {
    padding: 0;
  }
  .p-md-1 {
    padding: 8px;
  }
  .p-md-2 {
    padding: 16px;
  }
  .p-md-3 {
    padding: 24px;
  }
  .p-md-4 {
    padding: 32px;
  }
  .p-md-5 {
    padding: 40px;
  }
  .p-md-6 {
    padding: 48px;
  }
  .px-md-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-md-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-md-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-md-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-md-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-md-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-md-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .py-md-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-md-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-md-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-md-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-md-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-md-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-md-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pt-md-0 {
    padding-top: 0;
  }
  .pt-md-1 {
    padding-top: 8px;
  }
  .pt-md-2 {
    padding-top: 16px;
  }
  .pt-md-3 {
    padding-top: 24px;
  }
  .pt-md-4 {
    padding-top: 32px;
  }
  .pt-md-5 {
    padding-top: 40px;
  }
  .pt-md-6 {
    padding-top: 48px;
  }
  .pe-md-0 {
    padding-right: 0;
  }
  .pe-md-1 {
    padding-right: 8px;
  }
  .pe-md-2 {
    padding-right: 16px;
  }
  .pe-md-3 {
    padding-right: 24px;
  }
  .pe-md-4 {
    padding-right: 32px;
  }
  .pe-md-5 {
    padding-right: 40px;
  }
  .pe-md-6 {
    padding-right: 48px;
  }
  .pb-md-0 {
    padding-bottom: 0;
  }
  .pb-md-1 {
    padding-bottom: 8px;
  }
  .pb-md-2 {
    padding-bottom: 16px;
  }
  .pb-md-3 {
    padding-bottom: 24px;
  }
  .pb-md-4 {
    padding-bottom: 32px;
  }
  .pb-md-5 {
    padding-bottom: 40px;
  }
  .pb-md-6 {
    padding-bottom: 48px;
  }
  .ps-md-0 {
    padding-left: 0;
  }
  .ps-md-1 {
    padding-left: 8px;
  }
  .ps-md-2 {
    padding-left: 16px;
  }
  .ps-md-3 {
    padding-left: 24px;
  }
  .ps-md-4 {
    padding-left: 32px;
  }
  .ps-md-5 {
    padding-left: 40px;
  }
  .ps-md-6 {
    padding-left: 48px;
  }
  .gap-md-0 {
    gap: 0;
  }
  .gap-md-1 {
    gap: 8px;
  }
  .gap-md-2 {
    gap: 16px;
  }
  .gap-md-3 {
    gap: 24px;
  }
  .gap-md-4 {
    gap: 32px;
  }
  .gap-md-5 {
    gap: 40px;
  }
  .gap-md-6 {
    gap: 48px;
  }
  .row-gap-md-0 {
    row-gap: 0;
  }
  .row-gap-md-1 {
    row-gap: 8px;
  }
  .row-gap-md-2 {
    row-gap: 16px;
  }
  .row-gap-md-3 {
    row-gap: 24px;
  }
  .row-gap-md-4 {
    row-gap: 32px;
  }
  .row-gap-md-5 {
    row-gap: 40px;
  }
  .row-gap-md-6 {
    row-gap: 48px;
  }
  .column-gap-md-0 {
    column-gap: 0;
  }
  .column-gap-md-1 {
    column-gap: 8px;
  }
  .column-gap-md-2 {
    column-gap: 16px;
  }
  .column-gap-md-3 {
    column-gap: 24px;
  }
  .column-gap-md-4 {
    column-gap: 32px;
  }
  .column-gap-md-5 {
    column-gap: 40px;
  }
  .column-gap-md-6 {
    column-gap: 48px;
  }
  .text-md-start {
    text-align: left;
  }
  .text-md-end {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left;
  }
  .float-lg-end {
    float: right;
  }
  .float-lg-none {
    float: none;
  }
  .object-fit-lg-contain {
    object-fit: contain;
  }
  .object-fit-lg-cover {
    object-fit: cover;
  }
  .object-fit-lg-fill {
    object-fit: fill;
  }
  .object-fit-lg-scale {
    object-fit: scale-down;
  }
  .object-fit-lg-none {
    object-fit: none;
  }
  .d-lg-inline {
    display: inline;
  }
  .d-lg-inline-block {
    display: inline-block;
  }
  .d-lg-block {
    display: block;
  }
  .d-lg-grid {
    display: grid;
  }
  .d-lg-inline-grid {
    display: inline-grid;
  }
  .d-lg-table {
    display: table;
  }
  .d-lg-table-row {
    display: table-row;
  }
  .d-lg-table-cell {
    display: table-cell;
  }
  .d-lg-flex {
    display: flex;
  }
  .d-lg-inline-flex {
    display: inline-flex;
  }
  .d-lg-none {
    display: none;
  }
  .flex-lg-fill {
    flex: 1 1 auto;
  }
  .flex-lg-row {
    flex-direction: row;
  }
  .flex-lg-column {
    flex-direction: column;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-lg-grow-0 {
    flex-grow: 0;
  }
  .flex-lg-grow-1 {
    flex-grow: 1;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1;
  }
  .flex-lg-wrap {
    flex-wrap: wrap;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-lg-start {
    justify-content: flex-start;
  }
  .justify-content-lg-end {
    justify-content: flex-end;
  }
  .justify-content-lg-center {
    justify-content: center;
  }
  .justify-content-lg-between {
    justify-content: space-between;
  }
  .justify-content-lg-around {
    justify-content: space-around;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly;
  }
  .align-items-lg-start {
    align-items: flex-start;
  }
  .align-items-lg-end {
    align-items: flex-end;
  }
  .align-items-lg-center {
    align-items: center;
  }
  .align-items-lg-baseline {
    align-items: baseline;
  }
  .align-items-lg-stretch {
    align-items: stretch;
  }
  .align-content-lg-start {
    align-content: flex-start;
  }
  .align-content-lg-end {
    align-content: flex-end;
  }
  .align-content-lg-center {
    align-content: center;
  }
  .align-content-lg-between {
    align-content: space-between;
  }
  .align-content-lg-around {
    align-content: space-around;
  }
  .align-content-lg-stretch {
    align-content: stretch;
  }
  .align-self-lg-auto {
    align-self: auto;
  }
  .align-self-lg-start {
    align-self: flex-start;
  }
  .align-self-lg-end {
    align-self: flex-end;
  }
  .align-self-lg-center {
    align-self: center;
  }
  .align-self-lg-baseline {
    align-self: baseline;
  }
  .align-self-lg-stretch {
    align-self: stretch;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-last {
    order: 6;
  }
  .m-lg-0 {
    margin: 0;
  }
  .m-lg-1 {
    margin: 8px;
  }
  .m-lg-2 {
    margin: 16px;
  }
  .m-lg-3 {
    margin: 24px;
  }
  .m-lg-4 {
    margin: 32px;
  }
  .m-lg-5 {
    margin: 40px;
  }
  .m-lg-6 {
    margin: 48px;
  }
  .m-lg-auto {
    margin: auto;
  }
  .mx-lg-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-lg-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-lg-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-lg-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-lg-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-lg-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-lg-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-lg-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-lg-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-lg-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-lg-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-lg-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-lg-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-lg-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-lg-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-lg-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-lg-0 {
    margin-top: 0;
  }
  .mt-lg-1 {
    margin-top: 8px;
  }
  .mt-lg-2 {
    margin-top: 16px;
  }
  .mt-lg-3 {
    margin-top: 24px;
  }
  .mt-lg-4 {
    margin-top: 32px;
  }
  .mt-lg-5 {
    margin-top: 40px;
  }
  .mt-lg-6 {
    margin-top: 48px;
  }
  .mt-lg-auto {
    margin-top: auto;
  }
  .me-lg-0 {
    margin-right: 0;
  }
  .me-lg-1 {
    margin-right: 8px;
  }
  .me-lg-2 {
    margin-right: 16px;
  }
  .me-lg-3 {
    margin-right: 24px;
  }
  .me-lg-4 {
    margin-right: 32px;
  }
  .me-lg-5 {
    margin-right: 40px;
  }
  .me-lg-6 {
    margin-right: 48px;
  }
  .me-lg-auto {
    margin-right: auto;
  }
  .mb-lg-0 {
    margin-bottom: 0;
  }
  .mb-lg-1 {
    margin-bottom: 8px;
  }
  .mb-lg-2 {
    margin-bottom: 16px;
  }
  .mb-lg-3 {
    margin-bottom: 24px;
  }
  .mb-lg-4 {
    margin-bottom: 32px;
  }
  .mb-lg-5 {
    margin-bottom: 40px;
  }
  .mb-lg-6 {
    margin-bottom: 48px;
  }
  .mb-lg-auto {
    margin-bottom: auto;
  }
  .ms-lg-0 {
    margin-left: 0;
  }
  .ms-lg-1 {
    margin-left: 8px;
  }
  .ms-lg-2 {
    margin-left: 16px;
  }
  .ms-lg-3 {
    margin-left: 24px;
  }
  .ms-lg-4 {
    margin-left: 32px;
  }
  .ms-lg-5 {
    margin-left: 40px;
  }
  .ms-lg-6 {
    margin-left: 48px;
  }
  .ms-lg-auto {
    margin-left: auto;
  }
  .p-lg-0 {
    padding: 0;
  }
  .p-lg-1 {
    padding: 8px;
  }
  .p-lg-2 {
    padding: 16px;
  }
  .p-lg-3 {
    padding: 24px;
  }
  .p-lg-4 {
    padding: 32px;
  }
  .p-lg-5 {
    padding: 40px;
  }
  .p-lg-6 {
    padding: 48px;
  }
  .px-lg-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-lg-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-lg-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-lg-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-lg-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-lg-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-lg-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .py-lg-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-lg-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-lg-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-lg-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-lg-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-lg-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-lg-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pt-lg-0 {
    padding-top: 0;
  }
  .pt-lg-1 {
    padding-top: 8px;
  }
  .pt-lg-2 {
    padding-top: 16px;
  }
  .pt-lg-3 {
    padding-top: 24px;
  }
  .pt-lg-4 {
    padding-top: 32px;
  }
  .pt-lg-5 {
    padding-top: 40px;
  }
  .pt-lg-6 {
    padding-top: 48px;
  }
  .pe-lg-0 {
    padding-right: 0;
  }
  .pe-lg-1 {
    padding-right: 8px;
  }
  .pe-lg-2 {
    padding-right: 16px;
  }
  .pe-lg-3 {
    padding-right: 24px;
  }
  .pe-lg-4 {
    padding-right: 32px;
  }
  .pe-lg-5 {
    padding-right: 40px;
  }
  .pe-lg-6 {
    padding-right: 48px;
  }
  .pb-lg-0 {
    padding-bottom: 0;
  }
  .pb-lg-1 {
    padding-bottom: 8px;
  }
  .pb-lg-2 {
    padding-bottom: 16px;
  }
  .pb-lg-3 {
    padding-bottom: 24px;
  }
  .pb-lg-4 {
    padding-bottom: 32px;
  }
  .pb-lg-5 {
    padding-bottom: 40px;
  }
  .pb-lg-6 {
    padding-bottom: 48px;
  }
  .ps-lg-0 {
    padding-left: 0;
  }
  .ps-lg-1 {
    padding-left: 8px;
  }
  .ps-lg-2 {
    padding-left: 16px;
  }
  .ps-lg-3 {
    padding-left: 24px;
  }
  .ps-lg-4 {
    padding-left: 32px;
  }
  .ps-lg-5 {
    padding-left: 40px;
  }
  .ps-lg-6 {
    padding-left: 48px;
  }
  .gap-lg-0 {
    gap: 0;
  }
  .gap-lg-1 {
    gap: 8px;
  }
  .gap-lg-2 {
    gap: 16px;
  }
  .gap-lg-3 {
    gap: 24px;
  }
  .gap-lg-4 {
    gap: 32px;
  }
  .gap-lg-5 {
    gap: 40px;
  }
  .gap-lg-6 {
    gap: 48px;
  }
  .row-gap-lg-0 {
    row-gap: 0;
  }
  .row-gap-lg-1 {
    row-gap: 8px;
  }
  .row-gap-lg-2 {
    row-gap: 16px;
  }
  .row-gap-lg-3 {
    row-gap: 24px;
  }
  .row-gap-lg-4 {
    row-gap: 32px;
  }
  .row-gap-lg-5 {
    row-gap: 40px;
  }
  .row-gap-lg-6 {
    row-gap: 48px;
  }
  .column-gap-lg-0 {
    column-gap: 0;
  }
  .column-gap-lg-1 {
    column-gap: 8px;
  }
  .column-gap-lg-2 {
    column-gap: 16px;
  }
  .column-gap-lg-3 {
    column-gap: 24px;
  }
  .column-gap-lg-4 {
    column-gap: 32px;
  }
  .column-gap-lg-5 {
    column-gap: 40px;
  }
  .column-gap-lg-6 {
    column-gap: 48px;
  }
  .text-lg-start {
    text-align: left;
  }
  .text-lg-end {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
}
@media (min-width: 1300px) {
  .float-xl-start {
    float: left;
  }
  .float-xl-end {
    float: right;
  }
  .float-xl-none {
    float: none;
  }
  .object-fit-xl-contain {
    object-fit: contain;
  }
  .object-fit-xl-cover {
    object-fit: cover;
  }
  .object-fit-xl-fill {
    object-fit: fill;
  }
  .object-fit-xl-scale {
    object-fit: scale-down;
  }
  .object-fit-xl-none {
    object-fit: none;
  }
  .d-xl-inline {
    display: inline;
  }
  .d-xl-inline-block {
    display: inline-block;
  }
  .d-xl-block {
    display: block;
  }
  .d-xl-grid {
    display: grid;
  }
  .d-xl-inline-grid {
    display: inline-grid;
  }
  .d-xl-table {
    display: table;
  }
  .d-xl-table-row {
    display: table-row;
  }
  .d-xl-table-cell {
    display: table-cell;
  }
  .d-xl-flex {
    display: flex;
  }
  .d-xl-inline-flex {
    display: inline-flex;
  }
  .d-xl-none {
    display: none;
  }
  .flex-xl-fill {
    flex: 1 1 auto;
  }
  .flex-xl-row {
    flex-direction: row;
  }
  .flex-xl-column {
    flex-direction: column;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xl-grow-0 {
    flex-grow: 0;
  }
  .flex-xl-grow-1 {
    flex-grow: 1;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xl-wrap {
    flex-wrap: wrap;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xl-start {
    justify-content: flex-start;
  }
  .justify-content-xl-end {
    justify-content: flex-end;
  }
  .justify-content-xl-center {
    justify-content: center;
  }
  .justify-content-xl-between {
    justify-content: space-between;
  }
  .justify-content-xl-around {
    justify-content: space-around;
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xl-start {
    align-items: flex-start;
  }
  .align-items-xl-end {
    align-items: flex-end;
  }
  .align-items-xl-center {
    align-items: center;
  }
  .align-items-xl-baseline {
    align-items: baseline;
  }
  .align-items-xl-stretch {
    align-items: stretch;
  }
  .align-content-xl-start {
    align-content: flex-start;
  }
  .align-content-xl-end {
    align-content: flex-end;
  }
  .align-content-xl-center {
    align-content: center;
  }
  .align-content-xl-between {
    align-content: space-between;
  }
  .align-content-xl-around {
    align-content: space-around;
  }
  .align-content-xl-stretch {
    align-content: stretch;
  }
  .align-self-xl-auto {
    align-self: auto;
  }
  .align-self-xl-start {
    align-self: flex-start;
  }
  .align-self-xl-end {
    align-self: flex-end;
  }
  .align-self-xl-center {
    align-self: center;
  }
  .align-self-xl-baseline {
    align-self: baseline;
  }
  .align-self-xl-stretch {
    align-self: stretch;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-last {
    order: 6;
  }
  .m-xl-0 {
    margin: 0;
  }
  .m-xl-1 {
    margin: 8px;
  }
  .m-xl-2 {
    margin: 16px;
  }
  .m-xl-3 {
    margin: 24px;
  }
  .m-xl-4 {
    margin: 32px;
  }
  .m-xl-5 {
    margin: 40px;
  }
  .m-xl-6 {
    margin: 48px;
  }
  .m-xl-auto {
    margin: auto;
  }
  .mx-xl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xl-0 {
    margin-top: 0;
  }
  .mt-xl-1 {
    margin-top: 8px;
  }
  .mt-xl-2 {
    margin-top: 16px;
  }
  .mt-xl-3 {
    margin-top: 24px;
  }
  .mt-xl-4 {
    margin-top: 32px;
  }
  .mt-xl-5 {
    margin-top: 40px;
  }
  .mt-xl-6 {
    margin-top: 48px;
  }
  .mt-xl-auto {
    margin-top: auto;
  }
  .me-xl-0 {
    margin-right: 0;
  }
  .me-xl-1 {
    margin-right: 8px;
  }
  .me-xl-2 {
    margin-right: 16px;
  }
  .me-xl-3 {
    margin-right: 24px;
  }
  .me-xl-4 {
    margin-right: 32px;
  }
  .me-xl-5 {
    margin-right: 40px;
  }
  .me-xl-6 {
    margin-right: 48px;
  }
  .me-xl-auto {
    margin-right: auto;
  }
  .mb-xl-0 {
    margin-bottom: 0;
  }
  .mb-xl-1 {
    margin-bottom: 8px;
  }
  .mb-xl-2 {
    margin-bottom: 16px;
  }
  .mb-xl-3 {
    margin-bottom: 24px;
  }
  .mb-xl-4 {
    margin-bottom: 32px;
  }
  .mb-xl-5 {
    margin-bottom: 40px;
  }
  .mb-xl-6 {
    margin-bottom: 48px;
  }
  .mb-xl-auto {
    margin-bottom: auto;
  }
  .ms-xl-0 {
    margin-left: 0;
  }
  .ms-xl-1 {
    margin-left: 8px;
  }
  .ms-xl-2 {
    margin-left: 16px;
  }
  .ms-xl-3 {
    margin-left: 24px;
  }
  .ms-xl-4 {
    margin-left: 32px;
  }
  .ms-xl-5 {
    margin-left: 40px;
  }
  .ms-xl-6 {
    margin-left: 48px;
  }
  .ms-xl-auto {
    margin-left: auto;
  }
  .p-xl-0 {
    padding: 0;
  }
  .p-xl-1 {
    padding: 8px;
  }
  .p-xl-2 {
    padding: 16px;
  }
  .p-xl-3 {
    padding: 24px;
  }
  .p-xl-4 {
    padding: 32px;
  }
  .p-xl-5 {
    padding: 40px;
  }
  .p-xl-6 {
    padding: 48px;
  }
  .px-xl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .py-xl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pt-xl-0 {
    padding-top: 0;
  }
  .pt-xl-1 {
    padding-top: 8px;
  }
  .pt-xl-2 {
    padding-top: 16px;
  }
  .pt-xl-3 {
    padding-top: 24px;
  }
  .pt-xl-4 {
    padding-top: 32px;
  }
  .pt-xl-5 {
    padding-top: 40px;
  }
  .pt-xl-6 {
    padding-top: 48px;
  }
  .pe-xl-0 {
    padding-right: 0;
  }
  .pe-xl-1 {
    padding-right: 8px;
  }
  .pe-xl-2 {
    padding-right: 16px;
  }
  .pe-xl-3 {
    padding-right: 24px;
  }
  .pe-xl-4 {
    padding-right: 32px;
  }
  .pe-xl-5 {
    padding-right: 40px;
  }
  .pe-xl-6 {
    padding-right: 48px;
  }
  .pb-xl-0 {
    padding-bottom: 0;
  }
  .pb-xl-1 {
    padding-bottom: 8px;
  }
  .pb-xl-2 {
    padding-bottom: 16px;
  }
  .pb-xl-3 {
    padding-bottom: 24px;
  }
  .pb-xl-4 {
    padding-bottom: 32px;
  }
  .pb-xl-5 {
    padding-bottom: 40px;
  }
  .pb-xl-6 {
    padding-bottom: 48px;
  }
  .ps-xl-0 {
    padding-left: 0;
  }
  .ps-xl-1 {
    padding-left: 8px;
  }
  .ps-xl-2 {
    padding-left: 16px;
  }
  .ps-xl-3 {
    padding-left: 24px;
  }
  .ps-xl-4 {
    padding-left: 32px;
  }
  .ps-xl-5 {
    padding-left: 40px;
  }
  .ps-xl-6 {
    padding-left: 48px;
  }
  .gap-xl-0 {
    gap: 0;
  }
  .gap-xl-1 {
    gap: 8px;
  }
  .gap-xl-2 {
    gap: 16px;
  }
  .gap-xl-3 {
    gap: 24px;
  }
  .gap-xl-4 {
    gap: 32px;
  }
  .gap-xl-5 {
    gap: 40px;
  }
  .gap-xl-6 {
    gap: 48px;
  }
  .row-gap-xl-0 {
    row-gap: 0;
  }
  .row-gap-xl-1 {
    row-gap: 8px;
  }
  .row-gap-xl-2 {
    row-gap: 16px;
  }
  .row-gap-xl-3 {
    row-gap: 24px;
  }
  .row-gap-xl-4 {
    row-gap: 32px;
  }
  .row-gap-xl-5 {
    row-gap: 40px;
  }
  .row-gap-xl-6 {
    row-gap: 48px;
  }
  .column-gap-xl-0 {
    column-gap: 0;
  }
  .column-gap-xl-1 {
    column-gap: 8px;
  }
  .column-gap-xl-2 {
    column-gap: 16px;
  }
  .column-gap-xl-3 {
    column-gap: 24px;
  }
  .column-gap-xl-4 {
    column-gap: 32px;
  }
  .column-gap-xl-5 {
    column-gap: 40px;
  }
  .column-gap-xl-6 {
    column-gap: 48px;
  }
  .text-xl-start {
    text-align: left;
  }
  .text-xl-end {
    text-align: right;
  }
  .text-xl-center {
    text-align: center;
  }
}
@media (min-width: 1500px) {
  .float-xxl-start {
    float: left;
  }
  .float-xxl-end {
    float: right;
  }
  .float-xxl-none {
    float: none;
  }
  .object-fit-xxl-contain {
    object-fit: contain;
  }
  .object-fit-xxl-cover {
    object-fit: cover;
  }
  .object-fit-xxl-fill {
    object-fit: fill;
  }
  .object-fit-xxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxl-none {
    object-fit: none;
  }
  .d-xxl-inline {
    display: inline;
  }
  .d-xxl-inline-block {
    display: inline-block;
  }
  .d-xxl-block {
    display: block;
  }
  .d-xxl-grid {
    display: grid;
  }
  .d-xxl-inline-grid {
    display: inline-grid;
  }
  .d-xxl-table {
    display: table;
  }
  .d-xxl-table-row {
    display: table-row;
  }
  .d-xxl-table-cell {
    display: table-cell;
  }
  .d-xxl-flex {
    display: flex;
  }
  .d-xxl-inline-flex {
    display: inline-flex;
  }
  .d-xxl-none {
    display: none;
  }
  .flex-xxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxl-row {
    flex-direction: row;
  }
  .flex-xxl-column {
    flex-direction: column;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxl-center {
    justify-content: center;
  }
  .justify-content-xxl-between {
    justify-content: space-between;
  }
  .justify-content-xxl-around {
    justify-content: space-around;
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxl-start {
    align-items: flex-start;
  }
  .align-items-xxl-end {
    align-items: flex-end;
  }
  .align-items-xxl-center {
    align-items: center;
  }
  .align-items-xxl-baseline {
    align-items: baseline;
  }
  .align-items-xxl-stretch {
    align-items: stretch;
  }
  .align-content-xxl-start {
    align-content: flex-start;
  }
  .align-content-xxl-end {
    align-content: flex-end;
  }
  .align-content-xxl-center {
    align-content: center;
  }
  .align-content-xxl-between {
    align-content: space-between;
  }
  .align-content-xxl-around {
    align-content: space-around;
  }
  .align-content-xxl-stretch {
    align-content: stretch;
  }
  .align-self-xxl-auto {
    align-self: auto;
  }
  .align-self-xxl-start {
    align-self: flex-start;
  }
  .align-self-xxl-end {
    align-self: flex-end;
  }
  .align-self-xxl-center {
    align-self: center;
  }
  .align-self-xxl-baseline {
    align-self: baseline;
  }
  .align-self-xxl-stretch {
    align-self: stretch;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-last {
    order: 6;
  }
  .m-xxl-0 {
    margin: 0;
  }
  .m-xxl-1 {
    margin: 8px;
  }
  .m-xxl-2 {
    margin: 16px;
  }
  .m-xxl-3 {
    margin: 24px;
  }
  .m-xxl-4 {
    margin: 32px;
  }
  .m-xxl-5 {
    margin: 40px;
  }
  .m-xxl-6 {
    margin: 48px;
  }
  .m-xxl-auto {
    margin: auto;
  }
  .mx-xxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xxl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xxl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xxl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xxl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxl-0 {
    margin-top: 0;
  }
  .mt-xxl-1 {
    margin-top: 8px;
  }
  .mt-xxl-2 {
    margin-top: 16px;
  }
  .mt-xxl-3 {
    margin-top: 24px;
  }
  .mt-xxl-4 {
    margin-top: 32px;
  }
  .mt-xxl-5 {
    margin-top: 40px;
  }
  .mt-xxl-6 {
    margin-top: 48px;
  }
  .mt-xxl-auto {
    margin-top: auto;
  }
  .me-xxl-0 {
    margin-right: 0;
  }
  .me-xxl-1 {
    margin-right: 8px;
  }
  .me-xxl-2 {
    margin-right: 16px;
  }
  .me-xxl-3 {
    margin-right: 24px;
  }
  .me-xxl-4 {
    margin-right: 32px;
  }
  .me-xxl-5 {
    margin-right: 40px;
  }
  .me-xxl-6 {
    margin-right: 48px;
  }
  .me-xxl-auto {
    margin-right: auto;
  }
  .mb-xxl-0 {
    margin-bottom: 0;
  }
  .mb-xxl-1 {
    margin-bottom: 8px;
  }
  .mb-xxl-2 {
    margin-bottom: 16px;
  }
  .mb-xxl-3 {
    margin-bottom: 24px;
  }
  .mb-xxl-4 {
    margin-bottom: 32px;
  }
  .mb-xxl-5 {
    margin-bottom: 40px;
  }
  .mb-xxl-6 {
    margin-bottom: 48px;
  }
  .mb-xxl-auto {
    margin-bottom: auto;
  }
  .ms-xxl-0 {
    margin-left: 0;
  }
  .ms-xxl-1 {
    margin-left: 8px;
  }
  .ms-xxl-2 {
    margin-left: 16px;
  }
  .ms-xxl-3 {
    margin-left: 24px;
  }
  .ms-xxl-4 {
    margin-left: 32px;
  }
  .ms-xxl-5 {
    margin-left: 40px;
  }
  .ms-xxl-6 {
    margin-left: 48px;
  }
  .ms-xxl-auto {
    margin-left: auto;
  }
  .p-xxl-0 {
    padding: 0;
  }
  .p-xxl-1 {
    padding: 8px;
  }
  .p-xxl-2 {
    padding: 16px;
  }
  .p-xxl-3 {
    padding: 24px;
  }
  .p-xxl-4 {
    padding: 32px;
  }
  .p-xxl-5 {
    padding: 40px;
  }
  .p-xxl-6 {
    padding: 48px;
  }
  .px-xxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xxl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xxl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xxl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xxl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .py-xxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pt-xxl-0 {
    padding-top: 0;
  }
  .pt-xxl-1 {
    padding-top: 8px;
  }
  .pt-xxl-2 {
    padding-top: 16px;
  }
  .pt-xxl-3 {
    padding-top: 24px;
  }
  .pt-xxl-4 {
    padding-top: 32px;
  }
  .pt-xxl-5 {
    padding-top: 40px;
  }
  .pt-xxl-6 {
    padding-top: 48px;
  }
  .pe-xxl-0 {
    padding-right: 0;
  }
  .pe-xxl-1 {
    padding-right: 8px;
  }
  .pe-xxl-2 {
    padding-right: 16px;
  }
  .pe-xxl-3 {
    padding-right: 24px;
  }
  .pe-xxl-4 {
    padding-right: 32px;
  }
  .pe-xxl-5 {
    padding-right: 40px;
  }
  .pe-xxl-6 {
    padding-right: 48px;
  }
  .pb-xxl-0 {
    padding-bottom: 0;
  }
  .pb-xxl-1 {
    padding-bottom: 8px;
  }
  .pb-xxl-2 {
    padding-bottom: 16px;
  }
  .pb-xxl-3 {
    padding-bottom: 24px;
  }
  .pb-xxl-4 {
    padding-bottom: 32px;
  }
  .pb-xxl-5 {
    padding-bottom: 40px;
  }
  .pb-xxl-6 {
    padding-bottom: 48px;
  }
  .ps-xxl-0 {
    padding-left: 0;
  }
  .ps-xxl-1 {
    padding-left: 8px;
  }
  .ps-xxl-2 {
    padding-left: 16px;
  }
  .ps-xxl-3 {
    padding-left: 24px;
  }
  .ps-xxl-4 {
    padding-left: 32px;
  }
  .ps-xxl-5 {
    padding-left: 40px;
  }
  .ps-xxl-6 {
    padding-left: 48px;
  }
  .gap-xxl-0 {
    gap: 0;
  }
  .gap-xxl-1 {
    gap: 8px;
  }
  .gap-xxl-2 {
    gap: 16px;
  }
  .gap-xxl-3 {
    gap: 24px;
  }
  .gap-xxl-4 {
    gap: 32px;
  }
  .gap-xxl-5 {
    gap: 40px;
  }
  .gap-xxl-6 {
    gap: 48px;
  }
  .row-gap-xxl-0 {
    row-gap: 0;
  }
  .row-gap-xxl-1 {
    row-gap: 8px;
  }
  .row-gap-xxl-2 {
    row-gap: 16px;
  }
  .row-gap-xxl-3 {
    row-gap: 24px;
  }
  .row-gap-xxl-4 {
    row-gap: 32px;
  }
  .row-gap-xxl-5 {
    row-gap: 40px;
  }
  .row-gap-xxl-6 {
    row-gap: 48px;
  }
  .column-gap-xxl-0 {
    column-gap: 0;
  }
  .column-gap-xxl-1 {
    column-gap: 8px;
  }
  .column-gap-xxl-2 {
    column-gap: 16px;
  }
  .column-gap-xxl-3 {
    column-gap: 24px;
  }
  .column-gap-xxl-4 {
    column-gap: 32px;
  }
  .column-gap-xxl-5 {
    column-gap: 40px;
  }
  .column-gap-xxl-6 {
    column-gap: 48px;
  }
  .text-xxl-start {
    text-align: left;
  }
  .text-xxl-end {
    text-align: right;
  }
  .text-xxl-center {
    text-align: center;
  }
}
@media (min-width: 1740px) {
  .float-xxxl-start {
    float: left;
  }
  .float-xxxl-end {
    float: right;
  }
  .float-xxxl-none {
    float: none;
  }
  .object-fit-xxxl-contain {
    object-fit: contain;
  }
  .object-fit-xxxl-cover {
    object-fit: cover;
  }
  .object-fit-xxxl-fill {
    object-fit: fill;
  }
  .object-fit-xxxl-scale {
    object-fit: scale-down;
  }
  .object-fit-xxxl-none {
    object-fit: none;
  }
  .d-xxxl-inline {
    display: inline;
  }
  .d-xxxl-inline-block {
    display: inline-block;
  }
  .d-xxxl-block {
    display: block;
  }
  .d-xxxl-grid {
    display: grid;
  }
  .d-xxxl-inline-grid {
    display: inline-grid;
  }
  .d-xxxl-table {
    display: table;
  }
  .d-xxxl-table-row {
    display: table-row;
  }
  .d-xxxl-table-cell {
    display: table-cell;
  }
  .d-xxxl-flex {
    display: flex;
  }
  .d-xxxl-inline-flex {
    display: inline-flex;
  }
  .d-xxxl-none {
    display: none;
  }
  .flex-xxxl-fill {
    flex: 1 1 auto;
  }
  .flex-xxxl-row {
    flex-direction: row;
  }
  .flex-xxxl-column {
    flex-direction: column;
  }
  .flex-xxxl-row-reverse {
    flex-direction: row-reverse;
  }
  .flex-xxxl-column-reverse {
    flex-direction: column-reverse;
  }
  .flex-xxxl-grow-0 {
    flex-grow: 0;
  }
  .flex-xxxl-grow-1 {
    flex-grow: 1;
  }
  .flex-xxxl-shrink-0 {
    flex-shrink: 0;
  }
  .flex-xxxl-shrink-1 {
    flex-shrink: 1;
  }
  .flex-xxxl-wrap {
    flex-wrap: wrap;
  }
  .flex-xxxl-nowrap {
    flex-wrap: nowrap;
  }
  .flex-xxxl-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .justify-content-xxxl-start {
    justify-content: flex-start;
  }
  .justify-content-xxxl-end {
    justify-content: flex-end;
  }
  .justify-content-xxxl-center {
    justify-content: center;
  }
  .justify-content-xxxl-between {
    justify-content: space-between;
  }
  .justify-content-xxxl-around {
    justify-content: space-around;
  }
  .justify-content-xxxl-evenly {
    justify-content: space-evenly;
  }
  .align-items-xxxl-start {
    align-items: flex-start;
  }
  .align-items-xxxl-end {
    align-items: flex-end;
  }
  .align-items-xxxl-center {
    align-items: center;
  }
  .align-items-xxxl-baseline {
    align-items: baseline;
  }
  .align-items-xxxl-stretch {
    align-items: stretch;
  }
  .align-content-xxxl-start {
    align-content: flex-start;
  }
  .align-content-xxxl-end {
    align-content: flex-end;
  }
  .align-content-xxxl-center {
    align-content: center;
  }
  .align-content-xxxl-between {
    align-content: space-between;
  }
  .align-content-xxxl-around {
    align-content: space-around;
  }
  .align-content-xxxl-stretch {
    align-content: stretch;
  }
  .align-self-xxxl-auto {
    align-self: auto;
  }
  .align-self-xxxl-start {
    align-self: flex-start;
  }
  .align-self-xxxl-end {
    align-self: flex-end;
  }
  .align-self-xxxl-center {
    align-self: center;
  }
  .align-self-xxxl-baseline {
    align-self: baseline;
  }
  .align-self-xxxl-stretch {
    align-self: stretch;
  }
  .order-xxxl-first {
    order: -1;
  }
  .order-xxxl-0 {
    order: 0;
  }
  .order-xxxl-1 {
    order: 1;
  }
  .order-xxxl-2 {
    order: 2;
  }
  .order-xxxl-3 {
    order: 3;
  }
  .order-xxxl-4 {
    order: 4;
  }
  .order-xxxl-5 {
    order: 5;
  }
  .order-xxxl-last {
    order: 6;
  }
  .m-xxxl-0 {
    margin: 0;
  }
  .m-xxxl-1 {
    margin: 8px;
  }
  .m-xxxl-2 {
    margin: 16px;
  }
  .m-xxxl-3 {
    margin: 24px;
  }
  .m-xxxl-4 {
    margin: 32px;
  }
  .m-xxxl-5 {
    margin: 40px;
  }
  .m-xxxl-6 {
    margin: 48px;
  }
  .m-xxxl-auto {
    margin: auto;
  }
  .mx-xxxl-0 {
    margin-right: 0;
    margin-left: 0;
  }
  .mx-xxxl-1 {
    margin-right: 8px;
    margin-left: 8px;
  }
  .mx-xxxl-2 {
    margin-right: 16px;
    margin-left: 16px;
  }
  .mx-xxxl-3 {
    margin-right: 24px;
    margin-left: 24px;
  }
  .mx-xxxl-4 {
    margin-right: 32px;
    margin-left: 32px;
  }
  .mx-xxxl-5 {
    margin-right: 40px;
    margin-left: 40px;
  }
  .mx-xxxl-6 {
    margin-right: 48px;
    margin-left: 48px;
  }
  .mx-xxxl-auto {
    margin-right: auto;
    margin-left: auto;
  }
  .my-xxxl-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .my-xxxl-1 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .my-xxxl-2 {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .my-xxxl-3 {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .my-xxxl-4 {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .my-xxxl-5 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .my-xxxl-6 {
    margin-top: 48px;
    margin-bottom: 48px;
  }
  .my-xxxl-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .mt-xxxl-0 {
    margin-top: 0;
  }
  .mt-xxxl-1 {
    margin-top: 8px;
  }
  .mt-xxxl-2 {
    margin-top: 16px;
  }
  .mt-xxxl-3 {
    margin-top: 24px;
  }
  .mt-xxxl-4 {
    margin-top: 32px;
  }
  .mt-xxxl-5 {
    margin-top: 40px;
  }
  .mt-xxxl-6 {
    margin-top: 48px;
  }
  .mt-xxxl-auto {
    margin-top: auto;
  }
  .me-xxxl-0 {
    margin-right: 0;
  }
  .me-xxxl-1 {
    margin-right: 8px;
  }
  .me-xxxl-2 {
    margin-right: 16px;
  }
  .me-xxxl-3 {
    margin-right: 24px;
  }
  .me-xxxl-4 {
    margin-right: 32px;
  }
  .me-xxxl-5 {
    margin-right: 40px;
  }
  .me-xxxl-6 {
    margin-right: 48px;
  }
  .me-xxxl-auto {
    margin-right: auto;
  }
  .mb-xxxl-0 {
    margin-bottom: 0;
  }
  .mb-xxxl-1 {
    margin-bottom: 8px;
  }
  .mb-xxxl-2 {
    margin-bottom: 16px;
  }
  .mb-xxxl-3 {
    margin-bottom: 24px;
  }
  .mb-xxxl-4 {
    margin-bottom: 32px;
  }
  .mb-xxxl-5 {
    margin-bottom: 40px;
  }
  .mb-xxxl-6 {
    margin-bottom: 48px;
  }
  .mb-xxxl-auto {
    margin-bottom: auto;
  }
  .ms-xxxl-0 {
    margin-left: 0;
  }
  .ms-xxxl-1 {
    margin-left: 8px;
  }
  .ms-xxxl-2 {
    margin-left: 16px;
  }
  .ms-xxxl-3 {
    margin-left: 24px;
  }
  .ms-xxxl-4 {
    margin-left: 32px;
  }
  .ms-xxxl-5 {
    margin-left: 40px;
  }
  .ms-xxxl-6 {
    margin-left: 48px;
  }
  .ms-xxxl-auto {
    margin-left: auto;
  }
  .p-xxxl-0 {
    padding: 0;
  }
  .p-xxxl-1 {
    padding: 8px;
  }
  .p-xxxl-2 {
    padding: 16px;
  }
  .p-xxxl-3 {
    padding: 24px;
  }
  .p-xxxl-4 {
    padding: 32px;
  }
  .p-xxxl-5 {
    padding: 40px;
  }
  .p-xxxl-6 {
    padding: 48px;
  }
  .px-xxxl-0 {
    padding-right: 0;
    padding-left: 0;
  }
  .px-xxxl-1 {
    padding-right: 8px;
    padding-left: 8px;
  }
  .px-xxxl-2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .px-xxxl-3 {
    padding-right: 24px;
    padding-left: 24px;
  }
  .px-xxxl-4 {
    padding-right: 32px;
    padding-left: 32px;
  }
  .px-xxxl-5 {
    padding-right: 40px;
    padding-left: 40px;
  }
  .px-xxxl-6 {
    padding-right: 48px;
    padding-left: 48px;
  }
  .py-xxxl-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .py-xxxl-1 {
    padding-top: 8px;
    padding-bottom: 8px;
  }
  .py-xxxl-2 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .py-xxxl-3 {
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .py-xxxl-4 {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .py-xxxl-5 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .py-xxxl-6 {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .pt-xxxl-0 {
    padding-top: 0;
  }
  .pt-xxxl-1 {
    padding-top: 8px;
  }
  .pt-xxxl-2 {
    padding-top: 16px;
  }
  .pt-xxxl-3 {
    padding-top: 24px;
  }
  .pt-xxxl-4 {
    padding-top: 32px;
  }
  .pt-xxxl-5 {
    padding-top: 40px;
  }
  .pt-xxxl-6 {
    padding-top: 48px;
  }
  .pe-xxxl-0 {
    padding-right: 0;
  }
  .pe-xxxl-1 {
    padding-right: 8px;
  }
  .pe-xxxl-2 {
    padding-right: 16px;
  }
  .pe-xxxl-3 {
    padding-right: 24px;
  }
  .pe-xxxl-4 {
    padding-right: 32px;
  }
  .pe-xxxl-5 {
    padding-right: 40px;
  }
  .pe-xxxl-6 {
    padding-right: 48px;
  }
  .pb-xxxl-0 {
    padding-bottom: 0;
  }
  .pb-xxxl-1 {
    padding-bottom: 8px;
  }
  .pb-xxxl-2 {
    padding-bottom: 16px;
  }
  .pb-xxxl-3 {
    padding-bottom: 24px;
  }
  .pb-xxxl-4 {
    padding-bottom: 32px;
  }
  .pb-xxxl-5 {
    padding-bottom: 40px;
  }
  .pb-xxxl-6 {
    padding-bottom: 48px;
  }
  .ps-xxxl-0 {
    padding-left: 0;
  }
  .ps-xxxl-1 {
    padding-left: 8px;
  }
  .ps-xxxl-2 {
    padding-left: 16px;
  }
  .ps-xxxl-3 {
    padding-left: 24px;
  }
  .ps-xxxl-4 {
    padding-left: 32px;
  }
  .ps-xxxl-5 {
    padding-left: 40px;
  }
  .ps-xxxl-6 {
    padding-left: 48px;
  }
  .gap-xxxl-0 {
    gap: 0;
  }
  .gap-xxxl-1 {
    gap: 8px;
  }
  .gap-xxxl-2 {
    gap: 16px;
  }
  .gap-xxxl-3 {
    gap: 24px;
  }
  .gap-xxxl-4 {
    gap: 32px;
  }
  .gap-xxxl-5 {
    gap: 40px;
  }
  .gap-xxxl-6 {
    gap: 48px;
  }
  .row-gap-xxxl-0 {
    row-gap: 0;
  }
  .row-gap-xxxl-1 {
    row-gap: 8px;
  }
  .row-gap-xxxl-2 {
    row-gap: 16px;
  }
  .row-gap-xxxl-3 {
    row-gap: 24px;
  }
  .row-gap-xxxl-4 {
    row-gap: 32px;
  }
  .row-gap-xxxl-5 {
    row-gap: 40px;
  }
  .row-gap-xxxl-6 {
    row-gap: 48px;
  }
  .column-gap-xxxl-0 {
    column-gap: 0;
  }
  .column-gap-xxxl-1 {
    column-gap: 8px;
  }
  .column-gap-xxxl-2 {
    column-gap: 16px;
  }
  .column-gap-xxxl-3 {
    column-gap: 24px;
  }
  .column-gap-xxxl-4 {
    column-gap: 32px;
  }
  .column-gap-xxxl-5 {
    column-gap: 40px;
  }
  .column-gap-xxxl-6 {
    column-gap: 48px;
  }
  .text-xxxl-start {
    text-align: left;
  }
  .text-xxxl-end {
    text-align: right;
  }
  .text-xxxl-center {
    text-align: center;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem;
  }
  .fs-2 {
    font-size: 2rem;
  }
  .fs-3 {
    font-size: 1.75rem;
  }
  .fs-4 {
    font-size: 1.5rem;
  }
}
@media print {
  .d-print-inline {
    display: inline;
  }
  .d-print-inline-block {
    display: inline-block;
  }
  .d-print-block {
    display: block;
  }
  .d-print-grid {
    display: grid;
  }
  .d-print-inline-grid {
    display: inline-grid;
  }
  .d-print-table {
    display: table;
  }
  .d-print-table-row {
    display: table-row;
  }
  .d-print-table-cell {
    display: table-cell;
  }
  .d-print-flex {
    display: flex;
  }
  .d-print-inline-flex {
    display: inline-flex;
  }
  .d-print-none {
    display: none;
  }
}
/*! purgecss start ignore */
.container {
  padding-right: 26px;
  padding-left: 26px;
}
@media (max-width: 575.98px) {
  .container {
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (width <= 359px) {
  .container {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.fancybox__container {
  --fancybox-accent-color: #0f2944;
}

.flatpickr-calendar {
  --theme-color: #143558;
  --theme-color-hover: #0f2944;
}
.flatpickr-calendar .flatpickr-day.selected {
  background: var(--theme-color);
  border-color: var(--theme-color);
}
.flatpickr-calendar .flatpickr-day.selected:hover {
  background: var(--theme-color);
  border-color: var(--theme-color);
}
.flatpickr-calendar .flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-calendar .flatpickr-months .flatpickr-next-month:hover svg {
  fill: var(--theme-color);
}

.swiper {
  --swiper-pagination-color: #143558;
  overflow: hidden;
}

.swiper-wrapper {
  display: flex;
}

body {
  font-family: "Montserrat", verdana;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale !important;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
}

figure {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

html {
  height: 100%;
}

iframe {
  display: block;
  max-width: 100%;
}

img {
  display: block;
  opacity: 1;
  transition: opacity 0.5s ease;
  height: auto;
  max-width: 100%;
}
img[data-src], img[data-srcset] {
  display: block;
  min-height: 1px;
  min-width: 1px;
}
img[data-src]:not(.loaded) {
  opacity: 0;
}

a {
  display: inline-block;
  color: #143558;
  transition: text-decoration-color 0.3s ease;
  text-decoration: underline;
}
a:hover, a:focus {
  color: #143558;
  text-decoration-color: transparent;
}
a[href^="x-apple-data-detectors:"] {
  text-decoration: none;
  pointer-events: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

main {
  overflow-x: hidden;
  display: block;
  flex-grow: 1;
}

strong,
b {
  font-weight: 700;
}

.entry-content .column {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 24px;
}
.entry-content .column img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  float: none !important;
}
@media (max-width: 991.98px) {
  .entry-content .column h2:first-of-type,
  .entry-content .column h3:first-of-type,
  .entry-content .column h4:first-of-type,
  .entry-content .column h5:first-of-type,
  .entry-content .column h6:first-of-type {
    margin-top: 0;
  }
}
.entry-content .columns-1 {
  display: block;
}
.entry-content .columns-1 .column {
  display: block;
}
.entry-content .columns-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-2 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
}
@media (max-width: 991.98px) {
  .entry-content .columns-3 {
    display: flex;
    flex-direction: column;
  }
}
.entry-content .columns-4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
@media (max-width: 1299.98px) {
  .entry-content .columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .entry-content .columns-4 {
    display: flex;
    flex-direction: column;
  }
}

.validator {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: absolute;
  top: 100%;
  opacity: 0;
}
.validator .erorr-s {
  display: block;
  white-space: nowrap;
  color: #e52661;
  font-size: 12px;
  line-height: 16px;
  z-index: 99;
}
.validator:first-of-type {
  opacity: 1;
}

.entry-content .alignnone {
  margin: 5px 20px 20px 0;
}
.entry-content .alignright {
  float: right;
  margin: 0 0 20px 30px;
}
.entry-content .alignright img {
  margin-block: 0;
}
.entry-content .alignleft {
  float: left;
  margin: 0 30px 20px 0;
}
.entry-content .alignleft img {
  margin-block: 0;
}
.entry-content .aligncenter,
.entry-content div.aligncenter {
  display: block;
  margin: 48px auto;
}
.entry-content .aligncenter img,
.entry-content div.aligncenter img {
  margin-inline: auto;
  margin-block: 0;
}
@media (max-width: 575.98px) {
  .entry-content .alignleft,
  .entry-content .alignright {
    float: none;
    display: block;
    margin: 0;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
  }
}

.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.u-bg-primary {
  background-color: #143558;
}

.u-bg-secondary-500 {
  background-color: #d7ab2d;
}

.u-bg-white {
  background-color: #fff;
}

.u-border-radius-lg {
  border-radius: 24px;
}

.u-clamp {
  --lines: 2;
  display: -webkit-box;
  -webkit-line-clamp: var(--lines);
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.u-clamp-6 {
  --lines: 6;
}

.u-clamp-4 {
  --lines: 4;
}

.u-clamp-3 {
  --lines: 3;
}

.u-clamp-1 {
  --lines: 1;
}

.u-color-primary {
  color: #143558;
}

.u-color-white {
  color: #fff;
}

.u-cursor-pointer {
  cursor: pointer;
}

.u-fz-xs {
  --size: 12px;
  font-size: var(--size);
  line-height: calc(var(--size) + 4px);
}

.u-fz-sm {
  --size: 14px;
  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-md {
  --size: 16px;
  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-lg {
  --size: 18px;
  font-size: var(--size);
  line-height: calc(var(--size) + 4px);
}

.u-fz-xl {
  --size: 20px;
  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fz-xxl {
  --size: 24px;
  font-size: var(--size);
  line-height: calc(var(--size) + 8px);
}

.u-fw-400 {
  font-weight: 400;
}

.u-fw-500 {
  font-weight: 500;
}

.u-fw-600 {
  font-weight: 600;
}

.u-fw-700 {
  font-weight: 700;
}

.u-h1,
.u-h2,
.u-h3,
.u-h4,
.u-h5,
.u-h6,
.u-h7 {
  font-size: var(--size);
  color: #143558;
  line-height: var(--line-height);
}

.u-h1,
.entry-content h1 {
  --size: 84px;
  --line-height: 102px;
}
@media (max-width: 767.98px) {
  .u-h1,
  .entry-content h1 {
    --size: 60px;
    --line-height: 84px;
  }
}

.u-h2,
.entry-content h2 {
  --size: 72px;
  --line-height: 88px;
}
@media (max-width: 767.98px) {
  .u-h2,
  .entry-content h2 {
    --size: 56px;
    --line-height: 72px;
  }
}

.u-h3,
.entry-content h3 {
  --size: 60px;
  --line-height: 73px;
}
@media (max-width: 767.98px) {
  .u-h3,
  .entry-content h3 {
    --size: 52px;
    --line-height: 60px;
  }
}

.u-h4,
.entry-content h4 {
  --size: 48px;
  --line-height: 58px;
}
@media (max-width: 767.98px) {
  .u-h4,
  .entry-content h4 {
    --size: 40px;
    --line-height: 48px;
  }
}

.u-h5,
.entry-content h5 {
  --size: 36px;
  --line-height: 44px;
}
@media (max-width: 767.98px) {
  .u-h5,
  .entry-content h5 {
    --size: 32px;
    --line-height: 36px;
  }
}

.u-h6,
.entry-content h6 {
  --size: 30px;
  --line-height: 41px;
}
@media (max-width: 767.98px) {
  .u-h6,
  .entry-content h6 {
    --size: 28px;
    --line-height: 37px;
  }
}

.u-h7 {
  --size: 24px;
  --line-height: 32px;
}
@media (max-width: 767.98px) {
  .u-h7 {
    --size: 18px;
    --line-height: 24px;
  }
}

.u-img-hover {
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}
.u-img-hover img {
  transition-property: opacity, transform;
  will-change: transform, opacity;
}
.u-img-hover:hover img {
  transform: scale(1.03);
}

.--loading {
  position: relative;
  pointer-events: none;
}
.--loading::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #e4e8ec;
  z-index: 9000;
  transition: opacity 0.3s ease;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
.--loading::after {
  content: "";
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  left: 50%;
  top: 50%;
  margin-top: -15px;
  margin-left: -15px;
  transform: translateX(-50%) translateY(-50%);
  border: 4px solid #0f2944;
  border-bottom-color: #143558;
  border-radius: 50%;
  animation: rotation 1s ease infinite;
  animation-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  z-index: 9001;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.u-scrollbar .os-scrollbar {
  --os-handle-bg: #0b1d30;
  --os-handle-bg-hover: #0f2944;
  --os-handle-bg-active: #143558;
  --os-handle-border-radius: 4px;
  --os-size: 8px;
  --os-track-bg: #e4e8ec;
  --os-track-bg-hover: #e4e8ec;
  --os-track-bg-active: #e4e8ec;
  cursor: grab;
}
.u-scrollbar .os-scrollbar:active {
  cursor: grabbing;
}
.u-scrollbar .os-theme-dark.os-scrollbar-vertical {
  padding: 0;
}

.u-o-visible {
  overflow: visible !important;
}

.u-posr {
  position: relative;
}

.u-posa {
  position: absolute;
}

.u-fixed {
  position: fixed;
}

.u-sticky {
  position: sticky;
}

.u-top-50-p {
  top: 50%;
}

.u-left-50-p {
  left: 50%;
}

.u-top-0 {
  top: 0;
}

.u-left-0 {
  left: 0;
}

.u-right-0 {
  right: 0;
}

.u-translate-middle-x {
  transform: translateX(-50%);
}

.u-translate-middle-y {
  transform: translateY(-50%);
}

.u-translate-middle {
  transform: translateX(-50%) translateY(-50%);
}

.u-z-index-0 {
  z-index: 0;
}

.u-z-index-1 {
  z-index: 1;
}

.u-z-index-2 {
  z-index: 2;
}

.u-z-index-negative {
  z-index: -1;
}

.u-z-index-10 {
  z-index: 10;
}

.base-header {
  --spacing: 32px;
  margin-inline: auto;
  max-width: 928px;
  width: 100%;
  margin-bottom: var(--spacing);
}
.base-header.--left {
  align-items: flex-start;
  text-align: left;
  margin-inline: 0;
}
.base-header.--spacing-none {
  --spacing: 0;
}

.base-header__description {
  --font-family: Fraunces, serif;
  --font-style: italic;
  --font-weight: 200;
  --color: #7c8ea2;
  font-family: var(--font-family);
  font-style: var(--font-style);
  font-weight: var(--font-weight);
  color: var(--color);
}
.base-header__description p {
  margin-bottom: 0;
}

.base-heading {
  --font-weight: 700;
  font-weight: var(--font-weight);
}
.base-heading.--fw-400 {
  --font-weight: 400;
}

.base-section {
  --padding-y: 88px;
  padding-block: var(--padding-y);
}
@media (max-width: 575.98px) {
  .base-section {
    --padding-y: 44px;
  }
}
.base-section.--top-0 {
  padding-top: 0;
}
.base-section.--bottom-0 {
  padding-bottom: 0;
}
.base-section.--none {
  --padding-y: 0;
}

.btn {
  --direction-offset: 8px;
}
.btn.--size-xs {
  --direction-offset: 4px;
}
@media (hover: hover) {
  .btn.--animation-right:hover svg {
    transform: translateX(var(--direction-offset));
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media (hover: hover) {
  .btn.--animation-left:hover svg {
    transform: translateX(calc(-1 * var(--direction-offset)));
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
  }
}
@media (hover: hover) {
  .btn.--animation-zoom:hover svg {
    transform: scale(1.1);
  }
}
@media (hover: hover) {
  .btn.--animation-down:hover svg {
    transform: translateY(var(--direction-offset));
  }
}

.btn.--sm {
  --font-size: 14px;
  --padding-y: 12px;
  --padding-x: 24px;
  --min-height: 48px;
}
.btn.--xs {
  --font-size: 12px;
  --padding-y: 12px;
  --padding-x: 24px;
  --min-height: 40px;
  --svg-size: 16px;
}

.btn.--primary {
  --background-color: #cad2d9;
  --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #e4e8ec;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  transition: 0.3s ease;
  transition-property: background-color, box-shadow;
  will-change: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--primary:hover {
    --background-color: #e4e8ec;
    --shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1607843137), 0px 3px 20px #e4e8ec;
  }
}
.btn.--secondary {
  --background-color: #d7ab2d;
  --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #f0e1b7;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  transition: 0.3s ease;
  transition-property: background-color, box-shadow;
  will-change: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--secondary:hover {
    --background-color: #dfbd5b;
    --shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.1607843137), 0px 3px 20px #f0e1b7;
  }
}
.btn.--tertiary {
  --font-color: #fff;
  --background-color: #143558;
  --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #14355865;
  --border-radius: 44px;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  transition: 0.3s ease;
  transition-property: background-color, box-shadow;
  will-change: background-color, box-shadow;
}
@media (hover: hover) {
  .btn.--tertiary:hover {
    --background-color: #2d547e;
    --shadow: inset 0px -3px 0px #00000086, 0px 9px 16px #14355865;
  }
}
.btn.--outline {
  --font-color: #143558;
  --border-color: #143558;
  --background: #fff;
  background-color: var(--background);
  border: 1px solid var(--border-color);
  color: var(--font-color);
  will-change: border-color, background-color, color;
  transition: 0.3s ease;
  transition-property: border-color, background-color, color;
}
@media (hover: hover) {
  .btn.--outline:hover {
    --border-color: transparent;
    --background: #143558;
    --font-color: #fff;
  }
  .btn.--outline:hover path {
    fill: var(--font-color);
  }
}
.btn.--linkedin, .btn.--instagram, .btn.--facebook, .btn.--youtube {
  --background-color: #ff0200;
  --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #f0e1b7;
  background-color: var(--background-color);
  box-shadow: var(--shadow);
  transition: 0.3s ease;
  transition-property: background-color, box-shadow;
  will-change: background-color, box-shadow;
}
.btn.--linkedin .btn__icon::before, .btn.--instagram .btn__icon::before, .btn.--facebook .btn__icon::before, .btn.--youtube .btn__icon::before {
  background-color: #d7ab2d;
  content: "";
  width: calc(var(--min-height) + 1px);
  height: calc(var(--min-height) + 1px);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  transition-property: width, height;
  will-change: width, height;
}
@media (hover: hover) {
  .btn.--linkedin:hover, .btn.--instagram:hover, .btn.--facebook:hover, .btn.--youtube:hover {
    --background-color: #ff0200;
    --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #e6cfcf;
  }
  .btn.--linkedin:hover .btn__icon::before, .btn.--instagram:hover .btn__icon::before, .btn.--facebook:hover .btn__icon::before, .btn.--youtube:hover .btn__icon::before {
    content: "";
    width: 0;
    height: 0;
  }
}
.btn.--facebook {
  --background-color: #4267b2;
}
@media (hover: hover) {
  .btn.--facebook:hover {
    --background-color: #4267b2;
    --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #cfd6e5;
  }
}
.btn.--instagram {
  background-image: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}
@media (hover: hover) {
  .btn.--instagram:hover {
    --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #f0e1b7;
    background-image: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
  }
}
.btn.--linkedin {
  --background-color: #0072b1;
}
@media (hover: hover) {
  .btn.--linkedin:hover {
    --background-color: #0072b1;
    --shadow: inset 0px 0px 0px rgba(0, 0, 0, 0.1607843137), 0px 13px 20px #cfdde6;
  }
}
.btn.--play {
  --background-color: rgb(255 255 255 / 70%);
  --border-radius: 79px;
  --max-width: 374px;
  --font-size: 24px;
  --font-weight: 400;
  --padding-y: 16px;
  --padding-x: 16px;
  max-width: var(--max-width);
  position: absolute;
  bottom: 264px;
  left: 24px;
  transform: translate(-50%, 0);
  opacity: 1;
  background-color: var(--background-color);
  backdrop-filter: blur(12px);
}
@media (max-width: 1499.98px) {
  .btn.--play {
    bottom: 100px;
  }
}
@media (max-width: 1299.98px) {
  .btn.--play {
    bottom: 0;
    left: 50%;
  }
}
@media (max-width: 991.98px) {
  .btn.--play {
    --font-size: 18px;
    --max-width: 300px;
  }
}

.btn__icon .icon {
  --size: var(--svg-size);
}
.btn__icon svg {
  transition: transform 0.3s ease;
  will-change: transform;
}

.btn {
  --border-radius: 36px;
  --font-size: 18px;
  --font-weight: 700;
  --font-color: #143558;
  --font-family: Montserrat, verdana;
  --padding-y: 25px;
  --padding-x: 40px;
  --min-height: 72px;
  --svg-size: 20px;
  font-size: var(--font-size);
  font-family: var(--font-family);
  color: var(--font-color);
  font-weight: var(--font-weight);
  border-radius: var(--border-radius);
  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);
  min-height: var(--min-height);
  user-select: none;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
}
@media (max-width: 991.98px) {
  .btn {
    --min-height: 64px;
    --padding-y: 15px;
    --padding-x: 30px;
    --font-size: 16px;
  }
}
.btn:hover {
  text-decoration: none;
  color: var(--font-color);
}
.btn:focus, .btn:active {
  outline: 0;
  color: var(--font-color);
  text-decoration: none;
}
.btn path {
  fill: var(--font-color);
  transition: fill 0.3s ease;
}
.btn.--icon-before .btn__icon {
  order: -1;
}
.btn.--icon-only {
  --min-height: 48px;
  width: var(--min-height);
  height: var(--min-height);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.btn.--icon-only .btn__title {
  display: none;
}
@media (max-width: 575.98px) {
  .btn.--full-width-mobile {
    width: 100%;
  }
}

@media (min-width: 992px) {
  .content-w-aside__content {
    grid-column: 1/span 8;
  }
}

.content-w-aside__row {
  gap: 32px;
}
@media (min-width: 992px) {
  .content-w-aside__row {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(12, 1fr);
  }
}
@media (max-width: 991.98px) {
  .content-w-aside__row {
    display: flex;
    flex-direction: column;
  }
}
.content-w-aside__row > .content-w-aside__content:last-child {
  grid-column: 3/span 8;
}

@media (min-width: 992px) {
  .content-w-aside__aside {
    grid-column: span 4/-1;
  }
}
@media (min-width: 1500px) {
  .content-w-aside__aside {
    grid-column: span 3/-1;
  }
}

.f-checkbox__visual {
  width: var(--size);
  height: var(--size);
  border: 1px solid var(--border-color);
  background-color: #fff;
  transition: background-color 0.3s ease;
  border-radius: var(--radius);
}
.f-checkbox__visual svg {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}
.f-checkbox__visual::after {
  content: "";
  position: absolute;
  display: none;
  width: calc(var(--size) / 2);
  height: calc(var(--size) / 2);
  background-color: #fff;
  border-radius: calc(var(--size) / 2);
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
}

.f-checkbox__input {
  left: calc(var(--size) / 2);
  top: calc(var(--size) / 2);
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 0;
}
.f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-color: #05d686;
  background-color: var(--color);
}
.f-checkbox__input:checked ~ .f-checkbox__visual::after,
.f-checkbox__input:checked ~ .f-checkbox__visual svg {
  opacity: 1;
  transform: scale(1);
}

.f-checkbox__title {
  --color: #143558;
  color: var(--color);
}
.f-checkbox__title a {
  color: var(--color);
  font-weight: 700;
}

.f-checkbox {
  --color: #05d686;
  --border-color: #a78523;
  --radius: 4px;
  --size: 24px;
  cursor: pointer;
}
.f-checkbox.--radio {
  --radius: calc(var(--size) / 2);
}
.f-checkbox.--radio .f-checkbox__visual::after {
  display: block;
}
.f-checkbox.--disabled {
  --color: #cad2d9;
  --border-color: #cad2d9;
}
.f-checkbox.--disabled .f-checkbox__title {
  --color: #cad2d9;
  color: var(--color);
}
.f-checkbox.--disabled .f-checkbox__input:checked ~ .f-checkbox__visual {
  --border-color: #cad2d9;
}

.f-checkbox-select__header {
  padding: 7px 8px;
  min-height: 48px;
  border: 1px solid #e4e8ec;
  user-select: none;
  background-color: var(--header-background);
}

.f-checkbox-select__clear {
  display: none;
}
.f-checkbox-select__clear.--active {
  display: inline-flex;
}

.f-checkbox-select__toggler {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.f-checkbox-select__toggler svg {
  width: 14px;
  height: 14px;
  transition: scale 0.3s ease;
}
.f-checkbox-select__toggler:hover {
  background-color: #e4e8ec;
}

.f-checkbox-select__label {
  --end: 0;
  overflow: hidden;
  flex: 1;
  top: 0;
  left: 0;
  height: 32px;
}
.f-checkbox-select__label > span {
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: left 2s linear;
}
.f-checkbox-select__label::after {
  content: "";
  pointer-events: none;
  position: absolute;
  display: block;
  right: 0;
  top: 0;
  width: 16px;
  height: 100%;
  background: transparent linear-gradient(270deg, var(--header-background) 0%, rgba(255, 255, 255, 0) 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
  z-index: 1;
}
.f-checkbox-select__label:hover > span {
  left: var(--end);
}

.f-checkbox-select__body {
  border: 1px solid #e4e8ec;
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%) scaleY(0.25);
  transform-origin: top;
  width: calc(100% - 1px);
  background-color: #fff;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.f-checkbox-select__body-inner {
  max-height: 160px;
}

.f-checkbox-select {
  --header-background: #fff;
  min-width: 0;
}
.f-checkbox-select.--active {
  --header-background: #e4e8ec;
}
.f-checkbox-select.--active .f-checkbox-select__body {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(-50%) scaleY(1);
}
.f-checkbox-select.--active .f-checkbox-select__toggler svg {
  scale: -1;
}

.f-date__icon {
  display: var(--icon-display);
  padding: 8px;
  position: absolute;
  pointer-events: none;
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-date__input {
  border-radius: var(--radius);
  cursor: var(--cursor);
}
.f-date__input::-webkit-inner-spin-button, .f-date__input::-webkit-clear-button, .f-date__input::-webkit-calendar-picker-indicator {
  display: none;
  appearance: none;
}

.f-date {
  --radius: 30px;
  --icon-display: block;
  --cursor: pointer;
}
.f-date:not(.--native) .f-date__input {
  padding-right: 48px;
}
.f-date.--native {
  --icon-display: none;
  --cursor: auto;
}
.f-date.--invalid {
  --border-color: #e52661;
}

.f-date__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color 0.3s ease;
}

.f-error {
  --display: none;
  position: absolute;
  top: 100%;
  left: 0;
  color: #e52661;
  width: 100%;
  display: var(--display);
  z-index: 2;
}
.f-error.--show {
  --display: flex;
}

.f-error__icon {
  height: 22px;
}

.f-base {
  display: block;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  padding: 10px 16px;
  appearance: none;
  border: none;
  border-radius: 30px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1607843137);
  min-height: 46px;
}
.f-base:focus {
  border-color: none;
  outline: none;
}
.f-base::placeholder {
  opacity: 1;
  color: #143558;
}

textarea.f-base {
  min-height: 200px;
  resize: none;
}

.i-selected-file {
  background-color: #e4e8ec;
  min-height: 24px;
  cursor: pointer;
}

.i-selected-file__remove {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.i-selected-file__remove svg {
  width: 100%;
  height: 100%;
}

.f-file.--empty .f-file__files {
  display: none;
}

.f-file__input {
  display: none;
}

.f-label.--required::after {
  content: "*";
  color: #e52661;
  padding-left: 4px;
}

.f-single-select__select.slim-select.ss-main {
  padding-inline: var(--padding-inline);
}
.f-single-select__select.slim-select.ss-main:focus {
  box-shadow: none;
}
.f-single-select__select.slim-select.ss-values.ss-single {
  margin: 0;
}
.f-single-select__select.slim-select .ss-list .ss-option {
  padding-inline: 16px;
}

.f-single-select,
.f-single-select__select.slim-select {
  --radius: 30px;
  --height: 48px;
  --padding-inline: 16px;
  --border-color: #a78523;
  --ss-primary-color: #143558;
  --ss-bg-color: #fff;
  --ss-font-color: #081828;
  --ss-font-placeholder-color: #e4e8ec;
  --ss-disabled-color: #dcdee2;
  --ss-border-color: var(--border-color);
  --ss-highlight-color: #fffb8c;
  --ss-success-color: #00b755;
  --ss-error-color: #dc3545;
  --ss-main-height: var(--height);
  --ss-content-height: 300px;
  --ss-spacing-l: 7px;
  --ss-spacing-m: 5px;
  --ss-spacing-s: 3px;
  --ss-animation-timing: 0.2s;
  --ss-border-radius: var(--radius);
}
.f-single-select.--native .f-single-select__wrap,
.f-single-select__select.slim-select.--native .f-single-select__wrap {
  display: grid;
  align-items: center;
  grid-template-areas: "select";
}
.f-single-select.--native .f-single-select__wrap::after,
.f-single-select__select.slim-select.--native .f-single-select__wrap::after {
  display: block;
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("../images/svg/chevron-down.svg");
  background-repeat: no-repeat;
  background-size: contain;
  justify-self: end;
  grid-area: select;
  margin-right: 16px;
  pointer-events: none;
}
.f-single-select.--native .f-single-select__select,
.f-single-select__select.slim-select.--native .f-single-select__select {
  border-radius: var(--radius);
  width: 100%;
  min-height: var(--height);
  border: 2px solid var(--border-color);
  background-color: #fff;
  padding-left: 16px;
  cursor: pointer;
  outline: none;
  margin: 0;
  appearance: none;
  grid-area: select;
}
.f-single-select.--invalid,
.f-single-select__select.slim-select.--invalid {
  --border-color: #e52661;
}
.f-single-select.--invalid .ss-main,
.f-single-select__select.slim-select.--invalid .ss-main {
  --border-color: #e52661;
}

.f-switch {
  --width: 48px;
  --height: 24px;
  --offset: 4px;
  --background: #48617d;
  --background-checked: #143558;
  --transition-timing: 0.2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height) - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #fff;
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width) - var(--handle-size) - var(--offset));
}
.f-switch.--disabled {
  --background: #b0bbc7;
  --background-checked: #b0bbc7;
  --handle-background: #e4e8ec;
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;
}
.f-switch__visual::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%) translateX(var(--handle-translate-x));
  width: var(--handle-size);
  height: var(--handle-size);
  border-radius: var(--handle-radius);
  background-color: var(--handle-background);
  z-index: 2;
  transition: transform var(--transition-timing) ease;
  will-change: transform;
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;
}
.f-switch__input:checked ~ .f-switch__visual {
  --background: var(--background-checked);
  --handle-translate-x: var(--handle-translate-x-checked);
}

.f-text__icon {
  padding: 8px;
  position: absolute;
  pointer-events: none;
}
.f-text__icon.--before {
  padding-left: 16px;
  left: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}
.f-text__icon.--after {
  padding-right: 16px;
  right: 0;
  top: 50%;
  transform: translateX(0) translateY(-50%);
}

.f-text__input {
  border-radius: var(--radius);
}

.f-text {
  --radius: 30px;
}
.f-text.--invalid {
  --border-color: #e52661;
}
.f-text.--icon-before .f-text__input {
  padding-left: 48px;
}
.f-text.--icon-after .f-text__input {
  padding-right: 48px;
}

.f-text__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  transition: border-color 0.3s ease;
}

.f-text__toggle {
  --on-opacity: 0;
  --off-opacity: 1;
  --size: 24px;
  right: 16px;
  width: var(--size);
  height: var(--size);
}
.f-text__toggle.--active {
  --on-opacity: 1;
  --off-opacity: 0;
}

.f-text__toggle-icon {
  grid-column-start: 1;
  grid-row-start: 1;
  width: var(--size);
  height: var(--size);
}
.f-text__toggle-icon.--on {
  opacity: var(--on-opacity);
}
.f-text__toggle-icon.--off {
  opacity: var(--off-opacity);
}

.f-textarea {
  --radius: 30px;
}
.f-textarea.--invalid {
  --border-color: #e52661;
}

.f-textarea__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
}

.f-contact__grid {
  display: grid;
}
@media (min-width: 768px) {
  .f-contact__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 575.98px) {
  .f-contact__footer {
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .f-contact__submit {
    margin-left: auto;
  }
}

.f-newsletter {
  background-color: #e4e8ec;
  border-radius: 16px;
}

@media (min-width: 992px) {
  .f-newsletter__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-newsletter__header {
  gap: 4px;
}

@media (max-width: 575.98px) {
  .f-newsletter__inputs-col {
    flex-direction: column;
  }
}

@media (max-width: 767.98px) {
  .f-newsletter__submit {
    width: 100%;
  }
}

.g-base {
  display: flex;
  flex-wrap: wrap;
}
.g-base.--gap-xs {
  gap: 4px;
}
.g-base.--gap-sm {
  gap: 8px;
}
.g-base.--gap-md {
  gap: 16px;
}
.g-base.--gap-lg {
  gap: 24px;
}
.g-base.--gap-xl {
  gap: 32px;
}
.g-base.--align-left {
  justify-content: flex-start;
}
.g-base.--align-right {
  justify-content: flex-end;
}
.g-base.--align-center {
  justify-content: center;
}
.g-base.--align-between {
  justify-content: space-between;
}

.g-buttons {
  --spacing: 48px;
  margin-top: var(--spacing);
}
.g-buttons.--spacing-none {
  --spacing: 0;
}

.g-consultant-stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 100px 100px;
  row-gap: 98px;
  column-gap: 49px;
}
@media (max-width: 1299.98px) {
  .g-consultant-stats {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }
}

@media (min-width: 1300px) {
  .g-events {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.g-hero-slider__button {
  --offset: 50%;
  pointer-events: auto;
}
.g-hero-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}
.g-hero-slider__button.--next {
  transform: translateX(var(--offset));
}
@media (max-width: 575.98px) {
  .g-hero-slider__button {
    --offset: 8px;
  }
}

.g-hero-slider__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  min-height: 8px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
}
.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet {
  --swiper-pagination-bullet-border-radius: 50%;
  transition: width 0.3s ease;
  transform-origin: center;
}
.g-hero-slider__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 32px;
  --swiper-pagination-color: #d7ab2d;
  --swiper-pagination-bullet-border-radius: 4px;
}

.g-hero-slider .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  width: 100% !important;
}

.g-hero-slider__buttons {
  pointer-events: none;
}

.g-jobs {
  width: 100%;
  max-width: 928px;
  margin: 0 auto;
  gap: 80px;
}
.g-jobs.--card {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  max-width: unset;
  gap: 32px;
}

.g-persons {
  display: grid;
}
@media (min-width: 768px) {
  .g-persons {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1300px) {
  .g-persons {
    grid-template-columns: repeat(4, 1fr);
  }
}

.g-posts-grid {
  display: grid;
  gap: 32px;
  row-gap: 72px;
}
@media (min-width: 992px) {
  .g-posts-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

.g-posts-grid__cell:first-of-type {
  grid-column: 1/-1;
}

.g-posts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  row-gap: 56px;
}
@media (max-width: 991.98px) {
  .g-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 767.98px) {
  .g-posts {
    display: flex;
    flex-direction: column;
  }
}
.g-posts.--compact {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 767.98px) {
  .g-posts.--compact {
    display: flex;
    flex-direction: column;
  }
}
.g-posts.--inline {
  display: flex;
  flex-direction: column;
}

.g-pricetable {
  overflow-x: auto;
}

.g-pricetable__row:nth-child(odd) {
  background-color: #e4e8ec;
}

.g-pricetable__cell .icon {
  margin: 0 auto;
}

.g-reviews-slider__button {
  --offset: 50%;
  pointer-events: auto;
}
.g-reviews-slider__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}
.g-reviews-slider__button.--next {
  transform: translateX(var(--offset));
}
@media (max-width: 767.98px) {
  .g-reviews-slider__button {
    --offset: calc(50% + 4px);
  }
}
@media (max-width: 575.98px) {
  .g-reviews-slider__button {
    --offset: calc(50% - 4px);
  }
  .g-reviews-slider__button.--icon-only {
    --min-height: 38px;
  }
}

.g-reviews-slider.swiper {
  min-height: 730px;
  display: flex;
  align-items: center;
  overflow: initial;
}
.g-reviews-slider .swiper-wrapper {
  display: flex;
  align-items: center;
}
.g-reviews-slider .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
}

.g-reviews-slider__buttons {
  pointer-events: none;
}
.g-reviews-slider__buttons .swiper-button-disabled {
  opacity: 0;
  pointer-events: none;
}

@media (min-width: 768px) and (max-width: 1499.98px) {
  .g-signpost.--cols-4 {
    max-width: 718px;
    margin-inline: auto;
  }
}
@media (max-width: 767.98px) {
  .g-signpost.--cols-4 {
    max-width: 360px;
    margin-inline: auto;
  }
}
@media (max-width: 767.98px) {
  .g-signpost.--cols-3 {
    max-width: 448px;
    margin-inline: auto;
  }
}

.g-steps {
  row-gap: 72px;
}
.g-steps.--vertical {
  max-width: 686px;
  margin-inline: auto;
}

.g-testimonials__button {
  --offset: 50%;
  pointer-events: auto;
}
.g-testimonials__button.--prev {
  transform: translateX(calc(-1 * var(--offset)));
}
.g-testimonials__button.--next {
  transform: translateX(var(--offset));
}
@media (max-width: 575.98px) {
  .g-testimonials__button {
    --offset: 8px;
  }
}

.g-testimonials__pagination.swiper-pagination {
  --swiper-pagination-bullet-horizontal-gap: 0;
  --swiper-pagination-bullet-border-radius: 0;
  min-height: 8px;
  position: static;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  gap: 8px;
}
.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet {
  transition: width 0.3s ease;
  transform-origin: center;
}
.g-testimonials__pagination.swiper-pagination .swiper-pagination-bullet-active {
  --swiper-pagination-bullet-width: 32px;
}

.g-testimonials .swiper-slide {
  flex-shrink: 0;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  width: fit-content;
}
.g-testimonials .swiper-wrapper {
  align-items: center;
}

.g-testimonials__buttons {
  pointer-events: none;
}

@media (min-width: 1500px) {
  .g-usps__cell.col-xxl-2 {
    width: 20%;
  }
}

.g-videos {
  gap: 154px;
}
@media (max-width: 1739.98px) {
  .g-videos {
    gap: 143px;
  }
}
@media (max-width: 1499.98px) {
  .g-videos {
    gap: 161px;
  }
}
@media (max-width: 1299.98px) {
  .g-videos {
    flex-direction: column;
    gap: 154px;
  }
}

.g-videos__large-img .i-media .img.--cover {
  min-height: 538px;
  max-height: 538px;
}
@media (max-width: 1299.98px) {
  .g-videos__large-img .i-media .img.--cover {
    min-height: auto;
  }
}
@media (min-width: 1300px) {
  .g-videos__large-img .i-media .img.--cover {
    max-width: 655px;
  }
}
@media (min-width: 1500px) {
  .g-videos__large-img .i-media .img.--cover {
    min-width: 807px;
    max-width: 807px;
  }
}
@media (min-width: 1740px) {
  .g-videos__large-img .i-media .img.--cover {
    min-width: 950px;
    max-width: 950px;
  }
}
.g-videos__large-img::after {
  content: "";
  position: absolute;
  top: 0;
  right: -77px;
  transform: translate(50%, 0);
  width: 1px;
  height: 100%;
  background-color: #7c8793;
  opacity: 0.26;
}
@media (max-width: 1739.98px) {
  .g-videos__large-img::after {
    right: -71.5px;
  }
}
@media (max-width: 1499.98px) {
  .g-videos__large-img::after {
    right: -80.5px;
  }
}
@media (max-width: 1299.98px) {
  .g-videos__large-img::after {
    height: 1px;
    width: 100%;
    top: calc(100% + 77px);
    right: 0;
    transform: translate(0, -50%);
  }
}

@media (max-width: 1299.98px) {
  .g-videos__list {
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -8px;
  }
}

.g-videos__item:nth-child(n+4) {
  display: none;
}
@media (max-width: 1299.98px) {
  .g-videos__item {
    width: 50%;
    max-width: 100%;
    padding: 0 8px;
  }
}
@media (max-width: 991.98px) {
  .g-videos__item {
    width: 100%;
    max-width: 100%;
    padding: 0 8px;
  }
}

.g-videos-more {
  margin: 0 -8px;
  margin-top: 64px;
}
@media (max-width: 1299.98px) {
  .g-videos-more {
    margin-top: 32px;
  }
}
.g-videos-more.--hidden {
  display: none;
}

.g-videos-more__item {
  padding: 0 8px;
}

.icon {
  --size: 20px;
  color: #081828;
  flex: 0 0 var(--size);
  max-width: var(--size);
  height: var(--size);
}
.icon svg {
  width: 100%;
  height: 100%;
}
.icon.--colored {
  color: var(--color);
}
.icon.--colored path {
  fill: var(--color);
}
.icon.--primary {
  --color: #143558;
}
.icon.--secondary {
  --color: #a78523;
}
.icon.--white {
  --color: #fff;
}
.icon.--black {
  --color: #081828;
}
.icon.--danger {
  --color: #e52661;
}
.icon.--success {
  --color: #05d686;
}
.icon.--grey {
  --color: #7c8793;
}
.icon.--xxxl {
  --size: 48px;
}
.icon.--xxl {
  --size: 40px;
}
.icon.--xl {
  --size: 32px;
}
.icon.--lg {
  --size: 24px;
}
.icon.--md {
  --size: 20px;
}
.icon.--sm {
  --size: 16px;
}
.icon.--xs {
  --size: 10px;
}

.img {
  overflow: hidden;
}
.img.--ratio-4-3 {
  aspect-ratio: 4/3;
}
.img.--ratio-16-9 {
  aspect-ratio: 16/9;
}
.img.--ratio-3-2 {
  aspect-ratio: 3/2;
}
.img.--ratio-1-1 {
  aspect-ratio: 1/1;
}
.img.--ratio-3-4 {
  aspect-ratio: 3/4;
}
.img.--contain {
  width: 100%;
  height: 100%;
}
.img.--contain img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}
.img.--cover {
  width: 100%;
  height: 100%;
}
.img.--cover img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.img.--placeholder {
  position: relative;
}
.img.--placeholder::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background-color: #e4e8ec;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: -1;
}

.i-author__description p {
  margin-bottom: 0;
}

.i-author {
  display: flex;
}
.i-author.--compact {
  align-items: center;
}
.i-author.--compact .i-author__description {
  display: none;
}
@media (max-width: 575.98px) {
  .i-author {
    flex-direction: column;
  }
}

.i-author__image {
  --size: 64px;
  width: var(--size);
  height: var(--size);
  max-width: var(--size);
}

.i-banner__header {
  max-width: 80ch;
}

.i-banner__image {
  width: 100%;
  height: 100%;
  z-index: -1;
}

.i-banner__description {
  line-height: 24px;
}

.i-banner {
  background-color: #e4e8ec;
}
@media (max-width: 575.98px) {
  .i-banner {
    padding: 32px;
  }
}
.i-banner.--image {
  background-color: transparent;
}
@media (min-width: 768px) {
  .i-banner.--image .i-banner__inner {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
}
.i-banner.--image .i-banner__description {
  display: none;
}
.i-banner.--image .i-banner__buttons {
  margin-top: 0;
}

.i-breadcrumb {
  text-wrap: nowrap;
}
.i-breadcrumb .i-breadcrumb__link {
  --font-size: 14px;
}
.i-breadcrumb .i-breadcrumb__divider {
  margin-right: 16px;
}
.i-breadcrumb.--homepage .i-breadcrumb__link {
  gap: 0;
}
.i-breadcrumb.--homepage .i-breadcrumb__divider {
  display: none;
}
.i-breadcrumb.--current {
  font-size: 14px;
  color: #7c8793;
}

.i-consultant-stats {
  position: relative;
  padding-left: 48px;
  min-height: 99px;
}
.i-consultant-stats::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 3px;
  height: 100%;
  border-radius: 4px;
  background-color: #d7ab2d;
}
.i-consultant-stats .i-consultant-stats__title {
  font-family: "Fraunces", serif;
  word-wrap: break-word;
  word-break: break-all;
  line-height: 88px;
}

.i-document {
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  color: #e4e8ec;
}

.i-document__title {
  color: #143558;
}

.i-document__details {
  color: #143558;
}

@media (max-width: 767.98px) {
  .i-event {
    flex-direction: column;
    max-width: 320px;
    margin-inline: auto;
  }
}

@media (min-width: 768px) {
  .i-event__image-link {
    width: 280px;
    height: 210px;
  }
}

.i-faq__body {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.5s ease;
  background-color: #e4e8ec;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.i-faq__body-inner {
  overflow: hidden;
  position: relative;
}
.i-faq__body-inner::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #0b1d30;
}

.i-faq__header {
  cursor: pointer;
}

.i-faq {
  --border-radius: 6px;
  border: 1px solid #0b1d30;
  border-radius: var(--border-radius);
}
.i-faq svg {
  transition: transform 0.3s ease;
  will-change: transform;
}
.i-faq.--open .i-faq__toggler svg {
  transform: scale(-1);
}
.i-faq.--open .i-faq__body {
  grid-template-rows: 1fr;
}

.i-hero__body {
  max-width: 600px;
}
@media (max-width: 1299.98px) {
  .i-hero__body {
    position: static;
    transform: translate3d(0, 0, 0);
    left: auto;
    right: auto;
    max-width: 100%;
    gap: 24px;
  }
}
@media (max-width: 575.98px) {
  .i-hero__body {
    padding: 16px;
  }
}

.i-hero__content {
  max-width: 566px;
  width: 100%;
}

.i-hero {
  --offset: 64px;
}
.i-hero.--right .i-hero__body {
  right: var(--offset);
}
@media (min-width: 992px) {
  .i-hero.--center .i-hero__body {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
@media (min-width: 1300px) {
  .i-hero {
    margin-bottom: -88px;
  }
}
@media (min-width: 1500px) {
  .i-hero {
    margin-bottom: -224px;
  }
}
@media (max-width: 991.98px) {
  .i-hero {
    padding-top: 16px;
  }
}

.i-hero__image {
  position: relative;
  top: -210px;
  right: -164px;
  margin-left: auto;
  width: 964px;
  height: 1170px;
  transition: 0.3s ease;
  transition-property: width, height;
}
@media (max-width: 1499.98px) {
  .i-hero__image {
    width: 760px;
    height: 912px;
    top: -148px;
    right: -132px;
  }
}
@media (max-width: 1299.98px) {
  .i-hero__image {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    height: auto;
    top: -120px;
    right: 0;
  }
}
@media (max-width: 767.98px) {
  .i-hero__image {
    margin-bottom: -40px;
  }
}

.i-hero__share {
  margin-top: 58px;
  margin-bottom: 77px;
}
.i-hero__share .link {
  --font-size: 24px;
  gap: 30px;
}
@media (max-width: 575.98px) {
  .i-hero__share .link {
    gap: 16px;
  }
}
.i-hero__share .link.--underline-initial-dynamic .link__title {
  position: relative;
}
.i-hero__share .link.--underline-initial-dynamic .link__title::after {
  bottom: -8px;
}

.i-hero__play {
  --min-width: 110px;
}
.i-hero__play .btn__icon {
  min-width: var(--min-width);
  height: var(--min-width);
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 13px 26px rgba(0, 0, 0, 0.1607843137);
}
.i-hero__play .btn__icon .icon {
  margin-left: 8px;
}
@media (max-width: 991.98px) {
  .i-hero__play .btn__icon {
    --min-width: 90px;
  }
  .i-hero__play .btn__icon .icon {
    --size: 40px;
  }
}
@media (max-width: 575.98px) {
  .i-hero__play .btn__icon {
    --min-width: 64px;
  }
  .i-hero__play .btn__icon .icon {
    --size: 32px;
  }
}

.i-hero__youtube .btn__icon {
  margin-left: 4px;
}

.i-hero-slide__body {
  --radius: 8px;
  max-width: 568px;
  border-radius: var(--radius);
  backdrop-filter: blur(20px);
}
.i-hero-slide__body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: -1;
  opacity: 0.4;
  border-radius: var(--radius);
}
@media (max-width: 991.98px) {
  .i-hero-slide__body {
    position: static;
    transform: translate3d(0, 0, 0);
    left: auto;
    right: auto;
    max-width: 100%;
    gap: 8px;
  }
}
@media (max-width: 575.98px) {
  .i-hero-slide__body {
    padding: 16px;
  }
}

.i-hero-slide {
  --offset: 64px;
}
.i-hero-slide.--left .i-hero-slide__body {
  left: var(--offset);
}
.i-hero-slide.--right .i-hero-slide__body {
  right: var(--offset);
}
@media (min-width: 992px) {
  .i-hero-slide.--center .i-hero-slide__body {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}
@media (max-width: 991.98px) {
  .i-hero-slide {
    padding: 16px;
  }
}

@media (max-width: 991.98px) {
  .i-hero-slide__image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 16px;
  overflow: hidden;
}

.i-job__info {
  color: #143558;
  row-gap: 8px;
}
@media (min-width: 768px) {
  .i-job__info {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 144px;
  flex-direction: column;
}
@media (min-width: 768px) {
  .i-job {
    flex-direction: row;
  }
}
.i-job.--card {
  --image-size: 448px;
  flex-direction: column;
  width: 416px;
}
.i-job.--card .i-job__image {
  height: auto;
  max-width: 100%;
}
.i-job.--card .i-job__info {
  gap: 16px;
  flex-direction: column;
}
.i-job.--card .i-job__footer {
  flex-direction: column;
  align-items: flex-start;
}

.i-media.--size-sm {
  width: min(100%, 155px);
  height: 155px;
  flex-shrink: 0;
}
.i-media.--size-sm .i-media__play-icon {
  display: none;
}
.i-media.--size-sm .i-media__time {
  right: 8px;
  bottom: 12px;
}
@media (width <= 469px) {
  .i-media.--size-sm {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}

.i-media {
  --border-radius: 23px;
  border-radius: var(--border-radius);
  box-shadow: 0 13px 16px #e4e8ec;
}
.i-media::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: transparent;
  transition: 0.3s ease;
  transition-property: background-color, mix-blend-mode;
  will-change: background-color, mix-blend-mode;
}
@media (hover: hover) {
  .i-media:hover::before {
    content: "";
    background-color: #dfbd5b;
    mix-blend-mode: multiply;
    z-index: 1;
  }
  .i-media:hover .i-media__play-icon {
    opacity: 1;
  }
}
.i-media .img {
  border-radius: var(--border-radius);
}
.i-media .i-media__time {
  bottom: 35px;
  right: 50px;
  flex-shrink: 0;
  padding: 8px 20px;
  background-color: #020509;
  color: #fff;
  border-radius: var(--border-radius);
  opacity: 0.75;
  z-index: 2;
}
@media (max-width: 991.98px) {
  .i-media .i-media__time {
    bottom: 25px;
    right: 30px;
  }
}
@media (max-width: 575.98px) {
  .i-media .i-media__time {
    padding: 8px 15px;
    bottom: 12px;
    right: 8px;
  }
}
.i-media .i-media__play-icon {
  --min-width: 149px;
  min-width: var(--min-width);
  height: var(--min-width);
  background-color: #06111d;
  border-radius: 50%;
  box-shadow: 0 13px 16px rgba(0, 0, 0, 0.4666666667);
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.i-media .i-media__play-icon .icon {
  --size: 64px;
  margin-left: 8px;
}
@media (max-width: 991.98px) {
  .i-media .i-media__play-icon {
    --min-width: 119px;
  }
  .i-media .i-media__play-icon .icon {
    --size: 54px;
  }
}
@media (max-width: 575.98px) {
  .i-media .i-media__play-icon {
    --min-width: 89px;
  }
  .i-media .i-media__play-icon .icon {
    --size: 32px;
    margin-left: 4px;
  }
}
.i-media.--video {
  cursor: pointer;
}

.i-menu__submenu-button {
  --size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  rotate: var(--submenu-button-rotate);
  margin-left: auto;
}
@media (max-width: 991.98px) {
  .i-menu__submenu-button {
    --size: 40px;
    transition: scale 0.3s ease;
    will-change: scale;
    scale: var(--submenu-button-scale);
    position: absolute;
    top: 0;
    right: 16px;
  }
  .i-menu__submenu-button:hover svg {
    transform: translateY(4px);
  }
}
.i-menu__submenu-button svg {
  transition: transform 0.3s ease;
}

.i-menu {
  --submenu-display: none;
  --submenu-button-scale: 1;
  --submenu-button-rotate: 0deg;
  --padding-left: 21px;
  --padding-right: 21px;
  --background: transparent;
  --background-hover: #cad2d9;
}
.i-menu.--open {
  --submenu-display: block;
  --submenu-button-scale: -1;
}
.i-menu > .i-menu__submenu {
  display: var(--submenu-display);
}

.i-menu__link {
  display: flex;
  font-size: 18px;
  gap: 8px;
  min-height: 40px;
  align-items: center;
  padding-block: 13px;
  padding-left: var(--padding-left);
  padding-right: var(--padding-right);
  border-radius: 24px;
  position: relative;
  text-decoration: none;
  background-color: var(--background);
  transition: background-color 0.3s ease;
  will-change: background-color;
}

@media (min-width: 992px) {
  .i-menu {
    --padding-left: 21px;
    --padding-right: 21px;
    --submenu-display: block;
    --opacity: 0;
    --translate-x: -50%;
    --translate-y: 8px;
    --translate-y-dot: var(--translate-y);
    --pointer-events: none;
  }
  .i-menu .i-menu__submenu {
    z-index: 50;
    width: 100%;
    min-width: 280px;
    padding: 16px 8px;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 13px 16px #e4e8ec;
    border-radius: 20px;
    border: 1px solid #e4e8ec;
    opacity: var(--opacity);
    pointer-events: var(--pointer-events);
    transform: translate(var(--translate-x), var(--translate-y));
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
    will-change: opacity, transform;
  }
  .i-menu .i-menu__submenu::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 16px;
    height: 16px;
    background-color: #d7ab2d;
    border-radius: 50%;
    opacity: var(--opacity);
    transform: translate(-50%, var(--translate-y-dot));
    transition: opacity 0.3s ease;
    transition-property: opacity, transform;
    will-change: opacity, transform;
  }
  .i-menu.--level-1 {
    position: relative;
  }
  .i-menu.--level-1 > .i-menu__submenu {
    top: 100%;
    left: 50%;
  }
  .i-menu.--level-1:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --translate-y-dot: -50%;
    --opacity: 1;
  }
  .i-menu.--level-2 {
    position: relative;
  }
  .i-menu.--level-2 > .i-menu__submenu {
    top: 0;
    left: 100%;
  }
  .i-menu.--level-2 > .i-menu__submenu.--mirror {
    left: auto;
    right: 100%;
  }
  .i-menu.--level-2 .i-menu__link:only-child:hover {
    --background: var(--background-hover);
  }
  .i-menu.--level-2:hover {
    --pointer-events: auto;
    --translate-y: 0;
    --translate-x: 0;
    --translate-y-dot: -50%;
    --opacity: 1;
  }
}
@media (max-width: 991.98px) {
  .i-menu.--level-2 .i-menu__link:only-child:hover {
    --background: var(--background-hover);
  }
}
.i-notification {
  min-height: 40px;
  background-color: #143558;
  color: #fff;
}
@media (max-width: 575.98px) {
  .i-notification {
    text-align: center;
  }
}

.i-notification__container {
  width: min(100% - 32px, 832px);
}
@media (max-width: 575.98px) {
  .i-notification__container {
    flex-direction: column;
    gap: 8px;
  }
}

@media (max-width: 575.98px) {
  .i-notification__icon {
    margin-inline: auto;
  }
}

@media (max-width: 575.98px) {
  .i-notification__close {
    order: -1;
  }
}

.i-person__image {
  overflow: hidden;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .i-post.--hero {
    display: grid;
    gap: 32px;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991.98px) {
  .i-post.--hero {
    max-width: 688px;
    margin-inline: auto;
  }
}
.i-post.--hero .i-post__tags {
  margin-top: 0;
}
@media (min-width: 992px) {
  .i-post.--hero .i-post__content-col {
    padding-block: 32px;
  }
}
.i-post.--hero .i-post__content {
  justify-content: center;
}

@media (min-width: 992px) {
  .i-post.--inline {
    display: grid;
    grid-template-columns: minmax(160px, 446px) auto;
    gap: 32px;
  }
  .i-post.--inline .i-post__content {
    max-width: 70ch;
  }
}

@media (max-width: 991.98px) {
  .i-post {
    max-width: 448px;
    margin-inline: auto;
  }
}

.i-priceplan {
  border: 1px solid #e4e8ec;
  border-radius: 24px;
}
.i-priceplan.--highlight {
  border: none;
  box-shadow: 0 8px 16px 2px hsla(233, 78%, 27%, 0.15);
}
@media (max-width: 991.98px) {
  .i-priceplan {
    max-width: 448px;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  width: calc(100% - 64px);
  background: #e4e8ec;
  border-radius: 8px;
  color: #0f2944;
  top: -16px;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 64px;
}

.i-quote__title {
  width: min(100%, 969px);
  font-family: "Fraunces", serif;
  margin-inline: auto;
  margin-block: 0;
  word-wrap: break-word;
  white-space: normal;
}

.i-quote__icon-top {
  --size: 82px;
  height: auto;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.i-quote__icon-bottom {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.i-reviews-slide {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 13px 36px #e4e8ec;
}

.i-reviews-slide__perex {
  color: #7c8793;
}

.i-step {
  --size: 144px;
}
.i-step::before {
  content: "";
  position: absolute;
  display: block;
  background-color: #e4e8ec;
}
@media (max-width: 575.98px) {
  .i-step::before {
    display: none;
  }
}
.i-step.--column {
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.i-step.--column::before {
  left: 50%;
  top: calc(var(--size) / 2);
  transform: translateX(-50%);
  height: 4px;
  width: calc(100% + 32px);
}
.i-step.--inline::before {
  top: 0;
  left: calc(var(--size) / 2);
  height: calc(100% + 72px);
  width: 4px;
}
@media (max-width: 575.98px) {
  .i-step.--inline {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

.i-step__icon {
  width: var(--size);
  height: var(--size);
  background-color: #e4e8ec;
  border-radius: 50%;
}

.i-step__count {
  bottom: 0;
  left: 0;
  font-size: 56px;
  color: #143558;
}
.i-step__count::after {
  content: ".";
}

.i-tag {
  --padding-x: 16px;
  --padding-y: 4px;
  --min-height: 32px;
  --font-size: 16px;
  padding-block: var(--padding-y);
  padding-inline: var(--padding-x);
  min-height: var(--min-height);
  font-size: var(--font-size);
}
.i-tag.--sm {
  --padding-x: 8px;
  --padding-y: 2px;
  --min-height: 24px;
  --font-size: 14px;
}
.i-tag.--lg {
  --padding-x: 24px;
  --padding-y: 4px;
  --min-height: 40px;
}

.i-tag {
  --font-color: #143558;
  --background-color: #e4e8ec;
  background-color: var(--background-color);
  color: var(--font-color);
  border-radius: 4px;
  border: 1px solid var(--background-color);
}
.i-tag.--outline {
  border-color: #0b1d30;
}
.i-tag.--blank {
  --background-color: transparent;
  padding: 0;
  min-height: auto;
}
.i-tag.--icon-before .i-tag__icon {
  order: -1;
}
.i-tag.--icon-only {
  width: var(--min-height);
  height: var(--min-height);
  min-height: auto;
  padding: 0;
}
.i-tag.--icon-only .i-tag__title {
  display: none;
}

a.i-tag,
.i-tag.--hoverable {
  text-decoration: none;
  transition: border-color 0.3s ease;
  cursor: pointer;
}
a.i-tag:hover,
.i-tag.--hoverable:hover {
  border-color: #143558;
}

a.i-tag.--blank {
  text-decoration: underline;
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
}
a.i-tag.--blank:hover {
  border-color: transparent;
  text-decoration-color: var(--font-color);
}

.i-testimonial__image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.i-testimonial__image img {
  height: 72px;
  max-width: 250px;
  object-fit: contain;
}

@media (min-width: 576px) {
  .i-usp.--inline {
    text-align: left;
    flex-direction: row;
    gap: 24px;
    align-items: flex-start;
  }
  .i-usp.--inline .i-usp__body {
    align-items: flex-start;
  }
}

.i-usp.--link {
  position: relative;
  transition: box-shadow 0.3s ease;
  will-change: box-shadow;
}
.i-usp.--link .i-usp__link-overlay {
  inset: 0;
}
@media (hover: hover) {
  .i-usp.--link:hover {
    box-shadow: 0 8px 32px 2px rgba(0, 0, 0, 0.1607843137);
  }
}

.i-usp {
  max-width: 314px;
  gap: 43px;
  padding: 24px;
  border-radius: 20px;
}

.i-usp__icon .icon {
  --size: 80px;
}

.i-usp__description {
  max-width: 266px;
}

@media (width <= 469px) {
  .i-video {
    flex-direction: column;
  }
}

.i-video__text-part {
  height: auto;
}
.i-video__text-part .base-heading {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.i-video__title-link.link {
  --font-size: 24px;
  font-weight: 700;
  word-break: break-word;
}
.i-video__title-link.link:hover {
  text-decoration-color: transparent;
}

.i-video__name {
  color: #a78523;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #a78523;
  text-decoration-thickness: 4px;
  text-underline-offset: 6px;
}

.i-video__date {
  color: #7c8793;
}

.c-language-select {
  --dropdown-opacity: 0;
  --dropdown-pointer-events: none;
}
.c-language-select:hover {
  --dropdown-opacity: 1;
  --dropdown-pointer-events: auto;
}

.c-language-select__drowpdown {
  background-color: #fff;
  transition: opacity 0.3s ease;
  top: 100%;
  left: 0;
  opacity: var(--dropdown-opacity);
  pointer-events: var(--dropdown-pointer-events);
  box-shadow: 0 8px 32px 2px rgba(0, 0, 0, 0.1607843137);
}

.c-language-select__active {
  min-height: 40px;
}

.c-language-select__dropdown-link {
  text-decoration: none;
  transition: background-color 0.3s ease;
}
.c-language-select__dropdown-link:hover {
  background-color: #e4e8ec;
}

.link.--animation-right svg {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.27, 1.55);
}
.link.--animation-right:hover svg {
  transform: translateX(8px);
}
.link.--animation-zoom:hover svg {
  transform: scale(1.1);
}

.link.--black {
  color: #081828;
  text-decoration-color: transparent;
}
.link.--black:hover, .link.--black:focus {
  color: #081828;
  text-decoration-color: #081828;
}
.link.--secondary {
  color: #a78523;
  text-decoration-color: transparent;
}
.link.--secondary:hover, .link.--secondary:focus {
  color: #a78523;
  text-decoration-color: #a78523;
}
.link.--primary-400 {
  color: #0f2944;
  text-decoration-color: transparent;
}
.link.--primary-400:hover, .link.--primary-400:focus {
  color: #0f2944;
  text-decoration-color: transparent;
}
.link.--grey {
  color: #7c8793;
  text-decoration-color: transparent;
}
.link.--grey:hover, .link.--grey:focus {
  color: #7c8793;
  text-decoration-color: transparent;
}

.link__icon svg {
  transition: transform 0.3s ease;
  will-change: transform;
}

.link {
  --font-size: 18px;
  --text-decoration-style: dotted;
  --text-decoration-thickness: 4px;
  --text-decoration-offset: 6px;
  width: fit-content;
  font-size: var(--font-size);
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  text-decoration-style: var(--text-decoration-style);
  text-decoration-color: transparent;
  text-decoration-thickness: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);
}
.link:hover {
  text-decoration-color: inherit;
}
.link.--icon-before .link__icon {
  order: -1;
}
.link.--underline-initial-dynamic {
  --underline-bg: #a78523;
  text-decoration: none;
}
.link.--underline-initial-dynamic .link__title {
  position: relative;
}
.link.--underline-initial-dynamic .link__title::after {
  content: "";
  height: 4px;
  display: block;
  border-bottom: 4px dotted var(--underline-bg);
  width: 19px;
  transition: width 0.3s ease;
}
.link.--underline-initial-dynamic:hover, .link.--underline-initial-dynamic:focus {
  text-decoration: none;
}
.link.--underline-initial-dynamic:hover .link__title::after, .link.--underline-initial-dynamic:focus .link__title::after {
  width: 100%;
}
.link.--underline-initial-static {
  text-decoration-color: inherit;
}
.link.--underline-style-solid {
  --text-decoration-style: solid;
}

.main-footer-column {
  color: #0f2944;
  font-size: 18px;
}
@media (max-width: 575.98px) {
  .main-footer-column {
    text-align: center;
    align-items: center;
  }
}

.main-footer-column__youtube .btn__icon {
  margin-left: 4px;
}

.main-footer .main-footer__top .container {
  position: relative;
}
.main-footer .main-footer__top .container::before {
  content: "";
  position: absolute;
  top: -40px;
  left: 26px;
  height: 1px;
  width: calc(100% - 52px);
  background-color: #b0bbc7;
}

@media (min-width: 576px) {
  .main-footer__columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1300px) {
  .main-footer__columns {
    grid-template-columns: repeat(12, 1fr);
  }
  .main-footer__columns .main-footer-column:first-child {
    grid-column: span 2;
  }
  .main-footer__columns .main-footer-column:nth-of-type(2) {
    grid-column: span 2;
  }
  .main-footer__columns .main-footer-column:nth-of-type(3) {
    grid-column: span 3;
    margin-left: 32px;
  }
  .main-footer__columns .main-footer-column:last-child {
    grid-column: span 5;
    justify-self: flex-end;
  }
}

@media (min-width: 576px) {
  .main-footer__bottom-columns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 1300px) {
  .main-footer__bottom-columns {
    grid-template-columns: repeat(12, 1fr);
  }
  .main-footer__bottom-columns .link {
    grid-column: span 2;
  }
  .main-footer__bottom-columns .main-footer__content {
    grid-column: span 10;
  }
}

.main-footer__bottom-edge {
  background-color: #0f2944;
  height: 40px;
  width: 100%;
}

.m-header-search {
  --translate-y: -150%;
  --pointer-events: none;
  --opacity: 0;
}
.m-header-search.--open {
  --translate-y: -50%;
  --pointer-events: auto;
  --opacity: 1;
}

.m-header-search__form {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  top: 50%;
  transform: translateY(var(--translate-y));
  pointer-events: var(--pointer-events);
  opacity: var(--opacity);
  transition: transform 0.3s ease;
  transition-property: opacity, transform;
  will-change: transform, opacity;
}

.m-header {
  --shadow-color: transparent;
  --min-height: 75px;
  --p-block: 8px;
  --logo-max-w: 106px;
  --bg-color: #fff;
  background-color: var(--bg-color);
  top: 0;
  left: 0;
  position: sticky;
  z-index: 5;
  width: 100%;
  transition: transform 0.5s ease;
}
.m-header.headroom--unpinned {
  transform: translateY(-100%);
}
.m-header.headroom--not-top {
  --logo-max-w: 96px;
}
@media (min-width: 1300px) and (max-width: 1499.98px) {
  .m-header.headroom--not-top {
    --logo-max-w: 80px;
  }
}
@media (min-width: 1300px) and (max-width: 1499.98px) {
  .m-header {
    --logo-max-w: 100px;
  }
}

.m-header__body {
  min-height: var(--min-height);
  padding-block: var(--p-block);
  transition: 0.3s ease;
  transition-property: padding, min-height;
  will-change: padding, min-height;
}
@media (max-width: 575.98px) {
  .m-header__body {
    --min-height: 80px;
    --p-block: 0;
    min-height: var(--min-height);
    padding-block: var(--p-block);
  }
}

.m-header-logo {
  max-width: var(--logo-max-w);
  transition: max-width 0.3s ease;
  will-change: max-width;
}

.m-nav {
  --transition-timing: 0;
}
@media (min-width: 992px) {
  .m-nav {
    margin-left: auto;
  }
}
@media (max-width: 991.98px) {
  .m-nav {
    position: fixed;
    right: 0;
    top: 0;
    background-color: #fff;
    box-shadow: 0 8px 32px 2px rgba(0, 0, 0, 0.1607843137);
    width: 100%;
    max-width: 420px;
    transform: translateX(100%);
    transition: transform var(--transition-timing) ease;
    z-index: 100;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .m-nav.--open {
    transform: translateX(0);
  }
  .m-nav.--transition {
    --transition-timing: 0.3s;
  }
}

@media (min-width: 1740px) {
  .m-nav__list {
    margin-right: 85px;
  }
}
@media (min-width: 992px) {
  .m-nav__list {
    flex-direction: row;
    align-items: start;
  }
}

.i-menu__link.--underline-initial-dynamic {
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 2px;
}
.i-menu__link.--underline-initial-dynamic::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(50%);
  height: 4px;
  display: block;
  border-bottom: 4px dotted #a78523;
  width: 19px;
  transition: width 0.3s ease;
}
.i-menu__link.--underline-initial-dynamic:hover::after, .i-menu__link.--underline-initial-dynamic:focus::after {
  width: 100%;
}
.i-menu__link.--underline-initial-dynamic .link__title::after {
  display: none;
}
.i-menu__link.--underline-initial-dynamic .link__icon {
  margin-left: -1.5px;
}
.i-menu__link.--underline-initial-dynamic .link__icon .icon.--colored path.uuid-089ecb80-19e4-441b-88cf-bc590cae2494 {
  fill: none;
}

.i-menu__customers {
  padding-bottom: 13px;
  padding-inline: 21px;
  padding-right: 0;
}
.i-menu__customers .i-menu__link {
  border-radius: 0;
}

.c-modal__dialog {
  position: relative;
  width: auto;
  pointer-events: none;
  margin: 2rem;
  margin-left: auto;
  margin-right: auto;
  cursor: cell;
}

.c-modal__content {
  position: relative;
  width: var(--width);
  max-height: calc(100vh - 64px);
  overflow: hidden;
  margin-inline: auto;
  pointer-events: auto;
  background-clip: padding-box;
  outline: 0;
  background-color: #fff;
  cursor: auto;
  box-shadow: 4px 4px 24px rgba(15, 38, 57, 0.1019607843);
  transform: translateY(32px);
  transition: transform var(--transtion-length) ease;
}

.c-modal__inner {
  max-height: calc(100vh - 64px);
  overflow: auto;
}

.c-modal__body {
  position: relative;
  flex: 1 1 auto;
  padding: 32px 24px;
}
@media (max-width: 767.98px) {
  .c-modal__body {
    padding: 16px;
  }
}

.c-modal__header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 32px 24px 0;
}

.c-modal__button-container {
  order: -1;
  display: flex;
  justify-content: flex-end;
}

.c-modal__close-cross {
  transform: translate(-16px, 16px);
}

.c-modal__footer {
  padding: 0 24px 32px;
}
.c-modal__footer.--right {
  justify-content: flex-end;
}
.c-modal__footer.--center {
  justify-content: center;
}
.c-modal__footer.--between {
  justify-content: space-between;
}

.c-modal__heading {
  font-size: 22px;
}

.c-modal {
  --transtion-length: 300ms;
  --width: min(100% - 32px, 720px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9000;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  outline: 0;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: opacity var(--transtion-length) ease;
}
.c-modal.--open {
  opacity: 1;
  pointer-events: all;
  visibility: visible;
}
.c-modal.--open .c-modal__content {
  transform: translateY(0);
}
.c-modal.--hidden {
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
}
.c-modal.--size-sm {
  --width: min(100% - 32px, 480px);
}
.c-modal.--size-xl {
  --width: min(100% - 32px, 1140px);
}

.c-overlay {
  --opacity: 0;
  --pointer-events: none;
  --position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #081828;
  opacity: var(--opacity);
  pointer-events: var(--pointer-events);
  transition: opacity 0.3s ease;
  position: var(--position);
}
.c-overlay.--show {
  --opacity: 0.75;
  --pointer-events: auto;
}
.c-overlay.--absolute {
  --position: absolute;
  width: 100%;
  height: 100%;
}

.link.c-pagination__link {
  --background: transparent;
  --size: 40px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--background);
  min-height: var(--size);
  transition-property: text-decoration-color, color, background-color;
}
.link.c-pagination__link:hover {
  --background: #e4e8ec;
}
.link.c-pagination__link.--page-number {
  padding: 8px;
  text-decoration: none;
  min-width: var(--size);
}
.link.c-pagination__link.--active {
  --background: #143558;
  pointer-events: none;
  color: #fff;
}
.link.c-pagination__link.--blank {
  pointer-events: none;
}
.link.c-pagination__link.--prev:hover, .link.c-pagination__link.--next:hover {
  --background: transparent;
}

@media (max-width: 767.98px) {
  .c-pagination__items {
    order: -1;
    flex-basis: 100%;
    justify-content: center;
  }
}

@media (max-width: 767.98px) {
  .c-pagination {
    justify-content: center;
  }
}

.c-scroll-up {
  --size: 40px;
  --offset: 40px;
  --radius: 20px;
  --background: #143558;
  --background-hover: #0f2944;
  --icon-size: 16px;
  --icon-color: #fff;
  width: var(--size);
  height: var(--size);
  bottom: var(--offset);
  right: var(--offset);
  border-radius: var(--radius);
  background-color: var(--background);
  z-index: 50;
  transition: background-color 0.3s ease, opacity 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55), transform 0.3s cubic-bezier(0.68, -0.55, 0.27, 1.55);
  will-change: opacity, transform, background-color;
  pointer-events: none;
  opacity: 0;
  transform: translateY(var(--offset)) scale(0.8);
  cursor: pointer;
}
.c-scroll-up:hover {
  --background: var(--background-hover);
}
.c-scroll-up::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 20px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease;
  transition-property: opacity, box-shadow;
  box-shadow: 0 0 10px 40px #fff;
}
.c-scroll-up:active::after {
  box-shadow: 0 0 0 0 #fff;
  position: absolute;
  opacity: 1;
  transition: 0s;
}
.c-scroll-up svg {
  width: var(--icon-size);
  height: var(--icon-size);
}
.c-scroll-up path {
  fill: var(--icon-color);
}
.c-scroll-up.--active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0) scale(1);
}

@media (max-width: 991.98px) {
  .s-breadcrumbs {
    display: none;
  }
}

.s-breadcrumbs__homepage {
  display: block;
}

.s-calendar__app {
  --fc-border-color: #e4e8ec;
  --fc-today-bg-color: #e4e8ec;
  --fc-event-bg-color: #143558;
  --fc-button-bg-color: #143558;
  --fc-button-border-color: #143558;
  --fc-button-active-bg-color: #0f2944;
  --fc-button-active-border-color: #0f2944;
  --fc-button-hover-bg-color: #0f2944;
  --fc-button-hover-border-color: #0f2944;
  height: 570px;
}
.s-calendar__app.fc {
  height: auto;
}
.s-calendar__app.--close {
  display: none;
}
.s-calendar__app .fc-daygrid {
  background-color: #fff;
}
.s-calendar__app .fc-event {
  border-radius: 0;
  border: none;
}
.s-calendar__app .fc-daygrid-day-number {
  text-decoration: none;
  color: #143558;
}
.s-calendar__app .fc-col-header-cell-cushion {
  text-decoration: none;
}
.s-calendar__app .fc-col-header-cell {
  padding: 8px;
}

.s-calendar__toggler.--close svg {
  transform: scale(-1);
}

.s-consultant-about__header {
  --spacing: 51px;
}

.s-consultant-about__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 0;
}
@media (min-width: 992px) {
  .s-consultant-about__wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .s-consultant-about__wrapper .s-consultant-about__content {
    max-width: 450px;
    width: 100%;
  }
}
@media (min-width: 1500px) {
  .s-consultant-about__wrapper .s-consultant-about__content {
    max-width: 540px;
    width: 100%;
  }
}

.s-consultant-meet__img.--contain {
  width: 570px;
  aspect-ratio: 1/0.982;
}
@media (max-width: 991.98px) {
  .s-consultant-meet__img {
    display: none;
  }
}

.s-consultant-meet__header .base-header__description {
  --font-family: Montserrat, verdana;
  --font-size: 24px;
  --font-weight: 400;
  --font-style: normal;
  --color: #143558;
  font-size: 24px;
  line-height: 37px;
  display: block;
  margin-top: 8px;
  max-width: 594px;
  width: 100%;
}

.s-consultant-meet__text-part {
  width: min(100%, 630px);
  margin-left: -16px;
}
@media (max-width: 991.98px) {
  .s-consultant-meet__text-part {
    margin: 0;
  }
}

.s-consultant-meet__button {
  --font-size: 24px;
  --border-radius: 41px;
  width: min(100%, 408px);
  gap: 20px;
}
@media (max-width: 991.98px) {
  .s-consultant-meet__button {
    --font-size: 16px;
  }
  .s-consultant-meet__button .icon.--lg {
    --size: 20px;
  }
}

.s-consultant-text-media {
  padding-top: 44px;
  padding-bottom: 0;
  position: relative;
}
@media (max-width: 1299.98px) {
  .s-consultant-text-media {
    padding-bottom: 88px;
  }
}
@media (max-width: 575.98px) {
  .s-consultant-text-media {
    padding-bottom: 44px;
  }
}

.s-consultant-video__header {
  --spacing: 69px;
}

.s-consultant-video__media {
  --width: 1280px;
  --max-height: 725px;
  width: min(100%, var(--width));
  max-height: var(--max-height);
  height: 100%;
  margin-inline: auto;
}
.s-consultant-video__media .img {
  width: min(100%, var(--width));
  max-height: var(--max-height);
  height: 100%;
}

.s-error-404__heading {
  --font-size: 176px;
  font-size: var(--font-size);
}
@media (max-width: 767.98px) {
  .s-error-404__heading {
    --font-size: 136px;
  }
}

.s-error-404__sub-heading {
  margin-inline: auto;
  margin-bottom: 24px;
  max-width: 720px;
}

.s-error-404__perex {
  max-width: 720px;
  text-align: center;
  margin-inline: auto;
  margin-bottom: 0;
}

.s-jobs__footer {
  background-color: #e4e8ec;
}
@media (min-width: 768px) {
  .s-jobs__footer {
    padding: 48px 64px;
  }
}

@media (max-width: 575.98px) {
  .s-post-detail__meta {
    flex-direction: column;
    align-items: start;
  }
}

@media (max-width: 575.98px) {
  .s-post-detail__share-links {
    flex-direction: column;
    align-items: normal;
    margin-left: 0;
  }
}

@media (max-width: 575.98px) {
  .s-post-detail__share {
    flex-direction: column;
  }
}

.s-quote {
  --padding-y: 176px;
}

.s-reviews-slider__ellipse-1.icon {
  --size: 730px;
  min-width: var(--size);
  z-index: -2;
  top: calc(50% + 13px);
  left: 50%;
  transform: translate(-50%, -50%);
}

.s-reviews-slider__ellipse-2.icon {
  --size: 492px;
  min-width: var(--size);
  z-index: -1;
}

.s-reviews-slider__testimonials {
  margin-top: 75px;
  margin-bottom: 50px;
}

.s-text-media__grid {
  display: flex;
  flex-direction: column;
}
@media (min-width: 992px) {
  .s-text-media__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-text-media__header-in {
  gap: 8px;
  margin-bottom: 24px;
}

.s-text-media.--reverse .s-text-media__col.--content-col {
  order: -1;
}

.s-usps__header {
  --spacing: 80px;
}

.s-videos__button {
  margin-top: 64px;
}
.s-videos__button.--hidden {
  display: none;
}

.s-videos__header {
  --spacing: 68px;
}

.widget_categories select {
  --border-color: #e4e8ec;
  --radius: 4px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  height: 48px;
  width: 100%;
  padding: 8px;
  margin: 0;
}

.wp-aside-widgets .cat-item {
  display: flex;
  align-items: center;
  padding-right: 8px;
}
.wp-aside-widgets .cat-item > a {
  width: 100%;
}

.widget_media_image a {
  display: block;
}

.widget_nav_menu .menu-item-has-children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}
.widget_nav_menu .menu-item-has-children .sub-menu {
  padding-left: 16px;
}
.widget_nav_menu .menu-item-has-children > .sub-menu {
  display: var(--submenu-display);
}
.widget_nav_menu .menu-item-has-children > a {
  padding-right: 48px;
}
.widget_nav_menu .menu-item-has-children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_pages .page_item.active {
  text-decoration: underline;
}
.widget_pages .page_item_has_children {
  --submenu-display: none;
  --svg-rotate: 0deg;
  position: relative;
}
.widget_pages .page_item_has_children .sub-menu {
  padding-left: 16px;
}
.widget_pages .page_item_has_children > .children {
  display: var(--submenu-display);
}
.widget_pages .page_item_has_children > a {
  padding-right: 48px;
}
.widget_pages .page_item_has_children.--open {
  --submenu-display: block;
  --svg-rotate: 180deg;
}

.widget_search form > div {
  display: flex;
  gap: 8px;
}
.widget_search input[type=text] {
  --border-color: #e4e8ec;
  --radius: 4px;
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
  display: block;
  width: 100%;
  background-clip: padding-box;
  appearance: none;
  min-height: 46px;
  padding-left: 8px;
}
.widget_search input[type=text]:focus {
  border-color: none;
  outline: none;
}
.widget_search input[type=submit] {
  --border-radius: 6px;
  --background: #143558;
  --font-color: #fff;
  color: var(--font-color);
  background-color: var(--background);
  border-radius: var(--border-radius);
  display: block;
  height: 100%;
  user-select: none;
  border: none;
  z-index: 1;
  text-decoration: none;
  touch-action: manipulation;
  padding-block: 8px;
  padding-inline: 16px;
  min-height: 46px;
  transition: background-color 0.3s ease;
}
.widget_search input[type=submit]:hover {
  text-decoration: none;
  color: var(--font-color);
}
.widget_search input[type=submit]:focus, .widget_search input[type=submit]:active {
  outline: 0;
  color: var(--font-color);
  text-decoration: none;
}
@media (hover: hover) {
  .widget_search input[type=submit]:hover {
    --background: #0f2944;
  }
}

.wp-aside-widgets ul {
  display: flex;
  flex-direction: column;
}

.wp-aside-widgets .widget {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
@media (max-width: 991.98px) {
  .wp-aside-widgets .widget {
    --content-display: none;
    --svg-rotate: 0deg;
  }
  .wp-aside-widgets .widget ul,
  .wp-aside-widgets .widget form {
    display: var(--content-display);
  }
  .wp-aside-widgets .widget.--open {
    --content-display: flex;
    --svg-rotate: 180deg;
  }
}

.wp-aside-widgets .widgettitle {
  --size: 24px;
  display: block;
  font-size: var(--size);
  font-weight: 700;
  position: relative;
}
@media (max-width: 767.98px) {
  .wp-aside-widgets .widgettitle {
    --size: 20px;
  }
}

.wp-aside-widgets .wp-caption {
  max-width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.wp-aside-widgets li {
  --color: #081828;
  display: block;
}
.wp-aside-widgets li > a {
  display: block;
  min-height: 40px;
  padding-block: 8px;
  padding-left: 8px;
  padding-right: 8px;
  color: var(--color);
  text-decoration-color: transparent;
  transition: text-decoration-color 0.3s ease;
}
.wp-aside-widgets li > a:hover {
  text-decoration-color: var(--color);
}

.widget__dropdown-btn {
  --size: 24px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}
.widget__dropdown-btn svg {
  rotate: var(--svg-rotate);
  transition: rotate 0.3s ease;
}
@media (min-width: 992px) {
  .widget__dropdown-btn {
    display: none;
  }
}

.wp-aside-widgets .sub-menu__button {
  --size: 40px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
}
.wp-aside-widgets .sub-menu__button svg {
  rotate: var(--svg-rotate);
  transition: rotate 0.3s ease;
}

.wp-breadcrumbs {
  --gap: 24px;
}

.wp-breadcrumbs__group > span {
  display: flex;
  gap: var(--gap);
}
@media (max-width: 767.98px) {
  .wp-breadcrumbs__group {
    display: none;
  }
}

.wp-breadcrumbs__group > span span {
  --height: 56px;
  min-height: var(--height);
  display: flex;
  align-items: center;
}
.wp-breadcrumbs__group > span span a {
  display: block;
}
.wp-breadcrumbs__group > span span:first-child a {
  display: inline-block;
  color: transparent;
  width: 24px;
  height: var(--height);
  position: relative;
  background-image: url("../images/svg/home.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  user-select: none;
}
.wp-breadcrumbs__group > span span.breadcrumb_last {
  font-weight: 600;
}
.wp-breadcrumbs__group > span span:not(:first-child) {
  position: relative;
}
.wp-breadcrumbs__group > span span:not(:first-child)::before {
  content: "";
  position: absolute;
  display: block;
  width: 4px;
  height: 4px;
  background-color: #143558;
  left: calc(var(--gap) / -2);
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.entry-content strong:empty,
.entry-content b:empty,
.entry-content p:empty {
  display: none;
}
.entry-content hr {
  border: none;
  background: #e4e8ec;
  height: 1px;
  margin: 48px 0;
}
.entry-content .wp-caption {
  border: 0;
  padding: 0;
  text-align: center;
  max-width: 100%;
}
.entry-content .wp-caption-text {
  font-size: 16px;
  color: #081828;
  margin-left: 0;
  transform: none;
  text-align: center;
  line-height: 24px;
  padding: 4px 10px;
  background: rgba(235, 235, 235, 0.39);
  margin-bottom: 0;
}

.entry-content blockquote {
  font-weight: 400;
  padding: 16px;
  margin-block: 32px;
  position: relative;
  font-style: italic;
  background-color: #e4e8ec;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.entry-content blockquote p {
  font-style: italic;
  margin-bottom: 0;
}

.entry-content h1,
.entry-content h2,
.entry-content h3,
.entry-content h4,
.entry-content h5,
.entry-content h6 {
  margin-top: 40px;
  font-weight: bold;
  margin-bottom: 16px;
}
@media (max-width: 575.98px) {
  .entry-content h1,
  .entry-content h2,
  .entry-content h3,
  .entry-content h4,
  .entry-content h5,
  .entry-content h6 {
    margin-bottom: 24px !important;
  }
}
.entry-content h1 {
  margin-top: 0;
  position: relative;
}
.entry-content h2 {
  position: relative;
}
.entry-content > h2:first-child, .entry-content > h3:first-child {
  margin-top: 0;
}

.entry-content > a {
  display: block;
}
.entry-content img {
  max-width: 100%;
  margin-block: 16px;
}

/* stylelint-disable no-descending-specificity */
.entry-content ol > li {
  position: relative;
  counter-increment: item;
  padding-left: 24px;
}
.entry-content ol > li::before {
  content: counter(item) ".";
  font-weight: 700;
  font-size: 18px;
  top: 1px;
  width: 28px;
  height: 28px;
  display: inline-flex;
  margin-right: 8px;
  color: #143558;
  margin-left: -35px;
  padding: 1px 4px;
  padding-left: 6px;
}
.entry-content ol > li ol > li {
  counter-increment: sub-item;
}
.entry-content ol > li ol > li::before {
  content: counter(sub-item) ".";
}
.entry-content ol > li ol > li ol > li {
  counter-increment: sub-sub-item;
}
.entry-content ol > li ol > li ol > li::before {
  content: counter(sub-sub-item) ".";
}
.entry-content ol[style*="list-style-type: lower-alpha"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-alpha"] li::before {
  content: counter(item, lower-alpha) ")";
}
.entry-content ol[style*="list-style-type: upper-alpha"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-alpha"] li::before {
  content: counter(item, upper-alpha) ")";
}
.entry-content ol[style*="list-style-type: lower-latin"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-latin"] li::before {
  content: counter(item, lower-latin) ")";
}
.entry-content ol[style*="list-style-type: upper-latin"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-latin"] li::before {
  content: counter(item, upper-latin) ")";
}
.entry-content ol[style*="list-style-type: lower-greek"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-greek"] li::before {
  content: counter(item, lower-greek) ")";
}
.entry-content ol[style*="list-style-type: upper-greek"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-greek"] li::before {
  content: counter(item, upper-greek) ")";
}
.entry-content ol[style*="list-style-type: lower-roman"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: lower-roman"] li::before {
  content: counter(item, lower-roman) ")";
}
.entry-content ol[style*="list-style-type: upper-roman"] {
  list-style-type: none !important;
}
.entry-content ol[style*="list-style-type: upper-roman"] li::before {
  content: counter(item, upper-roman) ")";
}
.entry-content ul {
  list-style-type: none;
}
.entry-content ul > li {
  padding-left: 24px;
  position: relative;
}
.entry-content ul > li::before {
  content: "";
  position: absolute;
  display: block;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  background-color: #143558;
  border-radius: 50%;
}
.entry-content ul,
.entry-content ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
.entry-content ul ul,
.entry-content ul ol,
.entry-content ol ul,
.entry-content ol ol {
  margin-bottom: 0;
  margin-top: 8px;
}
.entry-content ul li,
.entry-content ol li {
  margin-bottom: 8px;
}

/* stylelint-enable no-descending-specificity */
.entry-content p {
  --font-size: 18px;
  margin-bottom: 24px;
  font-size: var(--font-size);
  color: #143558;
  line-height: 29px;
}
@media (max-width: 575.98px) {
  .entry-content p {
    --font-size: 16px;
  }
}

.entry-content .table-responsive {
  margin: 16px 0;
  border: 16px solid #fff;
  box-shadow: 0 0 0 1px #e4e8ec;
  overflow: auto;
}
.entry-content th,
.entry-content td,
.entry-content tr {
  border: none !important;
}
.entry-content td,
.entry-content th {
  padding: 16px;
  text-align: center;
  position: relative;
}
.entry-content thead {
  background-color: #143558;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.entry-content tbody {
  background-color: #fff;
  color: #081828;
  font-size: 14px;
}
.entry-content tbody tr:nth-child(2n) {
  background-color: rgba(235, 235, 235, 0.39);
}
.entry-content tbody td,
.entry-content tbody th {
  padding: 8px;
}
.entry-content tbody td::before,
.entry-content tbody th::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 1px;
  background: #e4e8ec;
  top: 0;
  right: 0;
}
.entry-content tbody td:last-child::before,
.entry-content tbody th:last-child::before {
  display: none;
}
.entry-content table {
  margin-bottom: 0 !important;
  width: 100%;
  max-width: 100%;
}
.entry-content table p {
  margin-bottom: 0 !important;
  font-size: 16px;
  transform: none;
}

.g-search-results {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 80px;
}

.i-search-result {
  max-width: 80ch;
}

.i-search-result__heading-link {
  text-decoration-color: transparent;
}
.i-search-result__heading-link:hover {
  text-decoration-color: #143558;
}

.i-search-result__link {
  text-decoration-color: transparent;
  transition-property: color, text-decoration-color;
}
.i-search-result__link:hover {
  color: #143558;
  text-decoration-color: #143558;
}

.entry-content .gallery-item {
  position: relative;
  margin: 0;
  padding: 0;
}
.entry-content .gallery-item a {
  display: block;
  transition: opacity 0.3s ease;
}
.entry-content .gallery-item a:hover {
  opacity: 0.7;
}
.entry-content .gallery-item img {
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
}

.search-form__wrap {
  display: flex;
  align-items: center;
  position: relative;
  gap: 16px 32px;
  max-width: 80ch;
}
@media (max-width: 991.98px) {
  .search-form__wrap {
    flex-wrap: wrap;
  }
}

.search-form__heading {
  flex-shrink: 0;
}

.search-form {
  background-color: #fff;
  box-shadow: inset 0 0 0 1px #e4e8ec;
  display: flex;
  flex-grow: 1;
  position: relative;
}

.search-form__input {
  border: 0;
  background: none;
  padding: 0 16px;
  flex-grow: 1;
  outline: 0;
  color: #081828;
}
@media (max-width: 575.98px) {
  .search-form__input {
    min-width: 0;
  }
}

.search-form__submit {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.search-form__submit svg {
  width: 18px;
  height: 18px;
  transition: fill 0.2s;
  fill: #143558;
}
.search-form__submit:hover {
  background-color: #143558;
}
.search-form__submit:hover svg {
  fill: #fff;
}
.search-form__submit:active, .search-form__submit:focus {
  outline: 0;
}

.entry-content .gallery {
  margin-top: 48px;
  margin-bottom: 48px;
  display: grid;
  gap: 16px;
  align-items: center;
}
.entry-content .gallery > br {
  display: none;
}
.entry-content .gallery-columns-9 {
  grid-template-columns: repeat(8, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-9 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-8 {
  grid-template-columns: repeat(8, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-8 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-7 {
  grid-template-columns: repeat(7, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-7 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-6 {
  grid-template-columns: repeat(6, 1fr);
}
@media (max-width: 991.98px) {
  .entry-content .gallery-columns-6 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-5 {
  grid-template-columns: repeat(5, 1fr);
}
@media (max-width: 767.98px) {
  .entry-content .gallery-columns-5 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-4 {
  grid-template-columns: repeat(4, 1fr);
}
@media (max-width: 767.98px) {
  .entry-content .gallery-columns-4 {
    grid-template-columns: repeat(2, 1fr);
  }
}
.entry-content .gallery-columns-3 {
  grid-template-columns: repeat(3, 1fr);
}
.entry-content .gallery-columns-2 {
  grid-template-columns: repeat(2, 1fr);
}
.entry-content .wp-gallery-no-gutter .gallery {
  gap: 0;
}

/*! purgecss end ignore */