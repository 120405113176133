@use "settings/s-colors" as colors;

.f-error {
  --display: none;

  position: absolute;
  top: 100%;
  left: 0;
  color: colors.$danger;
  width: 100%;
  display: var(--display);
  z-index: 2;

  &.--show {
    --display: flex;
  }
}

.f-error__icon {
  height: 22px;
}
