@use "settings/s-colors" as colors;
@use "settings/s-general" as general;

.f-contact__grid {
  display: grid;

  @include media-breakpoint-up(md) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.f-contact__footer {
  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.f-contact__submit {
  @include media-breakpoint-up(sm) {
    margin-left: auto;
  }
}
