@use "settings/s-colors" as colors;

.f-switch {
  --width: 48px;
  --height: 24px;
  --offset: 4px;
  --background: #{colors.$primary-600};
  --background-checked: #{colors.$primary};
  --transition-timing: 0.2s;
  --border-radius: calc(var(--height) / 2);
  --handle-size: calc(var(--height) - var(--offset) * 2);
  --handle-radius: calc(var(--handle-size) / 2);
  --handle-background: #{colors.$white};
  --handle-translate-x: var(--offset);
  --handle-translate-x-checked: calc(var(--width) - var(--handle-size) - var(--offset));

  &.--disabled {
    --background: #{colors.$primary-800};
    --background-checked: #{colors.$primary-800};
    --handle-background: #{colors.$primary-900};
  }
}

.f-switch__visual {
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background-color: var(--background);
  transition: background-color var(--transition-timing) ease;
  will-change: background-color;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%) translateX(var(--handle-translate-x));
    width: var(--handle-size);
    height: var(--handle-size);
    border-radius: var(--handle-radius);
    background-color: var(--handle-background);
    z-index: 2;
    transition: transform var(--transition-timing) ease;
    will-change: transform;
  }
}

.f-switch__input {
  left: 0;
  top: calc(var(--height) / 2);
  pointer-events: none;
  opacity: 0;

  &:checked ~ .f-switch__visual {
    --background: var(--background-checked);
    --handle-translate-x: var(--handle-translate-x-checked);
  }
}
