.s-consultant-about__header {
  --spacing: 51px;
}

.s-consultant-about__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  padding: 0;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    justify-content: space-between;

    & .s-consultant-about__content {
      max-width: 450px;
      width: 100%;
    }
  }

  @include media-breakpoint-up(xxl) {
    & .s-consultant-about__content {
      max-width: 540px;
      width: 100%;
    }
  }
}
