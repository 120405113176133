.g-videos-more {
  margin: 0 -8px;
  margin-top: 64px;

  @include media-breakpoint-down(xl) {
    margin-top: 32px;
  }

  &.--hidden {
    display: none;
  }
}

.g-videos-more__item {
  padding: 0 8px;
}
