@use "settings/s-colors" as colors;

.f-textarea {
  --radius: 30px;

  &.--invalid {
    --border-color: #{colors.$danger};
  }
}

.f-textarea__wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--radius);
}

.f-textarea__input {
  border-radius: var(--radius);
}
