@use "settings/s-colors.scss" as colors;

.g-videos {
  gap: 154px;

  @include media-breakpoint-down(xxxl) {
    gap: 143px;
  }

  @include media-breakpoint-down(xxl) {
    gap: 161px;
  }

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    gap: 154px;
  }
}

.g-videos__large-img {
  & .i-media {
    .img.--cover {
      min-height: 538px;
      max-height: 538px;

      @include media-breakpoint-down(xl) {
        min-height: auto;
      }

      @include media-breakpoint-up(xl) {
        max-width: 655px;
      }

      @include media-breakpoint-up(xxl) {
        min-width: 807px;
        max-width: 807px;
      }

      @include media-breakpoint-up(xxxl) {
        min-width: 950px;
        max-width: 950px;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: -77px;
    transform: translate(50%, 0);
    width: 1px;
    height: 100%;
    background-color: colors.$grey-100;
    opacity: 0.26;

    @include media-breakpoint-down(xxxl) {
      right: -71.5px;
    }

    @include media-breakpoint-down(xxl) {
      right: -80.5px;
    }

    @include media-breakpoint-down(xl) {
      height: 1px;
      width: 100%;
      top: calc(100% + 77px);
      right: 0;
      transform: translate(0, -50%);
    }
  }
}

.g-videos__list {
  @include media-breakpoint-down(xl) {
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0 -8px;
  }
}

.g-videos__item {
  &:nth-child(n + 4) {
    display: none;
  }

  @include media-breakpoint-down(xl) {
    width: 50%;
    max-width: 100%;
    padding: 0 8px;
  }

  @include media-breakpoint-down(lg) {
    width: 100%;
    max-width: 100%;
    padding: 0 8px;
  }
}
