@use "settings/s-colors" as colors;

.i-job__image {
  width: var(--image-size);
  height: var(--image-size);
  border-radius: 16px;
  overflow: hidden;
}

.i-job__info {
  color: colors.$primary;
  row-gap: 8px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}

.i-job {
  --image-size: 144px;

  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  &.--card {
    --image-size: 448px;

    flex-direction: column;
    width: 416px;

    .i-job__image {
      height: auto;
      max-width: 100%;
    }

    .i-job__info {
      gap: 16px;
      flex-direction: column;
    }

    .i-job__footer {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
