@use "settings/s-colors" as colors;

.u-scrollbar {
  .os-scrollbar {
    --os-handle-bg: #{colors.$primary-300};
    --os-handle-bg-hover: #{colors.$primary-400};
    --os-handle-bg-active: #{colors.$primary};
    --os-handle-border-radius: 4px;
    --os-size: 8px;
    --os-track-bg: #{colors.$primary-900};
    --os-track-bg-hover: #{colors.$primary-900};
    --os-track-bg-active: #{colors.$primary-900};

    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .os-theme-dark.os-scrollbar-vertical {
    padding: 0;
  }
}

.u-o-visible {
  overflow: visible !important;
}
