@use "settings/s-colors.scss" as colors;

.i-priceplan {
  border: 1px solid colors.$primary-900;
  border-radius: 24px;

  &.--highlight {
    border: none;
    box-shadow: 0 8px 16px 2px hsl(233deg 78% 27% / 15%);
  }

  @include media-breakpoint-down(lg) {
    max-width: 448px;
    margin-inline: auto;
  }
}

.i-priceplan__ribbon {
  width: calc(100% - 64px);
  background: colors.$primary-900;
  border-radius: 8px;
  color: colors.$primary-400;
  top: -16px;
}

.i-priceplan__price-before {
  text-decoration: line-through;
}

.i-priceplan__price {
  font-size: 64px;
}
