@use "settings/s-colors" as colors;
@use "settings/s-shadows" as shadows;

.i-hero__body {
  max-width: 600px;

  @include media-breakpoint-down(xl) {
    position: static;
    transform: translate3d(0, 0, 0);
    left: auto;
    right: auto;
    max-width: 100%;
    gap: 24px;
  }

  @include media-breakpoint-down(sm) {
    padding: 16px;
  }
}

.i-hero__content {
  max-width: 566px;
  width: 100%;
}

.i-hero {
  --offset: 64px;

  &.--right {
    .i-hero__body {
      right: var(--offset);
    }
  }

  &.--center {
    .i-hero__body {
      @include media-breakpoint-up(lg) {
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: -88px;
  }

  @include media-breakpoint-up(xxl) {
    margin-bottom: -224px;
  }

  @include media-breakpoint-down(lg) {
    padding-top: 16px;
  }
}

.i-hero__image {
  position: relative;
  top: -210px;
  right: -164px;
  margin-left: auto;
  width: 964px;
  height: 1170px;
  transition: 0.3s ease;
  transition-property: width, height;

  @include media-breakpoint-down(xxl) {
    width: 760px;
    height: 912px;
    top: -148px;
    right: -132px;
  }

  @include media-breakpoint-down(xl) {
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    height: auto;
    top: -120px;
    right: 0;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: -40px;
  }
}

.i-hero__share {
  margin-top: 58px;
  margin-bottom: 77px;

  .link {
    --font-size: 24px;

    gap: 30px;

    @include media-breakpoint-down(sm) {
      gap: 16px;
    }

    &.--underline-initial-dynamic {
      & .link__title {
        position: relative;

        &::after {
          bottom: -8px;
        }
      }
    }
  }
}

.i-hero__play {
  --min-width: 110px;

  & .btn__icon {
    min-width: var(--min-width);
    height: var(--min-width);
    background-color: colors.$white;
    border-radius: 50%;
    box-shadow: 0 13px 26px shadows.$color-01;

    .icon {
      margin-left: 8px;
    }

    @include media-breakpoint-down(lg) {
      --min-width: 90px;

      & .icon {
        --size: 40px;
      }
    }

    @include media-breakpoint-down(sm) {
      --min-width: 64px;

      & .icon {
        --size: 32px;
      }
    }
  }
}

.i-hero__youtube {
  & .btn__icon {
    margin-left: 4px;
  }
}
