@use "settings/s-colors.scss" as colors;

.link__icon {
  svg {
    transition: transform 0.3s ease;
    will-change: transform;
  }
}

.link {
  --font-size: 18px;
  --text-decoration-style: dotted;
  --text-decoration-thickness: 4px;
  --text-decoration-offset: 6px;

  width: fit-content;
  font-size: var(--font-size);
  transition: text-decoration-color 0.3s ease;
  transition-property: text-decoration-color, color;
  text-decoration: underline;
  text-decoration-style: var(--text-decoration-style);
  text-decoration-color: transparent;
  text-decoration-thickness: var(--text-decoration-thickness);
  text-underline-offset: var(--text-decoration-offset);

  &:hover {
    text-decoration-color: inherit;
  }

  &.--icon-before {
    .link__icon {
      order: -1;
    }
  }

  &.--underline-initial-dynamic {
    --underline-bg: #{colors.$secondary-400};

    text-decoration: none;

    .link__title {
      position: relative;

      &::after {
        content: "";
        height: 4px;
        display: block;
        border-bottom: 4px dotted var(--underline-bg);
        width: 19px;
        transition: width 0.3s ease;
      }
    }

    &:hover,
    &:focus {
      text-decoration: none;

      .link__title::after {
        width: 100%;
      }
    }
  }

  &.--underline-initial-static {
    text-decoration-color: inherit;
  }

  &.--underline-style-solid {
    --text-decoration-style: solid;
  }
}
