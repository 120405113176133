@use "settings/s-colors" as colors;

.s-calendar__app {
  --fc-border-color: #{colors.$primary-900};
  --fc-today-bg-color: #{colors.$primary-900};
  --fc-event-bg-color: #{colors.$primary};
  --fc-button-bg-color: #{colors.$primary};
  --fc-button-border-color: #{colors.$primary};
  --fc-button-active-bg-color: #{colors.$primary-400};
  --fc-button-active-border-color: #{colors.$primary-400};
  --fc-button-hover-bg-color: #{colors.$primary-400};
  --fc-button-hover-border-color: #{colors.$primary-400};

  height: 570px;

  &.fc {
    height: auto;
  }

  &.--close {
    display: none;
  }

  .fc-daygrid {
    background-color: colors.$white;
  }

  .fc-event {
    border-radius: 0;
    border: none;
  }

  .fc-daygrid-day-number {
    text-decoration: none;
    color: colors.$primary;
  }

  .fc-col-header-cell-cushion {
    text-decoration: none;
  }

  .fc-col-header-cell {
    padding: 8px;
  }
}

.s-calendar__toggler {
  &.--close {
    svg {
      transform: scale(-1);
    }
  }
}
