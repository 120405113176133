@use "settings/s-colors" as colors;

.s-text-media__grid {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.s-text-media__header-in {
  gap: 8px;
  margin-bottom: 24px;
}

.s-text-media {
  &.--reverse {
    .s-text-media__col.--content-col {
      order: -1;
    }
  }
}
