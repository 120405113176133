@use "settings/s-colors.scss" as colors;

.f-label {
  &.--required {
    &::after {
      content: "*";
      color: colors.$danger;
      padding-left: 4px;
    }
  }
}
