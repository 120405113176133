@use "settings/s-colors.scss" as colors;

.i-video {
  @media (width <= 469px) {
    flex-direction: column;
  }
}

.i-video__text-part {
  height: auto;

  & .base-heading {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
  }
}

.i-video__title-link.link {
  --font-size: 24px;

  font-weight: 700;
  word-break: break-word;

  &:hover {
    text-decoration-color: transparent;
  }
}

.i-video__name {
  color: colors.$secondary-400;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: colors.$secondary-400;
  text-decoration-thickness: 4px;
  text-underline-offset: 6px;
}

.i-video__date {
  color: colors.$grey-100;
}
