.s-consultant-text-media {
  padding-top: 44px;
  padding-bottom: 0;
  position: relative;

  @include media-breakpoint-down(xl) {
    padding-bottom: 88px;
  }

  @include media-breakpoint-down(sm) {
    padding-bottom: 44px;
  }
}
