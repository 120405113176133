@use "settings/s-colors.scss" as colors;
@use "settings/s-fonts.scss" as fonts;

.i-consultant-stats {
  position: relative;
  padding-left: 48px;
  min-height: 99px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    border-radius: 4px;
    background-color: colors.$secondary-500;
  }

  & .i-consultant-stats__title {
    font-family: fonts.$secondary;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 88px;
  }
}
