@use "settings/s-general.scss" as general;

.base-section {
  --padding-y: 88px;

  padding-block: var(--padding-y);

  @include media-breakpoint-down(sm) {
    --padding-y: 44px;
  }

  &.--top-0 {
    padding-top: 0;
  }

  &.--bottom-0 {
    padding-bottom: 0;
  }

  &.--none {
    --padding-y: 0;
  }
}
