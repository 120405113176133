@use "settings/s-colors" as colors;

.f-checkbox-select__header {
  padding: 7px 8px;
  min-height: 48px;
  border: 1px solid colors.$primary-900;
  user-select: none;
  background-color: var(--header-background);
}

.f-checkbox-select__clear {
  display: none;

  &.--active {
    display: inline-flex;
  }
}

.f-checkbox-select__toggler {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.3s ease;

  svg {
    width: 14px;
    height: 14px;
    transition: scale 0.3s ease;
  }

  &:hover {
    background-color: colors.$primary-900;
  }
}

.f-checkbox-select__label {
  --end: 0;

  overflow: hidden;
  flex: 1;
  top: 0;
  left: 0;
  height: 32px;

  > span {
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: left 2s linear;
  }

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    width: 16px;
    height: 100%;
    background: transparent linear-gradient(270deg, var(--header-background) 0%, #fff0 100%) 0% 0% no-repeat padding-box; /* stylelint-disable-line max-line-length */
    z-index: 1;
  }

  &:hover {
    > span {
      left: var(--end);
    }
  }
}

.f-checkbox-select__body {
  border: 1px solid colors.$primary-900;
  top: calc(100% - 1px);
  left: 50%;
  transform: translateX(-50%) scaleY(0.25);
  transform-origin: top;
  width: calc(100% - 1px);
  background-color: colors.$white;
  z-index: 5;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  transition-property: opacity, transform;
  will-change: opacity, transform;
}

.f-checkbox-select__body-inner {
  max-height: 160px;
}

.f-checkbox-select {
  --header-background: #{colors.$white};

  &.--active {
    --header-background: #{colors.$primary-900};

    .f-checkbox-select__body {
      opacity: 1;
      pointer-events: auto;
      transform: translateX(-50%) scaleY(1);
    }

    .f-checkbox-select__toggler {
      svg {
        scale: -1;
      }
    }
  }

  min-width: 0;
}
